import * as React from 'react';
import './styles.css';
import {
  Modal,
  Button,
  Paper,
  Typography,
  styled,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from '@mui/material';
import { FormikProvider } from 'formik';
import { Icon } from '@iconify/react';

const MuiDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '15px'
}));

const ButtonAlignment = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '25px'
}));

function AssignRoleToUserModal({ handleClose, open }) {
  const [role, setRole] = React.useState('');

  const handleSelectRole = () => {
    setRole('');
  };

  const handleChange = (e) => {
    setRole(e.value);
  };
  console.log('kusum role', role);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10%' }}
      >
        <Paper sx={{ margin: '5%' }}>
          <Typography sx={{ padding: '5%' }}>Assign Role to user</Typography>
          <hr className='divider' />
          <FormikProvider>
            <div style={{ padding: '25px' }}>
              {role.toLocaleLowerCase() === 'manager' ||
              role.toLocaleLowerCase() === 'user' ||
              role.toLocaleLowerCase() === 'admin' ? (
                <MuiDiv>
                  <Typography sx={{ marginRight: '180px' }}>{role}</Typography>
                  <Icon
                    onClick={handleSelectRole}
                    icon="material-symbols:delete-outline"
                    width={26}
                  />
                </MuiDiv>
              ) : (
                <MuiDiv>
                  <Typography sx={{ marginRight: '120px' }}>Role</Typography>
                  <FormControl sx={{ width: '224px' }} size="small">
                    <InputLabel id="demo-select-small">Role</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={role}
                      label="Role"
                      onChange={(e) => handleChange(e.target)}
                    >
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="User">User</MenuItem>
                      <MenuItem value="Manager">Manager</MenuItem>
                    </Select>
                  </FormControl>
                </MuiDiv>
              )}
            </div>

            <hr className='divider' />
            <ButtonAlignment>
              <Button
                variant="outlined"
                sx={{ marginRight: '10px' }}
                onClick={handleClose}
              >
                Close
              </Button>
              <Button variant="contained">
                Save
              </Button>
            </ButtonAlignment>
          </FormikProvider>
        </Paper>
      </Modal>
    </>
  );
}

export default AssignRoleToUserModal;
