import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography } from '@material-ui/core';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import Logo from '../components/Logo';
import "./styles.css"

import Background from './ea-loginbg.jpg';
// import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const LogoStyle = styled('div')(({ theme }) => ({
  width: '100%',
  height: '80px',
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  padding: '10px 0px'
}));

const SectionStyleNew = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 570,
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
  // width: '100%',
  // display: 'flex',
  // justifyContent: 'right',
  // alignItems: 'center',
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  maxHeight: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(8, 0, 2, 2),
  alignItems: 'center',
  backgroundColor: '#1a2a68'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <div className="mainPage">  
    {/* style={{height:'100vh + 90px', overflowY: "hidden", backgroundImage: `url(${Background})`, backgroundSize: '100%'}}   */}
      <div style={{padding: '20px'}}>
      <LogoStyle>
        <Logo />
      </LogoStyle>
      </div>
      <RootStyle title="Login | EAI" style={{marginTop: '-70px'}}>
        <AuthLayout>
          {/* Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Register
        </Link>
        <br /> */}
          {/* <Link underline="none" variant="subtitle2" component={RouterLink} to="/verifyemail">
          Verify Email
        </Link> */}
        </AuthLayout>
        <MHidden width="mdDown">
          <SectionStyleNew>
            <img src="/static/illustrations/illustration_login.png" alt="login" style={{ maxWidth: "400px" }}/>
          </SectionStyleNew>
          {/* <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 0, mb: 5 }} style={{ color: "white" }}>
              Hi, Welcome Back
            </Typography>
          </SectionStyle> */}
        </MHidden>

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                <span style={{ color: "white" }}>Sign in to EAI</span>
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}  style={{ color: "white" }}>Enter your details below.</Typography>
            </Stack>
            {/* <AuthSocial /> */}

            <LoginForm />

            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to="register">
                  Register
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Container>
      </RootStyle>
    </div>
  );
}
