import {
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import Page from '../components/Page';

const PriceQuote = () => {
  const [state, setState] = useState();
  return (
    <Page title="Price Quote">
      <Container>
        <Typography variant="h4" mb={4} gutterBottom>
          Price Quote
        </Typography>
        <Card sx={{ padding: '40px' }}>
          <Grid container>
            <Grid sm={4}>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px' }}
                htmlFor="client-textfield"
              >
                Client Name
              </InputLabel>
              <TextField id="client-textfield" size="small" type="text" />
            </Grid>
            <Grid sm={4}>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px' }}
                htmlFor="product-dropdown"
              >
                Product Type
              </InputLabel>
              <FormControl>
                <Select id="product-dropdown" size="small" sx={{ width: '220px' }}>
                  <MenuItem>Inclusive</MenuItem>
                  <MenuItem>Retail-Text</MenuItem>
                  <MenuItem>On Demand</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid sm={4}>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px' }}
                htmlFor="sales-textfield"
              >
                Sales Volume
              </InputLabel>
              <TextField id="sales-textfield" size="small" type="text" />
            </Grid>
          </Grid>
          <Grid container mt={4}>
            <Grid sm={4}>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px' }}
                htmlFor="sales-textfield"
              >
                Intervention%
              </InputLabel>
              <TextField id="sales-textfield" size="small" type="text" />
            </Grid>
            <Grid sm={4}>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px' }}
                htmlFor="sales-textfield"
              >
                ROS%
              </InputLabel>
              <TextField id="sales-textfield" size="small" type="text" />
            </Grid>
            <Grid>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px' }}
                htmlFor="tow-textfield"
              >
                TOW%
              </InputLabel>
              <TextField id="tow-textfield" size="small" type="text" />
            </Grid>
          </Grid>
          <Grid container mt={4}>
            <Grid sm={4}>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px' }}
                htmlFor="ros-textfield"
              >
                ROS Avg Km
              </InputLabel>
              <TextField id="ros-textfield" size="small" type="text" />
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px', marginTop: '20px' }}
                htmlFor="tow-avg-textfield"
              >
                TOW Avg km
              </InputLabel>
              <TextField id="tow-avg-textfield" size="small" type="text" />
            </Grid>
            <Grid sm={6}>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px' }}
                htmlFor="other-service-textfield"
              >
                Other Services
              </InputLabel>
              <TextField
                fullWidth
                id="other-service-textfield"
                multiline
                rows={5}
                size="small"
                type="text"
              />
            </Grid>
          </Grid>
          <Grid container mt={4}>
            <Grid sm={4}>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px', marginTop: '20px' }}
                htmlFor="competitive-price-textfield"
              >
                Competitive Price
              </InputLabel>
              <TextField id="competitive-price-textfield" size="small" type="text" />
            </Grid>
            <Grid sm={4}>
              <FormControl>
                <FormLabel
                  shrink
                  id="demo-row-radio-buttons-group-label"
                  sx={{ color: '#000000', fontSize: '16px', marginTop: '20px' }}
                >
                  Marketing Cost
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid sm={4}>
              <FormControl>
                <FormLabel
                  shrink
                  id="demo-row-radio-buttons-group-label"
                  sx={{ color: '#000000', fontSize: '16px', marginTop: '20px' }}
                >
                  Telecom Cost
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container mt={4}>
            <Grid sm={4}>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px' }}
                htmlFor="any-integration-cost-textfield"
              >
                Any Integration Cost
              </InputLabel>
              <TextField id="any-integration-cost-textfield" size="small" type="text" />
            </Grid>
            <Grid sm={6}>
              <InputLabel
                shrink
                sx={{ color: '#000000', fontSize: '20px' }}
                htmlFor="comments-textfield"
              >
                Comments
              </InputLabel>
              <TextField
                fullWidth
                id="comments-textfield"
                multiline
                rows={4}
                size="small"
                type="text"
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="space-between" mt={6}>
            <Button variant="outlined" size="large" sx={{ marginRight: '20px' }} fullWidth>
              cancel
            </Button>
            <Button variant="contained" size="large" fullWidth>
              Save
            </Button>
          </Stack>
        </Card>
      </Container>
    </Page>
  );
};

export default PriceQuote;
