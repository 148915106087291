import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import * as CryptoJS from "crypto-js";
import { Alert, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { setUserSession } from '../../../utils/Auth';

// ----------------------------------------------------------------------

export default function VerifyForm() {
  const navigate = useNavigate();
  const [passwordError, setpasswordError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [invlidOTP, setinvlidOTP] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    otp: Yup.string().required()
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      otp: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { email: values.email, otp: values.otp };
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      console.log(user, 'user data to send');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/consumeRequestEmailOtp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            console.log(data, 'after verification data is required')
            setSuccessMessage(true);
            setTimeout(() => {
              setSuccessMessage(false);
              navigate('/login', { replace: true });
            }, 3000);
          } else {
            setinvlidOTP(true);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="off"
            type="email"
            label="Email address"
            name="email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="off"
            type="text"
            label="OTP"
            name="otp"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Submit
          </LoadingButton>
          {successMessage ? (
            <Alert severity="success">
              Reset Password link was sent to your email seccussfully
            </Alert>
          ) : (
            ''
          )}
          {invlidOTP ? <Alert severity="success">Invalid OTP</Alert> : ''}
        </Stack>
      </Form>

      <div align="center">{passwordError}</div>
    </FormikProvider>
  );
}
