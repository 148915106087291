import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import * as CryptoJS from "crypto-js";
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  styled,
  DialogActions,
  DialogContent,
  DeleteIcon
} from '@material-ui/core';

// import CloseIcon from '@material-ui/icons/Close';
// import CloseIcon from '@mui/icons-material/Close';
// import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenuRole } from '../components/_dashboard/user';
import { UpdateClientRoleForm } from '../components/clientroles/updateclientrole'
import { CreateClientRoleForm } from '../components/clientroles/createclientrole'
import { AssignPemissionForm } from '../components/users/assignpemission'

//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [successMessage, setSuccessMessage] = useState('');
  const [delErr, setdelErr] = useState('');
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const [openPermissions, setopenPermissions] = useState(false);

  useEffect(() => {   
    fetchRoles()
  }, [])

  const fetchRoles = async () => {
    redirectToCP()
  }

  const redirectToCP = async () => {
    const token = localStorage.getItem('token');
    const authResult = new URLSearchParams(window.location.search);
    const RedirectUrl = authResult.get('RedirectUrl')
    const clientIDE = authResult.get('clientIDE')
    const role = authResult.get('role')
    window.location.replace(`${RedirectUrl}/pages/login/sso?clientIDE=${clientIDE}&role=${role}&token=${token}`)
    // const token = localStorage.getItem('token');
    // fetch(`https://eztraveluat.europassistance.in:3000/batchJobs/getClaintToken`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json'
    //   }
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //           localStorage.setItem('cp_token', data.token);
    //           Cookies.set('testCookie', 'this is my first cookie')
    //           window.location.replace(`http://localhost:3001/pages/login/sso?token=${data.token}`)
    //         });
  };

  return (
    <h3>Authenticating...</h3>
  );
}
