import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { FormLabel } from '@mui/material';
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select , { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
// import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
// import Select from 'react-select'

// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';

// import Select from '@material-ui/core/Select';
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 9;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.0 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "All",
  "Panindia",
  "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Panindia",
    "Punjab",
    "Rajasthan",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
];


const CreateClientRoleForm = ({ client, onUpdate, onClose }) => {

  const navigate = useNavigate();

  const [age, setAge] = React.useState('');
  
  const [allServices, setAllServices] = useState([]);
  const [allApps, setAllApps] = useState([]);
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState([]);
  const [regions, setRegions] = useState([]);

  const [stateName, setStateName] = useState([]);

  const handleChangeNew = (event) => {
    const {
      target: { value },
    } = event;
  
    if (value.includes("All")) {
      // If "All" is selected, set all states in the stateName array
      setStateName(value.includes("All") ? names.filter((state) => state !== "All") : value);
    } else {
      // Otherwise, update the stateName array as before
      setStateName(value);
    }
  };
  



  useEffect(() => {
    fetchApps();
  }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [selectedService, setSelectedService] = React.useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const LoginSchema = Yup.object().shape({
    application: Yup.string().required('Application is required'),
    role: Yup.string().required('Role is required'),
    roleId: Yup.string().required('Id is required')
  });

  const formik = useFormik({
    initialValues: {
      application: '', 
      role: '',
      roleId: '',
      client: '',
      sequence: '',
      regions: '',
      actionOfRole: '',
      rangeFrom: '',
      rangeTo: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { applicationId: values.application, role: values.role,roleId: values.roleId, clientId: values.client ,regions: stateName,sequence: values.sequence, actionOfRole: values.actionOfRole,rangeFrom: values.rangeFrom,rangeTo: values.rangeTo };
      console.log(user, 'role data to send');
      const encuser = {
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/application/addApplicationRoleMaster`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('successfull',data)
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              onUpdate()
              onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        })
        .catch((err) => console.log('err', err))
    }
  });

  const fetchApps = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          setAllApps(data.data);
        }
      });
  };

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>


                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Role"
                  name="role"
                  {...getFieldProps('role')}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                />
                

                  <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="RoleId"
                  name="roleId"
                  {...getFieldProps('roleId')}
                  error={Boolean(touched.roleId && errors.roleId)}
                  helperText={touched.roleId && errors.roleId}
                />
              
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Application</InputLabel>
                  <Select
                    native
                    label="Application"
                    inputProps={{
                      name: 'application',
                      id: 'filled-application-native-simple'
                    }}
                    {...getFieldProps('application')}
                    error={Boolean(touched.application && errors.application)}
                    helperText={touched.application && errors.application}
                  >
                    <option key="" value="" />
                    {allApps.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name.description}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Client</InputLabel>
                  <Select
                    native
                    label="Client"
                    inputProps={{
                      name: 'client',
                      id: 'filled-application-native-simple'
                    }}
                    {...getFieldProps('client')}
                    error={Boolean(touched.client && errors.client)}
                    helperText={touched.client && errors.client}
                  >
                    <option key="" value="" />
                    {client.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item?.displayName}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-multiple-checkbox-label">Regions</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={stateName}
                  onChange={handleChangeNew}
                  input={<OutlinedInput label="Regions" />}
                  renderValue={(selected) =>
                    selected.includes("All") ? "All" : selected.join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={(stateName || []).indexOf(name) > -1}/>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

                 
                <Grid container>
                <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                 fullWidth
                  autoComplete="off"
                  type="number"
                  label="Sequence"
                  name="sequence"
                  {...getFieldProps('sequence')}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                  />
                 
                </FormControl>               
                </Grid>
                <Grid item xs={5.9}>
                <FormControl fullWidth sx={{ ml: 1}}>
                  <InputLabel id="demo-simple-select-helper-label">Action Of Role</InputLabel>
                  <Select 
                    label="Action Of Role"
                    inputProps={{
                      name: 'actionOfRole',
                      id: 'filled-application-native-simple'
                    }}
                    {...getFieldProps('actionOfRole')}
                    error={Boolean(touched.client && errors.client)}
                    helperText={touched.client && errors.client}
                  >   
                    <MenuItem key="" value="">Select Action</MenuItem>
                    <MenuItem key="1" value='Creator' >Creator</MenuItem>
                    <MenuItem key="2" value='Approval' >Approval</MenuItem>
                    <MenuItem key="3" value='Authorised' >Authorised</MenuItem>
                    <MenuItem key="4" value='Authorised Approval' >Authorised Approval</MenuItem>
                    <MenuItem key="5" value='Final Approval' >Final Approval</MenuItem> 
                  </Select>
                </FormControl>
                </Grid>
               </Grid>

                <Grid container>
                <Grid item xs={6}>
                <TextField
                 fullWidth
                  autoComplete="off"
                  type="number"
                  label="Approval Range From"
                  name="rangeFrom"
                  {...getFieldProps('rangeFrom')}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                  />
                </Grid>
                <Grid item xs={5.9}>
                 <TextField
                 sx={{ ml: 1}}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  label="Approval Range To"
                  name="rangeTo"
                  {...getFieldProps('rangeTo')}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                />
                </Grid>
               </Grid>

                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CreateClientRoleForm;