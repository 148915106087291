import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { LoadingButton } from '@material-ui/lab';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// ----------------------------------------------------------------------
const UpdateUserForm = ({ role, onUpdate, onClose }) => {
  console.log(role, 'user data')
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const LoginSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    email: Yup.string().required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: role.firstName,
      lastName: role.lastName,
      phoneNumber: role.phoneNumber,
      email: role.email,
      userName: role?.userName,
      isActive: role.isActive
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { userId: role._id, firstName: values.firstName, lastName: values.lastName, phoneNumber: values.phoneNumber, email: values.email, username: role?.userName, status: 'Active', isActive: values.isActive };
      console.log(user, "user data")
      const encuser = {
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/updateUser`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              onUpdate()
              onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="First Name"
                  name="firstName"
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Last Name"
                  name="lastName"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Email"
                  name="email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Phone Number"
                  name="phoneNumber"
                  {...getFieldProps('phoneNumber')}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="User Name"
                  name="userName"
                  {...getFieldProps('userName')}
                  disabled
                />

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="filled-status-native-simple">Status</InputLabel>
                  <Select
                    native
                    label="Status"
                    inputProps={{
                      name: 'isActive',
                      id: 'filled-isActive-native-simple'
                    }}
                    {...getFieldProps('isActive')}
                    error={Boolean(touched.isActive && errors.isActive)}
                    helperText={touched.isActive && errors.isActive}
                  >
                    <option aria-label="None" value="" />
                    <option value="true">ACTIVE</option>
                    <option value="false">INACTIVE</option>
                  </Select>
                </FormControl>



                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UpdateUserForm;