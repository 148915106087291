import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useMemo } from 'react';
import * as CryptoJS from "crypto-js";
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  styled,
  DialogActions,
  DialogContent,
  DeleteIcon
} from '@material-ui/core';
import {stateToHTML} from 'draft-js-export-html';
// import { convertFromRaw } from 'draft-js';
// import CloseIcon from '@material-ui/icons/Close';
// import CloseIcon from '@mui/icons-material/Close';
// import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import {
 
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select, 
  TableHead,
  TextField
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ToastContainer, toast } from 'react-toastify';
import { Form, FormikProvider, useFormik } from 'formik';
import searchFill from '@iconify/icons-eva/search-fill';
import { UserMoreMenu } from '../components/_dashboard/user';
import AddNewPageModal from './AddNewPageModal';
import './styles.css';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
// import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { UpdateSmsForm } from '../components/sms/updatesms'
import { CreateSmsForm } from '../components/sms/createsms'
import { AssignPemissionForm } from '../components/users/assignpemission'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const HeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '15px'
}));

const AppMainPage = styled('div')(({ theme }) => ({
  padding: '10px'
}));

const OBJECT1 = {
  page: 'Search Page Name...',
  applicationName: 'Search Application Name...',
  status: 'Search Status...'
};

export default function Sms(){
  const [open, setOpen] = React.useState(false);
  const [isUpdatePageOpen, setIsUpdatePageOpen] = React.useState(false);
  const [pageData, setPageData] = React.useState([]);
  const [searchObj, setSearchObj] = React.useState({ type: '', search: '' });
  const [successMessage, setSuccessMessage] = React.useState('');
  const [appPages, setAppPages] = React.useState([]);
  const [searchField, setSearchField] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('');
 
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [applicationData, setApplicationData] = useState([]);
 // const [successMessage, setSuccessMessage] = useState('');
  const [delErr, setdelErr] = useState('');
//  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [clientData, setClientData] = useState([]);
  const [openPermissions, setopenPermissions] = useState(false);

  // const stripHtmlTags = (html) => {
   // const doc = new DOMParser().parseFromString(html, 'text/html');
  //  return doc.body.textContent || '';
 // };

  const createMarkup = (content) => ({ __html: content });


  const filteredData = useMemo(() => {
    if (!searchObj?.search?.length || !searchObj.type) {
      return appPages;
    }
    const filteredObj = appPages.filter((item) => {
      const filteredItems = item?.[searchObj.type]
        ?.toLowerCase()
        .includes(searchObj.search.toLowerCase());
      return filteredItems;
    });
    return filteredObj;
  }, [appPages, searchObj]);

  useEffect(() => {
    getAllApplications();
    fetchClient();
    getAppPageData();
    // fetchRoles();
    
  }, []);

    // fetch application
    const getAllApplications = () => {
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            data.data = decryptedData;
            console.log(data.data);
            const dataApp = data.data.map((item) => item);
            setApplicationData(dataApp);
            
            console.log(applicationData);
          }
        });
    } 
  

  const getAppPageData = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/notificationConfiguration/allList`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          setAppPages(decryptedData);
          console.log('All SMS Configuration', decryptedData);
        }
      })
      .catch((err) => console.log('app page data error', err));
  };

  const fetchClient = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          console.log(data.data, 'client data')       
          setClientData(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }

  const formik = useFormik({
    initialValues: {
      pageId: '',
      page: '',
      status: ''
    },
    enableReinitialize: true,
    onSubmit: () => {
      const token = localStorage.getItem('token');
      console.log('App page values', values);
      const bodyData = {
        pageId: '123',
        page: values?.page,
        status: values?.status
      };
      const encData = {
        encryptedData: CryptoJS.AES.encrypt(
          JSON.stringify(bodyData),
          '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
        ).toString()
      };
      fetch(`${process.env.REACT_APP_BASEURL}/api/page/updatePage`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encData)
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('App page data', data);
        })
        .catch((err) => console.log('err', err));
    }
  });

  const notify = () => toast.success('SMS Configuration is Deleted');

  const deleteAppPage = async (id) => {
    const pageId = { notificationId: id  };
    console.log(pageId);
    const encuser = {
      encryptedData: CryptoJS.AES.encrypt(
        JSON.stringify(pageId),
        '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
      ).toString()
    };
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/notificationConfiguration/delete`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          notify();
          getAppPageData();
          setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
        }
      })
      .catch((err) => console.log('err', err));
  };

  const editAppPage = async (data) => {
    setIsUpdatePageOpen(true);
    setPageData(data);
  };

  const handleCloseUpdateAppPage = () => {
    setIsUpdatePageOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const pageChangeHandler = (search, type) => {
    setSearchObj({ search, type });
  };

  const applicationChangeHandler = (search) => {
    setSearchObj({ search, type: 'applicationName' });
  };

  const statusChangeHandler = (search) => {
    setSearchObj({ search, type: 'status' });
  };

  const handleChange = (e) => {
    setSearchField(e.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editRole = async (id) => {
    console.log(id, 'edit id')
    setIsUpdatePageOpen(true);
    setRole(id)
    console.log(role, 'role')
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const { values, handleSubmit } = formik;

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
      padding: theme.spacing(2)
    },
    '& .MuDialogActions-root': {
      padding: theme.spacing(1)
    }
  }));

  
  const openNewRole = () => {
    setOpenNew(true);
  };

  const closeNewRole = (value) => {
    setOpenNew(false);
    setSelectedValue(value);
  };


  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Icon icon="simple-line-icons:close" className="big-icon" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Page title="SMS Configuration | EAI">
      <AppMainPage>
        <ToastContainer />
        <HeadWrapper>
          <h2 style={{ marginLeft: '15px' }}>SMS Configuration</h2>
          <Button
            variant="contained"
            onClick={openNewRole}
            startIcon={<Icon icon={plusFill} />}
          >
            Add Configuration
          </Button>
        
        </HeadWrapper>
        <hr className="divider" />
        <div style={{ display: 'flex', justifyContent: 'row', margin: '20px 15px' }}>
          <FormControl sx={{ width: '200px', marginRight: '20px' }}>
          <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              label="select"
              onChange={(e) => handleChange(e.target)}
            >
              
              <MenuItem value="application">Application</MenuItem>
             
              <MenuItem value="providerName">Provider Name</MenuItem>
              <MenuItem value="passwordValue">Password value</MenuItem>
              <MenuItem value="token">Token</MenuItem>
              <MenuItem value="TemplateName">Name</MenuItem>

              <MenuItem value="status">Status</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="search"
            placeholder={OBJECT1[searchField] ? OBJECT1[searchField] : 'Search'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="ic:round-search" width={22} />
                </InputAdornment>
              )
            }}
            onChange={(e) => pageChangeHandler(e.target.value, searchField)}
          />
        </div>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Application</StyledTableCell>                 
                    <StyledTableCell align="left">Provider Name</StyledTableCell>
                    <StyledTableCell align="left">Password Value</StyledTableCell>
                    <StyledTableCell align="left">Token</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row?._id}>
                                              <StyledTableCell align="left">
                        {row?.application
}
                      </StyledTableCell>  
                       
                        <StyledTableCell align="left">{row?.providerName}</StyledTableCell>
                        <StyledTableCell align="left">{row?.passwordValue}</StyledTableCell>
                        <StyledTableCell align="left">{row?.token}</StyledTableCell>
                       
                        <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(row.status === 'INACTIVE' && 'error') || 'success'}
                            >
                              {row?.status}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <UserMoreMenu role={row} onDelete={deleteAppPage} onEdit={editRole}/>
                          </TableCell>
                      </StyledTableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Form>
        </FormikProvider>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </AppMainPage>
      <BootstrapDialog
        onClose={handleCloseUpdateAppPage}
        aria-labelledby="customized-dialog-title"
        open={isUpdatePageOpen}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseUpdateAppPage}>
          Update SMS Configuration
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <UpdateSmsForm
            role={role}
            onUpdate={getAppPageData}
            onClose={handleCloseUpdateAppPage}
          />
        </DialogContent>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={closeNewRole}
        aria-labelledby="customized-dialog-title"
        open={openNew} fullWidth maxWidth="lg"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={closeNewRole}>
          SMS Configuration
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <CreateSmsForm onUpdate={getAppPageData} onClose={closeNewRole}/>
        </DialogContent>
      </BootstrapDialog>

      <AddNewPageModal
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        onUpdate={getAppPageData}
      />
    </Page>
  );
}
