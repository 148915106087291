import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as CryptoJS from "crypto-js";
// material
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@material-ui/core';
// components
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';
import { removeUserSession, getUser } from '../../utils/Auth';
import './footer.css';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Change Password',
    icon: settings2Fill,
    linkTo: 'change'
  }
];
const user = getUser();
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const email = localStorage.getItem('email');
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const logout = () => {
    console.log("yes came to logout")
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/auth/oauth2/DestroySession`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        
        let logoutUrls = []
        logoutUrls = localStorage.getItem('logouts') ? localStorage.getItem('logouts') : '[]';
        logoutUrls = JSON.parse(logoutUrls)
        if(logoutUrls.length) {
          
          logoutUrls.forEach(element => {       
            if(element) window.open(element);
          });
        }

        removeUserSession();
        navigate('/login', { replace: true });
      });



    // const body = {
    //   "token": localStorage.getItem('cp_token'),
    //   "status": false
    // }
    // fetch(`https://localhost:3000/batchJobs/jwtSession/updateStatus`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json'
    //   },
    //   body: JSON.stringify(body)
    // }).then((result) => {
    //     removeUserSession();
    //     navigate('/login', { replace: true });
    //   });


  };

  return (
    <>
      {/* <div className="main">
      <div className="badge">
        Hi, {user.firstName}
        </div>
        <div className="singleName" align="center">        
        { user.firstName ? user.firstName.charAt(0) : ''}
        </div>
        </div> */}
      {/* <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >

        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton> */}
      <div className="main">
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            // ...(open && {
            //   '&:before': {
            //     zIndex: 1,
            //     content: "''",
            //     width: '100%',
            //     height: '100%',
            //     borderRadius: '50%',
            //     position: 'absolute',
            //     bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            //   }
            // })
          }}
        >
        {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
        <div className="singleName" style={{marginLeft: "5px"}}>  
          {firstName ? firstName.charAt(0) : "" }
        </div>
        </IconButton>
        <div className="badge">
          <Box>
            <Typography sx={{fontSize: "95%", fontWeight: "bold"}}>
              <div className='testDiv'>
              <div  className='firstDiv'>
                {firstName} {lastName}
              </div>
              </div>
            </Typography>
          </Box>
  
        </div>
      </div>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={logout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
