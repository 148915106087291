import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import * as CryptoJS from "crypto-js";
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Alert, Stack, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------

export default function ResetForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [passwordError, setpasswordError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { id } = useParams();
  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    // otp: Yup.number().required('OTP is required'),
    password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
    confirmpassword: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      otp: '',
      password: '',
      confirmpassword: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { uuid: id, newPassword: values.password };
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      console.log(user, 'object')
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/consumeRequestRequestPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
      .then((res) => res.json())
        .then((data) => {
          setpasswordError('');
          if (data.code === 200) {
            values.password = '';
            values.confirmpassword = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              navigate('/login', { replace: true });
            }, 3000);
          } else {
            setpasswordError(data.msg);
          }
        });
        // .then((res) => res.json())
        // .then((data) => {
        //   if (data.data) {
        //     values.email = '';
        //     setSuccessMessage(data.msg);
        //     setTimeout(() => {
        //       setSuccessMessage('');
        //       navigate('/login', { replace: true });
        //     }, 3000);
        //   } else {
        //     values.email = '';
        //     console.log(values.email);
        //     setpasswordError(data.msg);
        //   }
        // });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConPassword = () => {
    setShowConPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            name="password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="off"
            type={showConPassword ? 'text' : 'password'}
            label="Confirm Password"
            name="password"
            {...getFieldProps('confirmpassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowConPassword} edge="end">
                    <Icon icon={showConPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmpassword && errors.confirmpassword)}
            helperText={touched.confirmpassword && errors.confirmpassword}
          />
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Create New Password
          </LoadingButton>
        </Stack>
      </Form>
      <br />
      {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
      {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
    </FormikProvider>
  );
}
