import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';
// import Select from '@material-ui/core/Select';
import Select from '@mui/material/Select';
import { LoadingButton } from '@material-ui/lab';


// ----------------------------------------------------------------------
const UpdateApprovalPage = ({pageData, onUpdate, handleClose}) => {
    console.log(pageData);
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [allApps, setAllApps] = useState([]);
  const [approvalData, setApprovalData] = useState([]);
  const [rolesOfClient, setRolesOfClient] = useState([]);
  const [index, setIndex] = useState(0);
  const [appPages, setAppPages] = useState([]);
  const [pages, setPages] = useState([]);
  const [client, setClient] = useState([]);
  const [projectName, setProjectName] = React.useState([]);
  const [searchProject, setSearchProject] = useState('');
  const [page, setPage] = React.useState(0);
  const [age, setAge] = React.useState('');
  const [appId, SetAppId] = React.useState('');
  const [clientIndex , setClientIndex] = useState(0);

 

  useEffect(() => {
    fetchApprovalData();
    // getAppPageData();
    fetchApps();
    fetchClient();
    roleBasedOnAapplication();
    pagesOnApplication();
  }, []);

  const formik = useFormik({
    initialValues: {
        pageId: pageData?.pageId,
        roleId: pageData?.roleId,
        clientId: pageData?.clientId,
        projectId: pageData?.projectId,
      projectName: pageData?.projectName,
      pageName: pageData?.pageName,
      clientName: pageData?.clientName,
      roleName: pageData?.roleName
    },
    
    onSubmit: () => {
        console.log("submit");
        const user = { documnetMasteId: pageData._id,pageId: values.pageId,roleId: values.roleId,clientId: values.clientId,projectId: values.projectId,
            projectName: values.projectName, pageName: values.pageName, clientName: values.clientName, roleName: values.roleName
        };
       console.log(user, 'approval update data')
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/application/updateDocumentApprovalMaster`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {           
            
              onUpdate();
              handleClose();
              console.log('succesful', data?.msg);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  
  const fetchApprovalData = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getDocumentApprovalMaster`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          console.log(data.data, 'Approval data')       
          setApprovalData(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }

  const fetchApps = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          setAllApps(data.data);
          console.log(data.data, 'get all application data');
          setProjectName(data.data);
        }
      });
  };

const roleBasedOnAapplication = () => {
    const user = { applicationId: pageData.projectId, clientId: pageData.clientId};
    console.log(user);
    const encuser = {
      "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
    }
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/roleBasedOnAapplication`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;  
          console.log(data.data, 'app roles');
          const newArray = data.data.map((item) => item);
          setRolesOfClient(newArray);
          console.log(rolesOfClient);
           
        }
      });
}

  const handleChangeClient = (e) => {

    formik.values.clientId = e

    console.log(index);
    
    if (index === 0){
        console.log('selected client id' , e);

        const indexClients =  client.findIndex(obj => obj._id === e);
    
        formik.values.clientName = client[indexClients].displayName
     
        setClientIndex(indexClients);
        const user = { applicationId: pageData.projectId , clientId: e};
        console.log(user);
        const encuser = {
          "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
        }
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_BASEURL}/api/users/roleBasedOnAapplication`, {
          method: 'POST',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(encuser)
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.code === 200) {
              const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
              const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
              data.data = decryptedData;  
              console.log(data.data, 'app roles');
              const newArray = data.data.map((item) => item);
              setRolesOfClient(newArray);
              console.log(rolesOfClient);
               
            }
          });
    }
    else
    {
        console.log('selected client id' , e);

        const indexClients =  client.findIndex(obj => obj._id === e);
    
        formik.values.clientName = client[indexClients].displayName
        setClientIndex(indexClients);
        const user = { applicationId: projectName[index]._id , clientId: e};
        console.log(user);
        const encuser = {
          "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
        }
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_BASEURL}/api/users/roleBasedOnAapplication`, {
          method: 'POST',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(encuser)
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.code === 200) {
              const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
              const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
              data.data = decryptedData;  
              console.log(data.data, 'app roles');
              const newArray = data.data.map((item) => item);
              setRolesOfClient(newArray);
              console.log(rolesOfClient);
               
            }
          });
    }
    console.log(appId);
    

  };

  const getAppPageData = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/page/getAllPage`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          setAppPages(decryptedData);
          console.log('All Pages', decryptedData);

          
                // formik.values.projectName = e.value
                // setSearchProject(e.value);
            
            const newpages = decryptedData.filter(
             (item) => item?.applicationName === pageData.projectName
              )
            
            setPages(newpages);
            // const indexs =  projectName.findIndex(obj => obj.name.description === e.value);
            // formik.values.projectId = projectName[indexs]._id
            // setIndex(indexs);
            // console.log(projectName[indexs]._id);   
        }
      })
      .catch((err) => console.log('app page data error', err));
  };


  const fetchClient = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          console.log(data.data, 'client data')       
          setClient(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }
  
  

  const handleChange = (e) => {
    console.log("change project")
    formik.values.projectName = e
    setSearchProject(e);
    console.log(e);
    // console.log(e.currentTarget.getAttribute('value'));
    // setAge(e);
    const newpages = appPages.filter(
      (item) => item?.applicationName === e
    )
    console.log(newpages);
    setPages(newpages);
    const indexs =  projectName.findIndex(obj => obj.name.description === e);
    formik.values.projectId = projectName[indexs]._id
    setIndex(indexs);
    console.log(projectName[indexs]._id);   

    const user = { applicationId: projectName[indexs]._id , clientId: pageData.clientId};
        console.log(user);
        const encuser = {
          "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
        }
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_BASEURL}/api/users/roleBasedOnAapplication`, {
          method: 'POST',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(encuser)
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.code === 200) {
              const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
              const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
              data.data = decryptedData;  
              console.log(data.data, 'app roles');
              const newArray = data.data.map((item) => item);
              setRolesOfClient(newArray);
              console.log(rolesOfClient);
               
            }
          });
  };

  const pagesOnApplication = () => {
    const token = localStorage.getItem('token');
    const url = decodeURI(
      `${process.env.REACT_APP_BASEURL}/api/application/pagesOfRole?roleName=${pageData.roleName}&applicationName=${pageData.projectName}&clientName=${pageData.clientName}`
    );
    fetch(url, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          console.log('decrypted assined data', data);
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));               
          console.log('assined pages', decryptedData);
          const newPages = decryptedData.map((item) => item);
          setPages(newPages);
        }
      })
      .catch((err) => console.log('err', err));

  }
  
  const handlePage = (e) => {
    console.log(e);
    formik.values.pageId = e._id
    // const indexPage =  pages.findIndex(obj => obj._id === e.value);
    formik.values.pageName = e.page;
  }

  const handleChangerole = (e) => {
    console.log(e);
    console.log('project index', index);
    console.log('client index', clientIndex);
    formik.values.roleId = e
    const indexRole =  rolesOfClient.findIndex(obj => obj._id === e);
    formik.values.roleName = rolesOfClient[indexRole].role;
    
    // console.log(index);
    
    if (index === 0 && clientIndex === 0){
    const token = localStorage.getItem('token');
    const url = decodeURI(
      `${process.env.REACT_APP_BASEURL}/api/application/pagesOfRole?roleName=${rolesOfClient[indexRole].role}&applicationName=${pageData.projectName}&clientName=${pageData.clientName}`
    );
    fetch(url, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          console.log('decrypted assined data', data);
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));               
          console.log('assined pages', decryptedData);
          const newPages = decryptedData.map((item) => item);
          setPages(newPages);
        }
      })
      .catch((err) => console.log('err', err));
    }
    else if (index !== 0 && clientIndex === 0){
      const token = localStorage.getItem('token');
    const url = decodeURI(
      `${process.env.REACT_APP_BASEURL}/api/application/pagesOfRole?roleName=${rolesOfClient[indexRole].role}&applicationName=${projectName[index].name.description}&clientName=${pageData.clientName}`
    );
    fetch(url, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          console.log('decrypted assined data', data);
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));               
          console.log('assined pages', decryptedData);
          const newPages = decryptedData.map((item) => item);
          setPages(newPages);
        }
      })
      .catch((err) => console.log('err', err));
    
      }
      else if (index === 0 && clientIndex !== 0){
              const token = localStorage.getItem('token');
          const url = decodeURI(
            `${process.env.REACT_APP_BASEURL}/api/application/pagesOfRole?roleName=${rolesOfClient[indexRole].role}&applicationName=${pageData.projectName}&clientName=${client[clientIndex].displayName}`
          );
          fetch(url, {
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.code === 200) {
                console.log('decrypted assined data', data);
                const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));               
                console.log('assined pages', decryptedData);
                const newPages = decryptedData.map((item) => item);
                setPages(newPages);
              }
            })
            .catch((err) => console.log('err', err));
            }
      else if (index !== 0 && clientIndex !== 0){
        const token = localStorage.getItem('token');
        const url = decodeURI(
          `${process.env.REACT_APP_BASEURL}/api/application/pagesOfRole?roleName=${rolesOfClient[indexRole].role}&applicationName=${projectName[index].name.description}&clientName=${client[clientIndex].displayName}`
        );
        fetch(url, {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.code === 200) {
              console.log('decrypted assined data', data);
              const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
              const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));               
              console.log('assined pages', decryptedData);
              const newPages = decryptedData.map((item) => item);
              setPages(newPages);
            }
          })
          .catch((err) => console.log('err', err));

      }

  }
  

   const { errors, touched, values, handleSubmit, getFieldProps  } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
                 
               <FormControl>
               <InputLabel id="demo-select-small" sx={{textAlign: 'center'}}>Project Name</InputLabel>
               <Select
               labelId="demo-select-small"
               label="Project Name" 
               // onChange ={(e) => handleChange(e.target)}
               sx={{height: '50px'}}
               // value={pageData.projectName}
               {...getFieldProps('projectName')}
               inputProps={{
                name: 'projectName',
                id: 'filled-application-native-simple'
              }}
              error={Boolean(touched.role && errors.role)}
              helperText={touched.role && errors.role}
               >
               <MenuItem value="">Select Project</MenuItem>
                    {projectName.map((item) => (
                      <MenuItem  value={item?.name.description} onClick={() => handleChange(item?.name.description)} >
                        {item?.name.description}
                      </MenuItem>
                      ))}
               </Select>
               </FormControl>

               <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-select-small">Client</InputLabel>
              <Select
              labelId="demo-select-small"
              label="Project Name"
              // onChange={(e) => handleChangeClient(e.target)} 
              sx={{height: '50px'}}
              {...getFieldProps('clientId')}
              inputProps={{
               name: 'clientId',
               id: 'filled-application-native-simple'
             }}
             error={Boolean(touched.role && errors.role)}
             helperText={touched.role && errors.role}
              >
              <MenuItem value="">Select Client</MenuItem>
                    {client.map((item) => (
                      <MenuItem key={item?._id} value={item?._id} onClick={() => handleChangeClient(item?._id)}>
                        {item?.displayName}
                      </MenuItem>
                    ))}              
                </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
               <InputLabel id="demo-simple-select-helper-label">User Role</InputLabel>
                  <Select
                    labelId='user_role'
                    label="User Role"
                    // onChange={(e) => handleChangerole(e.target)}
                    sx={{height: '50px'}}
                    {...getFieldProps('roleId')} 
                    name='roleId'
                    inputProps={{
                        name: 'roleId',
                        id: 'filled-application-native-simple'
                      }}
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                 >
                 <MenuItem value="">Select Role</MenuItem>
                    {rolesOfClient.map((item) => (
                      <MenuItem key={item?._id} value={item?._id} onClick={() => handleChangerole(item?._id)}>
                        {item.role}
                      </MenuItem>
                    ))}             
               </Select>                   
             </FormControl>
                
               <FormControl sx={{ m: 1, minWidth: 120 }}>
               <InputLabel id="page_name">Page Name</InputLabel>
               <Select
                labelId="page_name"
                label="Page Name"
                {...getFieldProps('pageId')}   
                // defaultValue={pageData.pageName}
                sx={{height: '50px'}}
                inputProps={{
                    name: 'pageId',
                    id: 'filled-application-native-simple'
                  }}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
               >
                <MenuItem value="">Select Pages</MenuItem>
                    {pages[0]?.pageData.map((item) => (
                      <MenuItem key={item?._id} value={item?._id} onClick={() => handlePage(item)}>
                        {item?.page}
                      </MenuItem>
                    ))}
               </Select>
               </FormControl>
              
            <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
            </LoadingButton>
            </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UpdateApprovalPage;