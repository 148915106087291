import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { LoadingButton } from '@material-ui/lab';

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
// ----------------------------------------------------------------------
const AssignRoleForm = ({role, onUpdate, onClose}) => {

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [allRoles, setallRoles] = useState([]);
  const [dense, setDense] = React.useState(false);
  const [assignedRole, setAssignedRole] = useState([]);

  const LoginSchema = Yup.object().shape({
    role: Yup.string().required('Status is required'),
  });

  useEffect(() => {   
    
    if(role.roleAttached) {
      console.log(role.roleAttached, 'role attached');
      setAssignedRole(role.roleAttached)
    }
    fetchRoles()
    // fetchAssignedRoles()
  }, [])

  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/ListAllRole`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;          
          console.log(data.data, 'roles data')
          setallRoles(data.data);
        }
      });
  }

  const fetchAssignedRoles = async () => {
    const token = localStorage.getItem('token');
    const userid = localStorage.getItem('_id');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/roleBasedOnUserId?userId=${userid}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log(data.data, 'user wise roles')
          if(data.data.length) {
            setAssignedRole(data.data[0].roleDatData[0])
          }
        }
      });
  }

  const formik = useFormik({
    initialValues: {
      role: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { userId: role._id, roleId: values.role };
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/attachRoleToUser`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            // fetchUser();
            fetchAssignedRoles();
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              // onUpdate()
              // onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const fetchUser = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/listAllActiveUser?userId=${role._id}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },      
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log(data.data, 'current user data');
          setAssignedRole(data.data[0].roleAttached);
        }
      });
  };

  const detachRole = (id) => {    
    const user = { userId: role._id, roleId: assignedRole[0]._id };
    console.log(user, 'user test')
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/detachRoleToUser`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            setAssignedRole([]);
            fetchAssignedRoles();
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
  }

  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  function generate(element) {
    return [0].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  }

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
        {assignedRole.length ? (
          <Grid item xs={12} md={12}>
          <Demo align="center">
            <List dense={dense}>
              {generate(
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <Icon icon="ic:baseline-delete" className="big-icon" onClick={detachRole}/>
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={assignedRole[0].name}
                  />
                </ListItem>,
              )}
            </List>
          </Demo>
        </Grid>
        
        ) : ( <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
                <Select
                  native
                  label="Role"
                  inputProps={{
                    name: 'role',
                    id: 'filled-status-native-simple'
                  }}
                  {...getFieldProps('role')}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                >
                <option key='' value=''/>
                {
                    allRoles.map((item) => (
                      <option key={item._id} value={item._id}>{item.name}</option>
                    ))
                }                  
                </Select>
                </FormControl>
                
                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>)}
         
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AssignRoleForm;