import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import React , { useState, useEffect ,useMemo } from 'react';
import * as CryptoJS from "crypto-js";
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { ToastContainer, toast } from 'react-toastify';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  Paper,
  FormControl,
  InputAdornment,
  TableHead,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  TableBody,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  styled,
  DialogActions,
  DialogContent,
  DeleteIcon
} from '@material-ui/core';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import CloseIcon from '@material-ui/icons/Close';
// import CloseIcon from '@mui/icons-material/Close';
// import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';

import { AssignRoleForm } from '../components/users/assignrole'

import { CreateApplicationForm } from '../components/applications/createapplication'
import { UpdateApplicationForm } from '../components/applications/updateapplication'


//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'applicationType', label: 'Application Type', alignRight: false },
  { id: 'GrantTypes', label: 'Grant Types', alignRight: false },
  { id: 'authenticationType', label: 'Authentication Type', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.description.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const OBJECT1 = {
  description: 'Search Description...',
  applicationType: 'Search Application Type...',
  grantType: 'Search Grant Type...',
  authenticationType: 'Search Authentication type...',
  status: 'Search Status...'
};

export default function User() {
  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [successMessage, setSuccessMessage] = useState('');
  const [delErr, setdelErr] = useState('');
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const [openRoles, setopenRoles] = useState(false);

  const [searchField, setSearchField] = React.useState();
  const [searchObj, setSearchObj] = React.useState({ type: '', search: '' });

  const filteredData = useMemo(() => {
    if (!searchObj?.search?.length || !searchObj.type) {
      return roles;
    }
  
    const { type, search } = searchObj;
  
    const filteredObj = roles.filter(item => {
      const fields = type.split('.');
      const fieldValue = fields.reduce((value, field) => {
        if (Object.prototype.hasOwnProperty.call(value, field)) {
          return value[field];
        }
        return '';
      }, item);
  
      if (typeof fieldValue === 'string' && fieldValue.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      return false;
    });
  
    return filteredObj;
  }, [roles, searchObj]);

  

  useEffect(() => {   
    fetchRoles()
  }, [])

  // Fetch Tasks
  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          console.log(data.data, 'before encryption')
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;          
          console.log(data.data, 'get all application data')
          setRoles(data.data);
          // setRoles([{
          //   description: "EzTravel",
          //   applicationLogo: "Logo.jpg",
          //   applicationType: "REGULAR WEB",
          //   applicationLogin: "/login",
          //   applicationLogoutUri: "www.eztravel.europe.in",
          //   GrantTypes: 'MFA',
          //   applicationAllowedUrl: "test",
          //   applicationRedirectUrl: 'sdsdsdv',
          //   authenticationType: "JWT",
          //   status: "ACTIVE",
          // }]);
        }
      });
  }

  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roles.map((n) => n.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, firstName) => {
    const selectedIndex = selected.indexOf(firstName);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, firstName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChange = (e) => {
    setSearchField(e.value);
  };

  const pageChangeHandler = (search, type) => {
    setSearchObj({ search, type });
  };

  const deleteRole = async (id) => {
    
      const user = { userId: id, isActive : false  };
      console.log(user)
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');      
      fetch(`${process.env.REACT_APP_BASEURL}/api/application/deleteApplication/${id}`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            setSuccessMessage(data.msg);
            fetchRoles()
            notify()
            setTimeout(() => {
              setSuccessMessage('');
            }, 3000);
          } else {
            setdelErr(data.msg);
          }
        });

  }
  
  const editRole = async (id) => {
    console.log(id, 'edit id')
    setOpen(true)
    setRole(id)
  }

  const useStyles = makeStyles({
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
  });
  
  function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Update Role</DialogTitle>
        <UpdateApplicationForm />
      </Dialog>
    );
  }
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
          <Icon icon="simple-line-icons:close" className="big-icon" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };


  const openNewRole = () => {
    setOpenNew(true);
  };

  const closeNewRole = (value) => {
    setOpenNew(false);
    setSelectedValue(value);
  };

  const assignRole = async (id) => {
    console.log('Assign Role')
    setopenRoles(true)
    setRole(id)
  }


  const closeAssignRole = async (id) => {
    setopenRoles(false)
  }

  
  const notify = () => toast.success("Application Deleted");

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const filteredUsers = applySortFilter(roles, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Users | EAI">
      <Container>
        <ToastContainer />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Applications
          </Typography>
          <Button
            variant="contained"
            onClick={openNewRole}
            startIcon={<Icon icon={plusFill} />}
          >
            New Application
          </Button>
        </Stack>

        <Card>
            
        <div style={{ display: 'flex', justifyContent: 'row', margin: '20px 15px' }}>
          <FormControl sx={{ width: '200px', marginRight: '20px' }}>
          <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              label="select"
              onChange={(e) => handleChange(e.target)}
            >
              <MenuItem value="name.description">Description</MenuItem>
              <MenuItem value="name.applicationType">Application Type</MenuItem>
              <MenuItem value="applicationSecret.GrantTypes">Grant Types</MenuItem>
              <MenuItem value="authenticationType">Authentication Type</MenuItem>
              <MenuItem value="status">Status</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="search"
            placeholder={OBJECT1[searchField] ? OBJECT1[searchField] : 'Search'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="ic:round-search" width={22} />
                </InputAdornment>
              )
            }}
            onChange={(e) => pageChangeHandler(e.target.value, searchField)}
          />
        </div>
         

               
        <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Description</StyledTableCell>
                    <StyledTableCell align="left">Application Type</StyledTableCell>
                    <StyledTableCell align="left">Grant Type</StyledTableCell>
                    <StyledTableCell align="left">Authentication Type</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row?._id}>
                        <StyledTableCell align="left">{row?.name.description}</StyledTableCell>
                        <StyledTableCell align="left">{row?.name.applicationType}</StyledTableCell>
                        <StyledTableCell align="left">{row?.applicationSecret.GrantTypes}</StyledTableCell>
                        <StyledTableCell align="left">{row?.authenticationType}</StyledTableCell>
                        <StyledTableCell align="left">{row?.status}</StyledTableCell>
                        <StyledTableCell align="left">
                          <UserMoreMenu role={row} onDelete={deleteRole} onEdit={editRole} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>


            <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Card>
        {/* <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} /> */}
        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Update Application
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <UpdateApplicationForm role={role} onUpdate={fetchRoles} onClose={handleClose}/>
        </DialogContent>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={closeNewRole}
        aria-labelledby="customized-dialog-title"
        open={openNew} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={closeNewRole}>
          New Application
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <CreateApplicationForm onUpdate={fetchRoles} onClose={closeNewRole}/>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={closeAssignRole}
        aria-labelledby="customized-dialog-title"
        open={openRoles} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={closeAssignRole}>
          Assign Role - {role.firstName} {role.lastName}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <AssignRoleForm role={role} onUpdate={fetchRoles} onClose={closeAssignRole}/>
        </DialogContent>
      </BootstrapDialog>

      </Container>
    </Page>
  );
}
