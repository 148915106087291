import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import * as CryptoJS from "crypto-js";
import Cookies from 'js-cookie';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import LoginCustomer from './pages/LoginCustomer';
import SSOLogin from './pages/SSOLogin';
import LoginApp from './pages/LoginApp';
import CreateUser from './pages/CreateUser';
import CreateRole from './pages/CreateRole';
import OTP from './pages/Opt';
import Forget from './pages/ForgetPassword';
import Verify from './pages/VerifyEmail';
import VerifyEmailCustomer from './pages/VerifyEmailCustomer';
import Reset from './pages/ResetPassword';
import Change from './pages/ChangePassword';
import Register from './pages/Register';
import RegisterAssign from './pages/RegisterAssign';

import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import Application from './pages/Application';
import Role from './pages/Role';
import ClientRole from './pages/ClientRole';
import Permission from './pages/Permission';
import Client from './pages/Client';
import POC from './pages/POC';
import Clan from './pages/Clan';
import Email from './pages/Email';
import Sms from './pages/Sms';
import AppPage from './pages/AppPage';
import AssignPageToRole from './pages/AssignPagesToRole';
import ApprovalProcess from './pages/ApprovalProcess';
import SmsTemplate from './pages/SmsTemplate';
import EmailTemplate from './pages/EmailTemplate';

import AppAuth from './pages/AppAuth';
// import { removeUserSession, getUser } from './utils/Auth';
import NotFound from './pages/Page404';
import { removeUserSession, getToken } from './utils/Auth';
import PriceQuote from './pages/PriceQuote';
// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();
  const role = localStorage.getItem('role')
  // let role;
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('_id')
  fetch(`${process.env.REACT_APP_BASEURL}/api/users/listAllActiveUser?userId=${userId}`, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
  })
    .then((res) => res.json())
    .then((data) => {
      const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      data.data = decryptedData;
      console.log(data.data, "All Active User")
      if (data.data[0].roleAttached.length) {
        if(data.data[0].roleAttached[0].name === role) {
          console.log('success')
        }
        else {
          removeUserSession();
          navigate('/login', { replace: true });
        }
      }
      else {
        console.log("Came to else")
        if(role !== "User") {
          removeUserSession();
          navigate('/login', { replace: true });
        }
      }
    })



  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: '/',
          element: getToken() ? (
            <Navigate to="/dashboard/app" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        },
        { path: 'app', element: getToken() ? <DashboardApp /> : <Navigate to="/login" replace /> },
        { path: 'user', element: (getToken() && role === 'Admin') ? <User /> : <Navigate to="/login" replace /> },
        { path: 'eaiapplication', element: (getToken() && role === 'Admin') ? <Application /> : <Navigate to="/login" replace /> },
        { path: 'client', element: (getToken() && role === 'Admin') ? <Client /> : <Navigate to="/login" replace /> },
        { path: 'approvalProcess', element: (getToken() && role === 'Admin') ? <ApprovalProcess /> : <Navigate to="/login" replace /> },
        { path: 'poc', element: (getToken() && role === 'Admin') ? <POC /> : <Navigate to="/login" replace /> },
        { path: 'clan', element: (getToken() && role === 'Admin') ? <Clan /> : <Navigate to="/login" replace /> },

        { path: 'appPage', element: getToken() ? <AppPage /> : <Navigate to="/login" replace /> },
        { path: 'assignPageToRole', element: getToken() ? <AssignPageToRole /> : <Navigate to="/login" replace /> },

        { path: 'priceQuote', element: getToken() ? <PriceQuote /> : <Navigate to="/login" replace />},

        { path: 'role', element: (getToken() && role === 'Admin') ? <Role /> : <Navigate to="/login" replace /> },
        { path: 'Eroleclient', element: (getToken() && role === 'Admin') ? <ClientRole /> : <Navigate to="/login" replace /> },

        { path: 'permission', element: (getToken() && role === 'Admin') ? <Permission /> : <Navigate to="/login" replace /> },

        { path: 'create', element: (getToken() && role === 'Admin') ? <CreateUser /> : <Navigate to="/login" replace /> },
        { path: 'addrole', element: (getToken() && role === 'Admin') ? <CreateRole /> : <Navigate to="/login" replace /> },
        { path: 'products', element: (getToken() && role === 'Admin') ? <Products /> : <Navigate to="/login" replace /> },
        { path: 'blog', element: (getToken() && role === 'Admin') ? <Blog /> : <Navigate to="/login" replace /> },
        { path: 'change', element: getToken() ? <Change /> : <Navigate to="/login" replace /> },

        { path: 'configurationEmail', element: (getToken() && role === 'Admin') ? <Email /> : <Navigate to="/login" replace /> },
        { path: 'emailTemplate', element: (getToken() && role === 'Admin') ? <EmailTemplate /> : <Navigate to="/login" replace /> },
        { path: 'configurationSms', element: (getToken() && role === 'Admin') ? <Sms /> : <Navigate to="/login" replace /> },
        { path: 'smsTemplate', element: (getToken() && role === 'Admin') ? <SmsTemplate /> : <Navigate to="/login" replace /> },

      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'loginandregister', element: <LoginCustomer /> },
        { path: 'ssologin', element: <SSOLogin /> },
        { path: 'applogin', element: <LoginApp /> },
        { path: 'appAuth', element: getToken() ? <AppAuth /> : <Navigate to="/login" replace /> },
        { path: 'otp', element: <OTP /> },
        { path: 'register', element: <Register /> },
        { path: 'registerandassign', element: <RegisterAssign /> },

        { path: 'forgetpassword', element: <Forget /> },
        { path: 'verifyemail', element: <Verify /> },
        { path: 'verifyemailassign', element: <VerifyEmailCustomer /> },
        { path: 'resetpassword/:id', element: <Reset /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
