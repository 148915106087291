import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import * as CryptoJS from "crypto-js";
// material
import {
  Alert,
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { setUserSession } from '../../../utils/Auth';

// ----------------------------------------------------------------------

export default function LoginCustomerForm() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [verifyOTP, setverifyOTP] = useState(true);
  const [verifysentOTP, setverifysentOTP] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setpasswordError] = useState('');
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { email: values.email, password: values.password };
      const encuser = {
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      console.log(encuser, 'encrypted data')
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, 'data')
          setpasswordError('');
          if (data.code === 200) {
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              setverifysentOTP(true);
              setverifyOTP(false);
              // navigate('/otp', { replace: true });
            }, 3000);

            // if (data.data) {
            //   setUserSession(
            //     data.data.userData.email,
            //     data.data.userData.firstName,
            //     data.data.userData.lastName,
            //     data.data.userData.phoneNumber,
            //     data.data.AutherisationToken
            //   );
            //   navigate('/dashboard', { replace: true });
            // } else {
            //   setpasswordError(data.msg);
            // }
          } else {
            setpasswordError(data.msg);
          }
        });

      // let result = fetch('https://localhost:3000/auth/users/login_test', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Accept: 'application/json'
      //   },
      //   body: JSON.stringify(user)
      // });
      // result = result.json();
      // console.log(result, 'result');
      // localStorage.setItem('user-data', JSON.stringify(result));
      // console.log(values, 'values');
    }
  });

  const handleSendOTP = () => {
    const otp = { email: values.email, otp: values.otp };
    const encuser = {
      "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(otp), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
    }
    console.log(otp, 'otp value');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/consumeLogInOtpEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'after login data');
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          console.log(data)
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          if (data.data) {


            const loginData = data.data
            const token = loginData.AutherisationToken;
            const  userId  = loginData.userData._id;
            console.log(userId, 'id test for error')
            fetch(`${process.env.REACT_APP_BASEURL}/api/users/listAllActiveUser?userId=${userId}`, {
              method: 'GET',
              headers: {
                Authorization: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
              },
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.code === 200) {
                  const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
                  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                  data.data = decryptedData;
                  if (data.data[0].applicationData.length === 1) {
                    // redirectToCPTest(data.data[0].applicationData[0]);
                    const item = data.data[0].applicationData[0]
                    const appId = item._id;
                    const Clients = [];
                    fetch(`${process.env.REACT_APP_BASEURL}/api/users/clientBasedOnApplicationAndUser?userId=${userId}&applicationId=${appId}`, {
                      method: 'GET',
                      headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                      }
                    })
                      .then((res) => res.json())
                      .then((data) => {
                        const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
                        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                        data.data = decryptedData;
                        data.data.forEach(element => {
                          Clients.push({ client: element.clientData[0].actualName, role: element.role })
                        });
                        if (Clients.length > 1) {
                          let userRole = ''
                          if (loginData.userRole.length) {
                            userRole = loginData.userRole[0].RolesDAta[0].name
                          }
                          else {
                            userRole = 'User'
                          }
                          setUserSession(
                            loginData.userData.email,
                            loginData.userData.firstName,
                            loginData.userData.lastName,
                            loginData.userData.phoneNumber,
                            loginData.AutherisationToken,
                            loginData.userData._id,
                            loginData.userData.cPortalId,
                            userRole
                          );
                          navigate('/dashboard', { replace: true });
                        }
                        else {
                          // goForAppTest(item)

                          const clientIDE = Clients[0].client
                          const role = 'User'
                          const user = {
                            "clientId": item._id,
                            "redirectUri": item.applicationRedirectUrl,
                            "userId": userId
                          }
                          const encuser = {
                            encryptedData: CryptoJS.AES.encrypt(
                              JSON.stringify(user),
                              '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
                            ).toString()
                          };
                          console.log(user, 'user ata')
                          fetch(`${process.env.REACT_APP_BASEURL}/api/auth/oauth2/grantToken`, {
                            method: 'POST',
                            headers: {
                              Authorization: token,
                              'Content-Type': 'application/json',
                              Accept: 'application/json'
                            },
                            body: JSON.stringify(encuser)
                          })
                            .then((res) => res.json())
                            .then((data) => {
                              const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
                              const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                              data.data = decryptedData;
                              if (data.data.oAuthToken) {
                                window.location.replace(`${item.applicationRedirectUrl}?clientIDE=${clientIDE}&clientID=${item._id}&role=${role}&oAuthtoken=${data.data.oAuthToken}&token=${token}&userId=${userId}&redirectURL=${item.applicationRedirectUrl}&log=true`)
                              }
                            });

                        }
                      });



















                  }
                  else {
                    let userRole = ''
                    if (loginData.userRole.length) {
                      userRole = loginData.userRole[0].RolesDAta[0].name
                    }
                    else {
                      userRole = 'User'
                    }
                    setUserSession(
                      loginData.userData.email,
                      loginData.userData.firstName,
                      loginData.userData.lastName,
                      loginData.userData.phoneNumber,
                      loginData.AutherisationToken,
                      loginData.userData._id,
                      userRole
                    );
                    navigate('/dashboard', { replace: true });
                  }
                }
              });













            // let userRole = ''
            // if (data.data.userRole.length) {
            //   userRole = data.data.userRole[0].RolesDAta[0].name
            // }
            // else {
            //   userRole = 'User'
            // }
            // setUserSession(
            //   data.data.userData.email,
            //   data.data.userData.firstName,
            //   data.data.userData.lastName,
            //   data.data.userData.phoneNumber,
            //   data.data.AutherisationToken,
            //   data.data.userData._id,
            //   userRole
            // );
            // navigate('/dashboard', { replace: true });
          } else {
            setpasswordError(data.msg);
          }
        } else {
          setpasswordError(data.msg);
        }
      });
  };

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <FormikProvider value={formik}>
      {verifysentOTP ? (
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="otp"
            label="OTP"
            name="otp"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSendOTP}
          >
            Submit OTP
          </LoadingButton>
        </Stack>
      ) : (
        ''
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {verifyOTP ? (
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="off"
              type="email"
              label="Email address"
              name="email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="off"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              name="password"
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>
        ) : (
          ''
        )}
        {verifyOTP ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
            />

            <Link component={RouterLink} variant="subtitle2" to="/forgetpassword">
              Forgot password?
            </Link>
          </Stack>
        ) : (
          ''
        )}
        {verifyOTP ? (
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Login
          </LoadingButton>
        ) : (
          ''
        )}
      </Form>
      <br />
      {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
      {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
    </FormikProvider>
  );
}
