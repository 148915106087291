import Cookies from 'js-cookie'

export const setUserSession = (email, firstName, lastName, phoneNumber, token, _id, cportalId, role, userName) => {
  localStorage.setItem('email', email);
  localStorage.setItem('firstName', firstName);
  localStorage.setItem('lastName', lastName);
  localStorage.setItem('phoneNumber', phoneNumber);
  localStorage.setItem('token', token);
  localStorage.setItem('_id', _id);
  localStorage.setItem('cportalId', cportalId);
  localStorage.setItem('role', role);
  localStorage.setItem('userName', userName);  
};

export const removeUserSession = () => {
  localStorage.removeItem('email');
  // localStorage.removeItem('firstName');
  // localStorage.removeItem('lastName');
  localStorage.removeItem('phoneNumber');
  localStorage.removeItem('token');
  
  localStorage.removeItem('ssojwttoken');
  localStorage.removeItem('cp_token');
  localStorage.removeItem('oAuthtoken');
  localStorage.removeItem('redirectUri');
  localStorage.removeItem('clientID');
  localStorage.removeItem('userId');
  localStorage.removeItem('userId');
  localStorage.removeItem('cportalId');
  localStorage.removeItem('_id');
  localStorage.removeItem('logouts');
  Cookies.remove('testCookie')
};

export const getUser = () => {
  const email = localStorage.getItem('email');
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const user = {
    email,
    firstName,
    lastName
  };
  return user;
};

export const getToken = () => localStorage.getItem('token') || false;

// export const getToken = () => {
//   return localStorage.getItem('token');
// };
