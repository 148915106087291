import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
// material
import {
  Box,
  Grid,
  Container,
  Typography,
  IconButton,
  styled,
  DialogContent,
  Stack
} from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { LoadingButton } from '@material-ui/lab';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Cookies from 'js-cookie'
// components
import * as CryptoJS from 'crypto-js';
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';

import { LoginAppForm } from '../components/authentication/loginapp';

import { NewRoleForm } from '../components/roles/newrole';
import Logo from '../components/Logo';
import './ChangePassword.css';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [openNew, setOpenNew] = useState(false);

  const [applications, setApplications] = useState([]);
  
  const [clientsData, setClientsData] = useState([]);
  const [appwiseClients, setAppwiseClients] = useState([]);
  const [userData, setUserData] = useState([]);

  const [appClients, setAppClients] = useState([]);
  const [selectedApp, setSelectedApp] = useState([]);
  let selectedOne = '';
  let selectedUUID = '';
  let selectedRole = '';
  // let selectedApp = '';
  useEffect(() => {
    // fetchRoles();
    fetchUser();
  }, []);

  // Fetch Tasks
  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log(data.data, 'applications data');
          setApplications(data.data);
        }
      });
  };

  const fetchUser = async () => {
    const token = localStorage.getItem('token');
    const _id = localStorage.getItem('_id');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/listAllActiveUser?userId=${_id}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log(data.data, 'current user data');
          setUserData(data.data[0]);
          setClientsData(data.data[0].clientData)
          setAppwiseClients(data.data[0].userapplicationclinetrolesData)
          console.log(data.data[0], 'First Record');
          console.log(data.data[0].applicationData, 'Assigned Data');
          setApplications(data.data[0].applicationData);
          getClients();
          if(data.data[0].applicationData.length === 1) {
            console.log(data.data[0].applicationData[0], 'item for redirectTOCPTest')
            redirectToCPTest(data.data[0].applicationData[0])
          }
        }
      });
  };

  const getClients = async () => {
    const test = '61b1a1bfc37a9b6fa4eae526';
    const appclients = appwiseClients.filter(x => x.applicationId === test);
    const clients = []
    appclients.forEach(element => {
      clients.push(clientsData.filter(x => x._id === element.clientId)[0]);
    });

    // setClientsData(data.data[0].clientData)
    // setAppwiseClients(data.data[0].userapplicationclinetrolesData)

  }

  const redirectToCPOri = async (item) => {
    console.log(item, 'client app data')
    setSelectedApp(item);
    console.log(selectedApp, 'selected app')
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('_id');
    const appId = item._id;
    const Clients = [];
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/clientBasedOnApplicationAndUser?userId=${userId}&applicationId=${appId}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        data.data = decryptedData;
        console.log(data.data, 'clients data')
        data.data.forEach(element => {
          Clients.push({ client: element.clientData[0].actualName, role: element.role, uuid: element.clientData[0].uuid })
        });
        console.log(Clients, 'clients ')
        if (Clients.length > 1) {
          console.log(Clients[0].client, 'first client');
          setOpenNew(true);
          setAppClients(Clients)
        }
        else {
          selectedOne = Clients[0].client;
          selectedUUID = Clients[0].uuid;
          console.log(selectedApp, 'selectedOne')
          console.log(item, 'selectedOne')
          goForApp(item)
        }
      });



    // const token = localStorage.getItem('token');
    // const clientIDE = item.clientData.actualName
    // const userId = localStorage.getItem('_id')
    // const role = 'User'
    // const user = {
    //   "clientId": item._id,
    //   "redirectUri": item.applicationData.applicationRedirectUrl,
    //   "userId": userId
    // }
    // const encuser = {
    //   encryptedData: CryptoJS.AES.encrypt(
    //     JSON.stringify(user),
    //     '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
    //   ).toString()
    // };
    // console.log(user, 'user ata')
    // fetch(`${process.env.REACT_APP_BASEURL}/api/auth/oauth2/grantToken`, {
    //   method: 'POST',
    //   headers: {
    //     Authorization: token,
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json'
    //   },
    //   body: JSON.stringify(encuser)
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //       console.log(data, 'data')
    //       const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
    //       const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //       data.data = decryptedData;
    //       console.log(data.data, 'testing')
    //       console.log(data.data.oAuthToken, 'oAuthToken')
    //       if(data.data.oAuthToken) {
    //         Cookies.set('testCookie', `${data.data.oAuthToken}`)
    //         window.open(`${item.applicationData.applicationRedirectUrl}?clientIDE=${clientIDE}&clientID=${item._id}&role=${role}&oAuthtoken=${data.data.oAuthToken}&token=${token}&userId=${userId}&redirectURL=${item.applicationData.applicationRedirectUrl}`)
    //       }
    //   });


    // Cookies.set('testCookie', 'this is my first cookie')
    // // window.open(`http://localhost:3001/pages/login/sso?clientIDE=${clientIDE}&role=${role}`)
    // window.open(`${item.applicationRedirectUrl}?clientIDE=${clientIDE}&role=${role}&token=${token}&userId=${userId}`)



    // fetch(`https://eztraveluat.europassistance.in:3000/batchJobs/getClaintToken`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json'
    //   }
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //           localStorage.setItem('cp_token', data.token);
    //           Cookies.set('testCookie', 'this is my first cookie')
    //           window.open(`http://localhost:3001/pages/login/sso?token=${data.token}`, '_blank')
    //         });

    // .then((data) => {
    //   console.log(data, 'data to be shown')
    //   const body = {
    //     "userId": localStorage.getItem('_id'),
    //     "token": data.token
    //   }
    //   console.log(body, 'body')
    //   fetch(`https://localhost:3000/batchJobs/jwtSession/create`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Accept: 'application/json'
    //     },
    //     body: JSON.stringify(body)
    //   }).then((result) => {
    //       localStorage.setItem('cp_token', data.token);
    //       Cookies.set('testCookie', 'this is my first cookie')
    //       window.open(`http://localhost:3001/pages/login/sso?token=${data.token}`, '_blank')
    //     });
    // });
  };

  const redirectToCP = async (item) => {
    // console.log(item, 'client app data')
    setSelectedApp(item);
    // console.log(selectedApp, 'selected app')
    const appId = item._id;
    const clientsDataApp = [];
    const appclients = appwiseClients.filter(x => x.applicationId === appId);
    // console.log(appclients, 'appclients')
    // console.log(clientsData, 'clientsData')    
    appclients.forEach(element => {
      clientsDataApp.push(clientsData.filter(x => x._id === element.clientId)[0]);
    });
    const Clients = [];
    clientsDataApp.forEach(element => {
      // console.log(element._id, 'element clientId')
      const role = appclients.filter(x => x.clientId === element._id)
      // console.log(role, 'role')
      Clients.push({ client: element.actualName, role: role[0].role, uuid: element.uuid })
    });    
    // console.log(Clients, 'Clients')
    if (Clients.length > 1) {
      // console.log(Clients[0].client, 'first client');
      setOpenNew(true);
      setAppClients(Clients)
    }
    else {
      selectedOne = Clients[0].client;
      selectedUUID = Clients[0].uuid;
      // console.log(selectedApp, 'selectedOne')
      // console.log(item, 'selectedOne')
      item.currentClient = Clients[0].role
      goForApp(item)
    }
  };

  const redirectToCPTest = async (item) => {
    console.log(item, 'directly to the app')
    console.log(item, 'direct app item at redirectToCPTest')
    setSelectedApp(item);
    console.log(selectedApp, 'selected app')
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('_id');
    const appId = item._id;
    const Clients = [];
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/clientBasedOnApplicationAndUser?userId=${userId}&applicationId=${appId}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        data.data = decryptedData;
        console.log(data.data, 'clients data')
        data.data.forEach(element => {
          Clients.push({ client: element.clientData[0].actualName, role: element.role })
        });
        console.log(Clients, 'clients ')
        console.log(Clients[0].client, 'first client');
        if (Clients.length > 1) {
          setOpenNew(true);
          setAppClients(Clients)
        }
        else {
          selectedOne = Clients[0].client
          goForAppTest(item)
        }
      });
  };


  const redirectToCPTestNew = async (item) => {
    console.log(item, 'directly to the app')
    console.log(item, 'direct app item at redirectToCPTest')
    setSelectedApp(item);
    console.log(selectedApp, 'selected app')
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('_id');
    const appId = item._id;

    const clientsDataApp = [];
    const appclients = appwiseClients.filter(x => x.applicationId === appId);
    appclients.forEach(element => {
      clientsDataApp.push(clientsData.filter(x => x._id === element.clientId)[0]);
    });
    const Clients = [];
    clientsDataApp.forEach(element => {
      Clients.push({ client: element.actualName, role: 'Admin', uuid: element.uuid })
    });
    if (Clients.length > 1) {
      setOpenNew(true);
      setAppClients(Clients)
    }
    else {
      selectedOne = Clients[0].client
      goForAppTest(item)
    }
  };



  const openNewRole = () => {
    setOpenNew(true);
  };

  const closeNewRole = (value) => {
    setOpenNew(false);
  };

  const handleChange = (e) => {
    console.log(e.target.value, 'value')

    console.log(appClients[e.target.value], 'test')
    console.log(appClients[e.target.value].uuid, 'uuid test')
    selectedOne = appClients[e.target.value].client
    selectedUUID = appClients[e.target.value].uuid
    selectedRole = appClients[e.target.value].role
    console.log(appClients, 'appClients')
    console.log(selectedUUID, 'selected UUID')
    console.log(selectedOne, 'client')
    console.log(selectedRole, 'role')

    // console.log(e.target.value, 'value')
    // const test = e.target.value
    // console.log(JSON.stringify(test), 'stringify')

    // console.log(test, 'test')
    // console.log(test.client, 'test')
    // console.log(e.target.value.client, 'value')

    // console.log(selectedOne, 'selected client')
    // console.log(selectedOne, 'selected role')
  }

  const goForApp = (item) => {
    console.log(item, 'final items')
    setOpenNew(false);
    const token = localStorage.getItem('token');
    console.log('app name')
    console.log(item.name.description, 'test app name')
    const appName = item.name.description
    console.log(appName, 'application Name')
    const clientIDE = selectedOne
    const clientUUID = selectedUUID
    const userId = localStorage.getItem('_id')
    
    
    const role = item.currentClient ? item.currentClient : selectedRole
    const user = {
      "clientId": item._id,
      "redirectUri": item.applicationRedirectUrl,
      "userId": userId
    }
    const encuser = {
      encryptedData: CryptoJS.AES.encrypt(
        JSON.stringify(user),
        '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
      ).toString()
    };
    fetch(`${process.env.REACT_APP_BASEURL}/api/auth/oauth2/grantToken`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        data.data = decryptedData;
        const _id = localStorage.getItem('_id');
        const email = localStorage.getItem('email');
        const firstName = localStorage.getItem('firstName');
        const lastName = localStorage.getItem('lastName');
        const phoneNumber = localStorage.getItem('phoneNumber');
        const cportalId = localStorage.getItem('cportalId')
        const userName = localStorage.getItem('userName')
        
        if (data.data.oAuthToken) {
          let logoutUrls = []
          logoutUrls = localStorage.getItem('logouts') ? localStorage.getItem('logouts') : '[]';
          console.log(logoutUrls, 'urls urls ')
          logoutUrls = JSON.parse(logoutUrls)

          const test = logoutUrls.includes(item.applicationAllowedUrl);
          if(test === false) {
            logoutUrls.push(item.applicationAllowedUrl)
          }
          localStorage.setItem('logouts', JSON.stringify(logoutUrls));
          
          if(item.name.description === 'Eclaims') {
            // goToApp();
            // window.open(`${item.applicationRedirectUrl}?clientIDE=${clientIDE}&clientID="${item._id}"&role=${role}&oAuthtoken=${data.data.oAuthToken}&token=${token}&userId=${userId}&redirectURL=${item.applicationRedirectUrl}&_id=${_id}&isActive=true&email=${email}&firstName=${firstName}&lastName=${lastName}&phoneNumber=${phoneNumber}`)
            // window.open(`https://eclaimsuat.europassistance.in/sso.html?clientIde=TATA AIG&&id=61b213e035642e799fa201d0&&isActive=true&&email=${email}&&firstName=${firstName}&&lastName=${lastName}%20test&&phoneNumber=${phoneNumber}&&clientId=996fcc89-8d91-4ffd-9b04-61a116655c7d&&clientIDE=996fcc89-8d91-4ffd-9b04-61a116655c7d`, '_blank')
            window.open(`${item.applicationRedirectUrl}?appName=${appName}&clientIDE=${clientIDE}&clientID=${item._id}&role=${role}&oAuthtoken=${data.data.oAuthToken}&token=${token}&userId=${cportalId}&redirectURL=${item.applicationRedirectUrl}&_id=${_id}&isActive=true&email=${email}&firstName=${firstName}&lastName=${lastName}&phoneNumber=${phoneNumber}&uuid=${clientUUID}`)
          }
          else {
            window.open(`${item.applicationRedirectUrl}?appName=${appName}&clientIDE=${clientIDE}&clientID=${item._id}&role=${role}&oAuthtoken=${data.data.oAuthToken}&token=${token}&userId=${cportalId}&redirectURL=${item.applicationRedirectUrl}&_id=${_id}&isActive=true&email=${email}&userName=${userName}&firstName=${firstName}&lastName=${lastName}&phoneNumber=${phoneNumber}`)
          }
        }
      });

  }

  const goForAppTest = (item) => {
    setOpenNew(false);
    console.log(item, 'direct app item')
    console.log("came to direct to client app ")
    const token = localStorage.getItem('token');
    const clientIDE = selectedOne
    const userId = localStorage.getItem('_id')
    const role = 'User'
    const user = {
      "clientId": item._id,
      "redirectUri": item.applicationRedirectUrl,
      "userId": userId
    }
    const encuser = {
      encryptedData: CryptoJS.AES.encrypt(
        JSON.stringify(user),
        '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
      ).toString()
    };
    console.log(user, 'user ata')
    fetch(`${process.env.REACT_APP_BASEURL}/api/auth/oauth2/grantToken`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        data.data = decryptedData;
        if (data.data.oAuthToken) {
          Cookies.set('testCookie', `${data.data.oAuthToken}`)
          window.location.replace(`${item.applicationRedirectUrl}?clientIDE=${clientIDE}&clientID=${item._id}&role=${role}&oAuthtoken=${data.data.oAuthToken}&token=${token}&userId=${userId}&redirectURL=${item.applicationRedirectUrl}&log=true`)
        }
      });

  }


  const goToApp = (id) => {
    const baseURL = id.applicationRedirectUrl
    const clientIDE = "TATA AIG"
    const _id = localStorage.getItem('_id')
    const email = localStorage.getItem('email')
    const firstName = localStorage.getItem('firstName')
    const lastName = localStorage.getItem('lastName')
    const phoneNumber = localStorage.getItem('phoneNumber')
    const clientID = '996fcc89-8d91-4ffd-9b04-61a116655c7d'

    window.open(`https://${baseURL}/sso.html?clientIde=${clientIDE}&&id=${_id}&&isActive=true&&email=${email}&&firstName=${firstName}&&lastName=${lastName}%20test&&phoneNumber=${phoneNumber}&&clientId=${clientID}&&clientIDE=${clientIDE}`, '_blank')
    // window.open(`https://${baseURL}/sso.html?clientIde=${clientIDE}&&id=${data.data.userData._id}&&isActive=true&&email=${data.data.userData.email}&&firstName=${data.data.userData.firstName}&&lastName=${data.data.userData.lastName}%20test&&phoneNumber=${data.data.userData.phoneNumber}&&clientId=${clientID}&&clientIDE=${clientIDE}`, '_blank')
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
      padding: theme.spacing(2)
    },
    '& .MuDialogActions-root': {
      padding: theme.spacing(1)
    }
  }));
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Icon icon="simple-line-icons:close" className="big-icon" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Page title="Dashboard | EAI">
      <Container maxWidth="xl">
        {/* <Logo  className="logo_mainPage"/> */}
        <Grid container spacing={3}>
          {applications.map((item) => (

            <Grid item xs={12} sm={6} md={3} onClick={() => redirectToCP(item)}>
              <NewRoleForm role={item.name.description} desc={item.applicationSecret.applicationLogoutUri} logo={item?.name?.applicationLogo}/>
            </Grid>

            // item.name.description === 'Client Portal' ? (
            //   <Grid item xs={12} sm={6} md={3} onClick={() => redirectToCP(item)}>
            //     <NewRoleForm role={item.name.description} desc={item.applicationSecret.applicationLogoutUri} />
            //   </Grid>
            // ) : (
            //   <Grid item xs={12} sm={6} md={3} onClick={() => openNewRole(item)}>
            //     <NewRoleForm role={item.name.description} desc={item.applicationSecret.applicationLogoutUri} />
            //   </Grid>
            // )


          ))}

          {/* <Grid item xs={12} sm={6} md={3} onClick={openNewRole}>
            <AppWeeklySales />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid> */}
        </Grid>
        <BootstrapDialog
          onClose={closeNewRole}
          aria-labelledby="customized-dialog-title"
          open={openNew}
          fullWidth
          maxWidth="sm"
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={closeNewRole}>
            SSO
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {/* <LoginAppForm /> */}

            <Stack spacing={3}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-helper-label">Clients</InputLabel>
                <Select
                  native
                  label="Clients"
                  onChange={handleChange}
                  inputProps={{
                    name: 'approle',
                    id: 'filled-status-native-simple'
                  }}
                >
                  <option key="" value="" />
                  {appClients.map((option, index) => (
                    <option key={option.client} value={index}>
                      {option.client}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => goForApp(selectedApp)}>
                Submit
              </LoadingButton>
            </Stack>

          </DialogContent>
        </BootstrapDialog>
      </Container>
    </Page>
  );
}
