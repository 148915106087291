// import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
// import { Card, Container } from '@material-ui/core';
// import { Card, Stack, Link, Container, Typography } from '@material-ui/core';
// layouts
// import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
// import { MHidden } from '../components/@material-extend';
import { ChangeForm } from '../components/authentication/change';
// import AuthSocial from '../components/authentication/AuthSocial';
import './ChangePassword.css';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2)
// }));

// const ContentStyle = styled('div')(({ theme }) => ({
//   maxWidth: 480,
//   margin: 'auto',
//   display: 'flex',
//   minHeight: '100vh',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: theme.spacing(12, 0)
// }));

// ----------------------------------------------------------------------

export default function ChangePassword() {
  return (
    <RootStyle title="Change Password | EAI">
      <Container maxWidth="sm">
        <div>
          <div className="middle">
            <ChangeForm />
          </div>
        </div>
      </Container>
    </RootStyle>
  );
}
