import React, { useEffect, useMemo ,useState} from 'react';
import { flushSync } from 'react-dom';
import { Icon } from '@iconify/react';
import * as CryptoJS from 'crypto-js';
import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Card,
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ToastContainer, toast } from 'react-toastify';
import { Form, FormikProvider, useFormik } from 'formik';
import editFill from '@iconify/icons-eva/edit-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import { UserMoreMenu } from '../components/_dashboard/user';
import AddNewPageModal from './AddNewPageModal';
import './styles.css';
import { CreateApproval } from '../components/documentApproval/createApproval'
import UpdateApprovalPage from './UpdateApprovalPage';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const HeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '15px'
}));

const AppMainPage = styled('div')(({ theme }) => ({
  padding: '10px'
}));

const OBJECT1 = {
  page: 'Search Page Name...',
  applicationName: 'Search Application Name...',
  status: 'Search Status...'
};

export default function ApprovalProcess() {
  const [open, setOpen] = React.useState(false);
  const [isUpdatePageOpen, setIsUpdatePageOpen] = React.useState(false);
  const [pageData, setPageData] = React.useState([]);
  const [searchObj, setSearchObj] = React.useState({ type: '', search: '' });
  const [successMessage, setSuccessMessage] = React.useState('');
  const [projectName, setProjectName] = React.useState([]);
  const [searchProject, setSearchProject] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [assignedPages, setAssignedPages] = useState([]);
  const [appPages, setAppPages] = useState([]);
  const [client, setClient] = useState([]);
  const [approvalData, setApprovalData] = useState([]);
  const [index, setIndex] = useState(0);
  const [rolesOfClient, setRolesOfClient] = useState([]);
  const [openNew, setOpenNew] = useState(false);

  const filteredData = useMemo(() => {
    if (!searchObj?.search?.length || !searchObj.type) {
      return appPages;
    }
    const filteredObj = appPages.filter((item) => {
      const filteredItems = item?.[searchObj.type]
        ?.toLowerCase()
        .includes(searchObj.search.toLowerCase());
      return filteredItems;
    });
    return filteredObj;
  }, [appPages, searchObj]);

  useEffect(() => {
    getAppPageData();
    fetchRoles();
    fetchRolesMaster();
    fetchClient();
    fetchApprovalData();
  }, []);

  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          console.log(data.data, 'before encryption')
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;          
          console.log(data.data, 'get all application data');
          setProjectName(data.data);
          // setRoles(data.data);
          // setRoles([{
          //   description: "EzTravel",
          //   applicationLogo: "Logo.jpg",
          //   applicationType: "REGULAR WEB",
          //   applicationLogin: "/login",
          //   applicationLogoutUri: "www.eztravel.europe.in",
          //   GrantTypes: 'MFA',
          //   applicationAllowedUrl: "test",
          //   applicationRedirectUrl: 'sdsdsdv',
          //   authenticationType: "JWT",
          //   status: "ACTIVE",
          // }]);
        }
      });
  }

  const formik = useFormik({
    initialValues: {
      pageId: '',
      page: '',
      status: ''
    },
    enableReinitialize: true,
    onSubmit: () => {
      const token = localStorage.getItem('token');
      console.log('App page values', values);
      const bodyData = {
        pageId: '123',
        page: values?.page,
        status: values?.status
      };
      const encData = {
        encryptedData: CryptoJS.AES.encrypt(
          JSON.stringify(bodyData),
          '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
        ).toString()
      };
      fetch(`${process.env.REACT_APP_BASEURL}/api/page/updatePage`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encData)
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('App page data', data);
        })
        .catch((err) => console.log('err', err));
    }
  });

  const notify = () => toast.success('Approval Page Deleted');

  const deleteAppPage = async (id) => {
    const documnetMasteId = { documnetMasteId: id };
    console.log(documnetMasteId);
    const encuser = {
      encryptedData: CryptoJS.AES.encrypt(
        JSON.stringify(documnetMasteId),
        '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
      ).toString()
    };
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/deleteDocumentApprovalMaster`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          notify();
          fetchApprovalData();
          setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
        }
      })
      .catch((err) => console.log('err', err));
  };

  const editAppPage = async (data) => {
    setIsUpdatePageOpen(true);
    setPageData(data);
  };

  const handleCloseUpdateAppPage = () => {
    setIsUpdatePageOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const openNewRole = () => {
    setOpenNew(true);
  };

  const closeNewRole = (value) => {
    setOpenNew(false);
    // setSelectedValue(value);
  };



  const pageChangeHandler = (search, type) => {
    setSearchObj({ search, type });
  };

  const applicationChangeHandler = (search) => {
    setSearchObj({ search, type: 'applicationName' });
  };

  const statusChangeHandler = (search) => {
    setSearchObj({ search, type: 'status' });
  };

  const fetchRolesMaster = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getApplicationRoleMaste`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;  
          console.log(data.data, 'role master data')
          // setRole(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }

  const getAppPageData = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/page/getAllPage`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          setAppPages(decryptedData);
          console.log('All Pages', decryptedData);
        }
      })
      .catch((err) => console.log('app page data error', err));
  };


  const fetchClient = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          console.log(data.data, 'client data')       
          setClient(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }

  const fetchApprovalData = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getDocumentApprovalMaster`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          console.log(data.data, 'Approval data')       
          setApprovalData(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }
  
  const pages = appPages.filter(
    (item) => item?.applicationName === searchProject
  );

 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const { values, handleSubmit } = formik;

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
      padding: theme.spacing(2)
    },
    '& .MuDialogActions-root': {
      padding: theme.spacing(1)
    }
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Icon icon="simple-line-icons:close" className="big-icon" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <>
      <AppMainPage>
        <ToastContainer />
        <Card className="mainTitles">
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{mb: 5}} className="mainbuttons">
        <HeadWrapper>
          <h2 style={{ marginLeft: '15px' }}>Document Approval</h2>
        </HeadWrapper>
        <Button
              variant="contained"
              onClick={openNewRole}
              startIcon={<Icon icon={plusFill} />}
            >
              New Document Approval
            </Button> 
        </Stack>
        </Card>
        <Card>        
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Project Name</StyledTableCell>
                    <StyledTableCell align="left">Page Name</StyledTableCell>
                    <StyledTableCell align="left">Client</StyledTableCell>
                    <StyledTableCell align="left">User Role</StyledTableCell>
                    <StyledTableCell align="left">Sequence</StyledTableCell>
                    <StyledTableCell align="left">Action Of Role</StyledTableCell>
                    <StyledTableCell align="left">Range</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
              {approvalData.map((row) => (
                <StyledTableRow key={row.sr}>
                  <StyledTableCell align="left">{row.projectName}</StyledTableCell>
                  <StyledTableCell align="left">{row.pageName}</StyledTableCell>
                  <StyledTableCell align="left">{row.clientName}</StyledTableCell>
                  <StyledTableCell align="left">{row.roleName}</StyledTableCell>
                  <StyledTableCell align="left">{row.roleData[0]?.sequence}</StyledTableCell>
                    <StyledTableCell align="left">{row.roleData[0]?.actionOfRole}</StyledTableCell>
                    <StyledTableCell align="left">{row.roleData[0]?.rangeFrom}  to  {row.roleData[0]?.rangeTo}</StyledTableCell>
                  <TableCell align="left">
                            <UserMoreMenu role={row}  onDelete={deleteAppPage} onEdit={editAppPage}/>
                          </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
              </Table>
            </TableContainer>
          </Form>
        </FormikProvider>       
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Card>
      </AppMainPage>
      <BootstrapDialog
        onClose={handleCloseUpdateAppPage}
        aria-labelledby="customized-dialog-title"
        open={isUpdatePageOpen}
        fullWidth
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseUpdateAppPage}>
          Update Approval Page
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <UpdateApprovalPage
            pageData={pageData}
            handleClose={handleCloseUpdateAppPage}
            onUpdate={fetchApprovalData}
          />
        </DialogContent>       
      </BootstrapDialog>

      <BootstrapDialog
        onClose={closeNewRole}
        aria-labelledby="customized-dialog-title"
        open={openNew} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={closeNewRole}>
          New Document Approval
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <CreateApproval client={client} onUpdate={fetchApprovalData} onClose={closeNewRole}/>
        </DialogContent>
      </BootstrapDialog>

      <AddNewPageModal
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        onUpdate={getAppPageData}
      />
    </>
  );
}
