import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import * as CryptoJS from "crypto-js";
// material
import {
  Alert,
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { setUserSession } from '../../../utils/Auth';

// ----------------------------------------------------------------------
export default function SSOLoginForm() {
  
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  
  const [baseURL, setBaseURL] = useState('');
  const [clientID, setClientID] = useState('');
  const [clientIDE, setClientIDE] = useState('');
  const [verifyOTP, setverifyOTP] = useState(true);
  const [verifysentOTP, setverifysentOTP] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setpasswordError] = useState('');
  const { id } = useParams();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
  });

  const formik = useFormik({    
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      // console.log(id, 'params value');
      // const obj = JSON.parse(id)

      const authResult = new URLSearchParams(window.location.search); 
      const base = authResult.get('base')
      const clientId = authResult.get('clientId')
      const clientIDE = authResult.get('clientIDE')
      
      setBaseURL(base)
      setClientID(clientId)
      setClientIDE(clientIDE)
      const user = { email: values.email, password: values.password, redirectUrl: base };
      console.log(user, 'body')
      const encuser = {
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      console.log(encuser, 'encrypted data')
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/ssoLogIn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          setpasswordError('');
          if (data.code === 200) {
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              setverifysentOTP(true);
              setverifyOTP(false);
              // navigate('/otp', { replace: true });
            }, 3000);

            // if (data.data) {
            //   setUserSession(
            //     data.data.userData.email,
            //     data.data.userData.firstName,
            //     data.data.userData.lastName,
            //     data.data.userData.phoneNumber,
            //     data.data.AutherisationToken
            //   );
            //   navigate('/dashboard', { replace: true });
            // } else {
            //   setpasswordError(data.msg);
            // }
          } else {
            setpasswordError(data.msg);
          }
        });

    }
  });

  const handleSendOTP = () => {
    const otp = { email: values.email, otp: values.otp };
    const encuser ={
      "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(otp), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
    }
    console.log(otp, 'otp value');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/consumeLogInOtpEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'after login data');
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          console.log(data)
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data=decryptedData;
          if (data.data) {
            
            setUserSession(
              data.data.userData.email,
              data.data.userData.firstName,
              data.data.userData.lastName,
              data.data.userData.phoneNumber,
              data.data.AutherisationToken,
              data.data.userData._id
            );
            // navigate('/dashboard', { replace: true });
            // window.open(`https://${baseURL}/sso.html?clientIde=${clientIDE}&&id=${data.data.userData._id}&&isActive=true&&email=${data.data.userData.email}&&firstName=${data.data.userData.firstName}&&lastName=${data.data.userData.lastName}%20test&&phoneNumber=${data.data.userData.phoneNumber}&&clientId=${clientID}&&clientIDE=${clientIDE}, '_blank');
            window.open(`https://${baseURL}/sso.html?clientIde=${clientIDE}&&id=${data.data.userData._id}&&isActive=true&&email=${data.data.userData.email}&&firstName=${data.data.userData.firstName}&&lastName=${data.data.userData.lastName}%20test&&phoneNumber=${data.data.userData.phoneNumber}&&clientId=${clientID}&&clientIDE=${clientIDE}`, '_blank')
            // window.location = `https://${baseURL}/sso.html?clientIde=${clientIDE}&&id=${data.data.userData._id}&&isActive=true&&email=${data.data.userData.email}&&firstName=${data.data.userData.firstName}&&lastName=${data.data.userData.lastName}%20test&&phoneNumber=${data.data.userData.phoneNumber}&&clientId=${clientID}&&clientIDE=${clientIDE}, '_blank'`;
            navigate('/dashboard', { replace: true });
            
          } else {
            setpasswordError(data.msg);
          }
        } else {
          setpasswordError(data.msg);
        }
      });
  };

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  const testChange = (e) => {
    const authResult = new URLSearchParams(window.location.search); 
    console.log(authResult, 'auth result')
    const base = authResult.get('base')
    const clientId = authResult.get('clientId')
    const clientIDE = authResult.get('clientIDE')
    console.log(base)
    console.log(clientId)
    console.log(clientIDE)
  };

  

  return (
    <FormikProvider value={formik}>
      {verifysentOTP ? (
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="off"
            type="otp"
            label="OTP"
            name="otp"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSendOTP}
          >
            Submit OTP
          </LoadingButton>
        </Stack>
      ) : (
        ''
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {verifyOTP ? (
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="off"
              type="email"
              label="Email address"
              name="email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="off"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              name="password"
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={testChange}>
            Login 
          </LoadingButton>
          </Stack>
        ) : (
          ''
        )}
        
      </Form>
      <br />
      {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
      {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
    </FormikProvider>
  );
}
