import * as React from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TextField, TableBody, TableHead, TableRow, Table, styled, Paper } from '@mui/material';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import AssignRoleToUserModal from './AssignRoleToUserModal';
import "./styles.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 5
  }
}));

const rows = [
  {
    sr: 1,
    user: 'Kusum Rajbhar',
    application: 'Eclaims',
    client: 'TATA AIG',
    role: 'User'
  },
  {
    sr: 2,
    user: '',
    application: 'Eclaims',
    client: 'Relience',
    role: 'Admin'
  },
  {
    sr: 3,
    user: '',
    application: 'Client Portal',
    client: 'TATA AIG',
    role: 'Admin'
  },
  {
    sr: 4,
    user: 'Kusum',
    application: 'Eclaims',
    client: 'Relience',
    role: 'user'
  }
];

const AppMainPage = styled('div')(({ theme }) => ({
  padding: '10px'
}));

export default function AssignPagesToRole() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppMainPage>
        <h2 style={{ marginLeft: '15px', marginBottom: '10px' }}>Assign Role</h2>
        <hr className='divider' />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell align="left">User</StyledTableCell>
                <StyledTableCell align="left">Application</StyledTableCell>
                <StyledTableCell align="left">Client</StyledTableCell>
                <StyledTableCell align="left">Role</StyledTableCell>
                <StyledTableCell align="left">Actions</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <TextField type="search" placeholder="S.No" size="small" />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TextField type="search" placeholder="User" size="small" />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TextField type="search" placeholder="Application" size="small" />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TextField type="search" placeholder="Client" size="small" />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TextField type="search" placeholder="Role" size="small" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.sr}>
                  <StyledTableCell component="th" scope="row">
                    {row.sr}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.user}</StyledTableCell>
                  <StyledTableCell align="left">{row.application}</StyledTableCell>
                  <StyledTableCell align="left">{row.client}</StyledTableCell>
                  <StyledTableCell align="left">{row.role}</StyledTableCell>
                  <StyledTableCell align="left">
                    <Icon icon={editFill} width={24} height={24} onClick={handleOpen}/>
                    {/* <Icon
                      style={{ cursor: 'pointer' }}
                      onClick={handleOpen}
                      icon="material-symbols:edit-outline-sharp"
                      width={24}
                    /> */}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AppMainPage>
      <AssignRoleToUserModal handleClose={handleClose} handleOpen={handleOpen} open={open} />
    </>
  );
}
