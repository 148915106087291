import React, { useState } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { Alert} from '@material-ui/core';
import CryptoJS from 'crypto-js';

const ChangePasswordModal = ({ userRole }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  console.log(userRole);

  const changePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('New Password Required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      ),
    confirmNewPassword: Yup.string()
      .required('Confirm Password Required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
      .oneOf([Yup.ref('newPassword'), null], 'Password must match')
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: changePasswordSchema,
    onSubmit: () => {
      console.log('submitted');

      if(values.newPassword === values.confirmNewPassword){
      
      const user = { email: userRole.email, newPassword: values.newPassword };
      console.log(user);
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/resetPassword`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'data')
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          values.newPassword = '';
          values.confirmNewPassword = '';
          setTimeout(() => {
            setSuccessMessage('');
           
          }, 3000);

        
        } else {
          setSuccessMessage(data.msg);
        }
      });
      
    }
    }
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmNewPassword = () => {
    setShowConfirmNewPassword((show) => !show);
  };

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none'
          },
          marginLeft: '15%'
        }}
      >
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>{userRole?.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>User Name</TableCell>
          <TableCell>{userRole?.userName}</TableCell>
        </TableRow>
      </Table>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            placeholder="New Password"
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            name="newPassword"
            {...getFieldProps('newPassword')}
            sx={{ marginBottom: '25px', marginTop: '25px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.newPassword) && errors.newPassword}
            helperText={touched.newPassword && errors.newPassword}
          />
          <TextField
            fullWidth
            placeholder="Confirm New Password"
            autoComplete="off"
            type={showConfirmNewPassword ? 'text' : 'password'}
            name="confirmNewPassword"
            {...getFieldProps('confirmNewPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={handleShowConfirmNewPassword} edge="end">
                    <Icon icon={showConfirmNewPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmNewPassword) && errors.confirmNewPassword}
            helperText={touched.confirmNewPassword && errors.confirmNewPassword}
          />
          <Button variant="contained" size="large" sx={{ marginTop: '35px' }} fullWidth  type='submit'>
            Submit
          </Button>
          {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
        </Form>
      </FormikProvider>
    </>
  );
};

export default ChangePasswordModal;
