import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useMemo } from 'react';
import * as CryptoJS from "crypto-js";
import plusFill from '@iconify/icons-eva/plus-fill';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  styled,
  DialogActions,
  DialogContent,
  DeleteIcon
} from '@material-ui/core';
import {
 
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select, 
  TableHead,
  TextField
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@mui/material/Tooltip';
// import CloseIcon from '@material-ui/icons/Close';
// import CloseIcon from '@mui/icons-material/Close';
// import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { CSVLink } from "react-csv";
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenuTwo } from '../components/_dashboard/user';
import { UpdateUserForm } from '../components/users/updateuser'
import { CreateUserForm } from '../components/users/createuser'
import { AssignRoleForm } from '../components/users/assignrole'
import { AssignAppForm } from '../components/users/assignapp'
import './styles.css';
import ChangePasswordModal from '../components/_dashboard/user/ChangePasswordModal';
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const TABLE_HEAD = [
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'phoneNumber', label: 'Phone No', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'userName', label: 'User Name', alignRight: false },
  { id: 'Applications', label: 'Assigned Apps', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------
const HeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '15px'
}));

const AppMainPage = styled('div')(({ theme }) => ({
  padding: '10px'
}));

const OBJECT1 = {
  page: 'Search Page Name...',
  applicationName: 'Search Application Name...',
  status: 'Search Status...'
};


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [searchObj, setSearchObj] = React.useState({ type: '', search: '' });
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [successMessage, setSuccessMessage] = useState('');
  const [searchField, setSearchField] = React.useState();
  const [delErr, setdelErr] = useState('');
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const [userRole, setUserRole] = useState([]);

  const [openRoles, setopenRoles] = useState(false);

  const [openApps, setopenApps] = useState(false);

  const filteredData = useMemo(() => {
    if (!searchObj?.search?.length || !searchObj.type) {
      return roles;
    }
    const filteredObj = roles.filter((item) => {
      const filteredItems = item?.[searchObj.type]
        ?.toLowerCase()
        .includes(searchObj.search.toLowerCase());
      return filteredItems;
    });
    return filteredObj;
  }, [roles, searchObj]);

 


  useEffect(() => {
    fetchRoles()
  }, [])

  // Fetch Tasks
  const fetchRoles = async () => {
    console.log("came to retrieve")
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/listAllActiveUser`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log(data.data, 'All Active User')
          setRoles(data.data);
        }
      });
  }

  const handleChange = (e) => {
    setSearchField(e.value);
  };

  const handleChangePasswordOpen = (role) => {
    setIsOpenChangePassword(true);
    setUserRole(role);
   }

   const handleChangePasswordClose = () => {
    setIsOpenChangePassword(false);
   }
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roles.map((n) => n.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, firstName) => {
    const selectedIndex = selected.indexOf(firstName);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, firstName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const notify = () => toast.success("User Deleted");

  const deleteRole = async (id) => {

    const user = { userId: id };
    console.log(user)
    const encuser = {
      "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
    }
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/deleteUser`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          fetchRoles()
          notify()
          setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
        } else {
          setdelErr(data.msg);
        }
      });

  }

  const editRole = async (id) => {
    console.log(id, 'edit id')
    setOpen(true)
    setRole(id)
  }

  const useStyles = makeStyles({
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
  });

  function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
      onClose(value);
    };

    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Update Role</DialogTitle>
        <UpdateUserForm />
      </Dialog>
    );
  }
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon icon="simple-line-icons:close" className="big-icon" />
          </IconButton>

        ) : null}
      </DialogTitle>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };


  const openNewRole = () => {
    setOpenNew(true);
  };

  const closeNewRole = (value) => {
    setOpenNew(false);
    setSelectedValue(value);
  };

  const assignRole = async (id) => {
    console.log('Assign Role')
    setopenRoles(true)
    setRole(id)
  }


  const closeAssignRole = async (id) => {
    fetchRoles()
    setopenRoles(false)
  }

  const assignApplication = async (id) => {
    console.log('Assign App')
    setopenApps(true)
    setRole(id)
  }

  const closeAssignApp = async (id) => {
    fetchRoles()
    setopenApps(false)
  }
  
  const pageChangeHandler = (search, type) => {
    setSearchObj({ search, type });
  };



  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - roles.length) : 0;

  const filteredUsers = applySortFilter(roles, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const userData = [];
  roles.map((item) => (
    userData.push({
      'First Name' : item?.firstName, 
      'Last Name' : item?.lastName, 
      'Email': item?.email , 
      'Phone Number' : item?.phoneNumber,
      'User Name' : item?.userName,
      'Application' : item?.applicationData.map((value) => (
        value?.name?.description
        )),
      'Status' : item?.isActive ? 'Active' : "Inactive"
    })
  ));

  return (
    <Page title="Users | EAI">
      <Container>
      <AppMainPage>
        <ToastContainer />
        <HeadWrapper>
          <h2 style={{ marginLeft: '15px' }}>Users</h2>
          <div>
          <Button variant='contained' sx={{marginRight: '10px'}}>
          <CSVLink
            data={userData}
            filename="user-info-file.csv"
            style={{color: "#ffffff", textDecoration: "none"}}
            target="_blank"
            onClick={fetchRoles}
          >
            Download 
          </CSVLink>
          </Button>
          <Button
            variant="contained"
            onClick={openNewRole}
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button>
          </div>
        
        </HeadWrapper>
        <hr className="divider" />
        <div style={{ display: 'flex', justifyContent: 'row', margin: '20px 15px' }}>
          <FormControl sx={{ width: '200px', marginRight: '20px' }}>
          <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              label="select"
              onChange={(e) => handleChange(e.target)}
            >
              
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="phoneNumber">Phone Number</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="userName">User Name</MenuItem>
              <MenuItem value="isActive">Status</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="search"
            placeholder={OBJECT1[searchField] ? OBJECT1[searchField] : 'Search'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="ic:round-search" width={22} />
                </InputAdornment>
              )
            }}
            onChange={(e) => pageChangeHandler(e.target.value, searchField)}
          />
        </div>

     <Card>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">First Name</StyledTableCell>
                    <StyledTableCell align="left">Last Name</StyledTableCell>
                    <StyledTableCell align="left">Phone Number</StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">User Name</StyledTableCell>
                    <StyledTableCell align="left">Assigned Apps</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row?._id}>
                                             
                        <StyledTableCell align="left">{row?.firstName}</StyledTableCell>
                        <StyledTableCell align="left">{row?.lastName}</StyledTableCell>
                        <StyledTableCell align="left">{row?.phoneNumber}</StyledTableCell>
                        <StyledTableCell align="left">{row?.email}</StyledTableCell>
                        <StyledTableCell align="left">{row?.userName}</StyledTableCell>
                        <StyledTableCell align="left">
                            {row.applicationData.length === 1 ?
                              row.applicationData.map((item) => (
                                <div>{item.name.description}</div>
                              )) : ('')}

                            {row.applicationData.length > 1 ?
                              (

                                <Tooltip title={row.applicationData.map((item) => (
                                  <div>{item.name.description}</div>
                                ))} placement="bottom">
                                  <div>
                                    {row.applicationData[0].name.description}...
                                  </div>
                                </Tooltip>
                              ) : ('')}

                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Label
                              variant="ghost"
                              color={(row.isActive === false && 'error') || 'success'}
                            >
                              {row.isActive ? 'ACTIVE' : 'INACTIVE'}
                            </Label>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <UserMoreMenuTwo role={row} onDelete={deleteRole} onEdit={editRole} onRole={assignRole} onApp={assignApplication} handleChangePasswordOpen={handleChangePasswordOpen}/>
                          </StyledTableCell>
                        
                      </StyledTableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
         
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>




        </AppMainPage>
        
        




        {/* <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} /> */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open} fullWidth maxWidth="sm"
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Update User
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <UpdateUserForm role={role} onUpdate={fetchRoles} onClose={handleClose} />
          </DialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={closeNewRole}
          aria-labelledby="customized-dialog-title"
          open={openNew} fullWidth maxWidth="sm"
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={closeNewRole}>
            New User
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <CreateUserForm onUpdate={fetchRoles} onClose={closeNewRole} />
          </DialogContent>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={closeAssignRole}
          aria-labelledby="customized-dialog-title"
          open={openRoles} fullWidth maxWidth="sm"
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={closeAssignRole}>
            Assign Role - {role.firstName} {role.lastName}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <AssignRoleForm role={role} onUpdate={fetchRoles} onClose={closeAssignRole} />
          </DialogContent>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={closeAssignApp}
          aria-labelledby="customized-dialog-title"
          open={openApps} fullWidth maxWidth="sm"
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={closeAssignApp}>
            Assign Application - {role.firstName} {role.lastName}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <AssignAppForm role={role} onUpdate={fetchRoles} onClose={closeAssignApp} />
          </DialogContent>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={handleChangePasswordClose}
          aria-labelledby="customized-dialog-title"
          open={isOpenChangePassword} fullWidth maxWidth="sm"
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleChangePasswordClose}>
            Change Password
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <ChangePasswordModal open={isOpenChangePassword} handleClose={handleChangePasswordClose} userRole={userRole}/>
          </DialogContent>
        </BootstrapDialog>

      </Container>
    </Page>
  );
}
