import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { setUserSession } from '../../../utils/Auth';

// ----------------------------------------------------------------------

export default function OtpForm() {
  const navigate = useNavigate();
  const [passwordError, setpasswordError] = useState('');
  const LoginSchema = Yup.object().shape({
    otp: Yup.string().required('OTP is required')
  });

  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { otp: values.otp };
      console.log(user, 'user data to send');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(user)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === '200') {
            if (data.data) {
              setUserSession(
                data.data.userData.email,
                data.data.userData.firstName,
                data.data.userData.lastName,
                data.data.userData.phoneNumber,
                data.data.AutherisationToken
              );
              navigate('/dashboard', { replace: true });
            } else {
              setpasswordError(data.msg);
            }
          } else {
            setpasswordError(data.msg);
          }
        });

      // let result = fetch('https://localhost:3000/auth/users/login_test', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Accept: 'application/json'
      //   },
      //   body: JSON.stringify(user)
      // });
      // result = result.json();
      // console.log(result, 'result');
      // localStorage.setItem('user-data', JSON.stringify(result));
      // console.log(values, 'values');
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="otp"
            label="OTP"
            name="otp"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Submit OTP
          </LoadingButton>
        </Stack>
      </Form>
      <br />
      {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
    </FormikProvider>
  );
}
