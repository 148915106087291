import * as Yup from 'yup';
import React,{ useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';
import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableCell,
  tableCellClasses,
  TableRow,
 
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { ReactMultiEmail, isEmail } from "react-multi-email";
import 'react-multi-email/dist/style.css';

import { LoadingButton } from '@material-ui/lab';

const styles = {
  fontFamily: "sans-serif",
  width: "500px",
  // border: "1px solid #eee",
  background: "transparent",
  // padding: "25px",
  margin: "20px",
  emailInput: {
    width: '400px', // Change width as needed
    padding: '8px', // Adjust padding as needed
    fontSize: '16px' // Adjust font size as needed
  }
};



// ----------------------------------------------------------------------
const CreateEmailForm = ({onUpdate, onClose}) => {

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [allRoles, setallRoles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);

  // const getLabel = (email, index, removeEmail) => (
    //  <div data-tag key={index}>
  //    <div data-tag-item>{email}</div>
 //     <span data-tag-handle onClick={() => removeEmail(index)}>
  //      ×
  //    </span>
 //   </div>
 // );
  
  const handleEmailsChange = (newEmails) => {
    setEmails(newEmails);
  };

 


  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Display Name is required'),    
    status: Yup.string().required('Status is required'),
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    e.preventDefault();
  };



  useEffect(() => {  
    fetchRoles();
    // fetchClient();
  }, []);

  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log(data.data);
          setallRoles(data.data);
        }
      });
  };


  const formik = useFormik({
    initialValues: {
      application:  "" ,
      fromMailId: "" ,
      ccMailId:  "" ,
      password: "",
      host: "" ,
      postNo:  "" ,
      defaultTo:  "",
      status: ""  ,
      url: "",
      userName: "",
      token: "",
      type: "",
    },
  //  validationSchema: LoginSchema,
    onSubmit: () => {
      const user = {
        application:  values.application ,
      fromMailId: values.fromMailId,
      ccMailId:  emails,
      password: values.password,
      host: values.host,
      postNo:  values.postNo,
      defaultTo:  values.defaultTo,
      status: values.status,
      url: values.url,
      userName: values.userName,
      token: values.token,
      type: values.type,
        };
      console.log(user, 'user ')
      
      
const encuser = {
  encryptedData: CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString(),
};

console.log(encuser);

// Decrypting the data
const bytes = CryptoJS.AES.decrypt(encuser.encryptedData, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
console.log(decryptedData);
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/emailNotification/createEmailNotification`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              onUpdate()
              onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>

              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> 

              <Grid item xs={2} sm={2} md={6}>
                      <div>
                       <span style={{ display: 'block',marginBottom: 'none',color: '#71797E'}}>User Name</span>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type="text"
                  // label="Form Mail Id"
                  name="userName"
                  {...getFieldProps('userName')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                </div>
                </Grid>


              <Grid item xs={2} sm={2} md={6}>
              <FormControl fullWidth sx={{ minWidth: 120}} size="small">
                   <span style={{ display: 'block',marginBottom: 'none',color: '#71797E'}}>Application</span>
                   {/* <InputLabel id="demo-simple-select-helper-label">Application</InputLabel> */}
                      <Select
                        fullWidth                     
                        label="Application"
                        sx={{ borderRadius: '10px'}}
                        inputProps={{
                          name: 'application',
                          id: 'filled-status-native-simple'
                        }}
                        {...getFieldProps('application')}
                        error={Boolean(touched.application && errors.application)}
                        helperText={touched.application && errors.application}
                      >
                        <MenuItem key="" value="" />
                        {allRoles.map((item) => (
                          <MenuItem key={item.name.description} value={item.name.description}>
                            {item.name.description}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
              </Grid>
              <Grid item xs={2} sm={2} md={6}>
                      <div>
                       <span style={{ display: 'block',marginBottom: 'none',color: '#71797E'}}>Type</span>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type="text"
                  // label="Form Mail Id"
                  name="type"
                  {...getFieldProps('type')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                </div>
                </Grid>
              <Grid item xs={2} sm={2} md={6}>
                      <div>
                       <span style={{ display: 'block',marginBottom: 'none',color: '#71797E'}}>From</span>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type="text"
                  // label="Form Mail Id"
                  name="fromMailId"
                  {...getFieldProps('fromMailId')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                </div>
                </Grid>
             
               <Grid item xs={2} sm={2} md={6}>              
                  <span style={{ display: 'block', marginBottom: 'none',color: '#71797E'}}>CC</span>
                <ReactMultiEmail
                  // placeholder="Input your email"
                  emails={emails}
                  onChange={handleEmailsChange}
                  // autoFocus
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  getLabel={(email, index, removeEmail) => (
                    <div
                      key={index}
                      role="button"
                      tabIndex={0}
                      onClick={() => removeEmail(index)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                          removeEmail(index);
                        }
                      }}
                      style={{ display: 'inline-flex', alignItems: 'center',
                      backgroundColor: focused ? 'lightgrey' : 'white', // Change color based on focus
                      padding: '5px', // Add padding for better visibility
                      borderRadius: '5px', // Add rounded corners
                      margin: '3px', // Add margin between emails
                        
                    }}
                      aria-label="Remove Email"
                    >
                      <div style={{ marginRight: '5px' }}>{email}</div>
                      <span
                        onClick={() => removeEmail(index)}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === ' ') {
                            removeEmail(index);
                          }
                        }}
                        role="button"
                        tabIndex={0}
                        aria-label="Remove"
                        style={{ cursor: 'pointer' }}
                      >
                        ×
                      </span>
                    </div>
  )}
                />
               
             
              </Grid>

              <Grid item xs={2} sm={2} md={6}>
                      <div>
                       <span style={{ display: 'block',marginBottom: 'none',color: '#71797E'}}>Token</span>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type="text"
                  // label="Form Mail Id"
                  name="token"
                  {...getFieldProps('token')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                </div>
                </Grid>

              <Grid item xs={2} sm={2} md={6}>
                      <div>
                       <span style={{ display: 'block',marginBottom: 'none',color: '#71797E'}}>Url</span>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type="text"
                  // label="Form Mail Id"
                  name="url"
                  {...getFieldProps('url')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                </div>
                </Grid>

              <Grid item xs={2} sm={2} md={6}>             
                  <span style={{ display: 'block', marginBottom: 'none',color: '#71797E'}}>Password</span>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type={showPassword ? 'text' : 'password'}
                  // label="Password"
                  name="password"
                  onCut={handleChange}
                  onCopy={handleChange}
                  onPaste={handleChange}
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
            
             </Grid>

              <Grid item xs={2} sm={2} md={6}>
                  <div>
                  <span style={{ display: 'block', marginBottom: 'none',color: '#71797E'}}>Host</span>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type="text"
                  // label="Host"
                  name="host"
                  {...getFieldProps('host')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
                 </div>
                 </Grid>
                 <Grid item xs={2} sm={2} md={6}>   
                   <div>
                  <span style={{ display: 'block', marginBottom: 'none',color: '#71797E'}}>Post No.</span> 
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type="text"
                  // label="Post No"
                  name="postNo"
                  {...getFieldProps('postNo')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
                </div>
                </Grid>
              
                <Grid item xs={2} sm={2} md={6}>
                  <div>
                  <span style={{ display: 'block', marginBottom: 'none',color: '#71797E'}}>Default To</span>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type="text"
                  // label="Default To Mail Id"
                  name="defaultTo"
                  {...getFieldProps('defaultTo')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
                </div>
                </Grid>

               <Grid item xs={2} sm={2} md={6}> 
              <FormControl sx={{ minWidth: 560 ,borderRadius: '15px'}}  size="small">
               
                  <span style={{ display: 'block', marginBottom: 'none',color: '#71797E'}}>Status</span>
             {/* <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>  */}  
                <Select
                  native
                 
                  // label="Status"
                  inputProps={{
                    name: 'status',
                    id: 'filled-status-native-simple'
                  }}
                  {...getFieldProps('status')}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                >
                  <option aria-label="None" value="" />
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                </Select>
                </FormControl>
                </Grid>

                </Grid>
                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CreateEmailForm;