import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import * as CryptoJS from "crypto-js";
import Cookies from 'js-cookie'
// material
import {
  Alert,
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { setUserSession } from '../../../utils/Auth';
// ----------------------------------------------------------------------

export default function AppLoginForm() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [verifyOTP, setverifyOTP] = useState(true);
  const [verifysentOTP, setverifysentOTP] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setpasswordError] = useState('');
  
  let onSuccess;
  let response;
  const isCrossOrigin = true;
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      
      const authResult = new URLSearchParams(window.location.search); 

      const client = authResult.get('client_id')
      const redirect = authResult.get('redirect_uri')
      const responses = authResult.get('response_type')

      console.log(client, 'client id')
      console.log(redirect, 'redirect_uri')
      console.log(responses, 'response_type')
      console.log('came gere')
      // return onSuccess;
      // window.open(redirect);
      window.addEventListener("beforeunload", (ev) => 
      {  
          ev.preventDefault();
          return onSuccess = 'success';
      });

      // window.addEventListener("message", function (event) {
      //   if (event.data.message === "requestResult") {
      //     event.source.postMessage({"message": "deliverResult", result: {...} }, "*");
      //   }
      // });
      // const encuser = {
      //   "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      // }
 
    }
  });




  const attachEvent = () => {
      window.addEventListener("message", handlePostMessage);
      handlePostMessage('message')
  }

  const handlePostMessage = (event) => {
    console.log(event, 'event')
    event.source.postMessage({"message": "deliverResult" });
  };

  const close = () => {
    window.addEventListener('message', handlePostMessage);
  };

  const handleSendOTP = () => {
    const otp = { email: values.email, otp: values.otp };
    const encuser ={
      "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(otp), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
    }
    console.log(otp, 'otp value');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/consumeLogInOtpEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'after login data');
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          console.log(data)
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data=decryptedData;
          if (data.data) {
            setUserSession(
              data.data.userData.email,
              data.data.userData.firstName,
              data.data.userData.lastName,
              data.data.userData.phoneNumber,
              data.data.AutherisationToken,
              data.data.userData._id
            );
            navigate('/dashboard', { replace: true });
          } else {
            setpasswordError(data.msg);
          }
        } else {
          setpasswordError(data.msg);
        }
      });
  };

  const testFunction = () => {
    console.log("Welcome");
    Cookies.set('testCookie', 'this is my first cookie')
    console.log(Cookies.get('testCookie'))

}

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <FormikProvider value={formik}>
      {verifysentOTP ? (
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="otp"
            label="OTP"
            name="otp"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSendOTP}
          >
            Submit OTP
          </LoadingButton>
        </Stack>
      ) : (
        ''
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {verifyOTP ? (
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="off"
              type="email"
              label="Email address"
              name="email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="off"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              name="password"
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>
        ) : (
          ''
        )}
        {verifyOTP ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
            />

            <Link component={RouterLink} variant="subtitle2" to="/forgetpassword">
              Forgot password?
            </Link>
          </Stack>
        ) : (
          ''
        )}
        {verifyOTP ? (
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Login
          </LoadingButton>
        ) : (
          ''
        )}
      </Form>
      <br />
      {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
      {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
    </FormikProvider>
  );
}
