import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Alert,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem
} from '@material-ui/core';

import { LoadingButton } from '@material-ui/lab';
import Select from '@material-ui/core/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

// ----------------------------------------------------------------------
const CreateUserForm = ({ onUpdate, onClose }) => {

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const LoginSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    userName: Yup.string().required('Username is required'),
    password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    )
  });
  const [showPassword, setShowPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      userName: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      // const user = {
      //                 users : 
      //                 [
      //                     {
      //                         name: values.firstName +  ' '.repeat(1) + values.lastName,
      //                         email: values.email,
      //                         firstName: values.firstName,
      //                         lastName: values.lastName,
      //                         password: values.password,
      //                         phoneNumber: values.phoneNumber
      //                     }
      //                 ]
      //               }
      const user = {
        "name": values.firstName +  ' '.repeat(1) + values.lastName,
        "email": values.email,
        "firstName": values.firstName,
        "lastName": values.lastName,
        "phoneNumber": values.phoneNumber,
        "userName": values.userName,
        "password" : values.password
        }
      console.log(user, 'user details')
      const encuser = {
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      // old api, api/users/bulkInsert
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/createUserWithoutAppClientAndRole`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(user)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.firstName = '';
            values.lastName = '';
            values.email = '';
            values.phoneNumber = '';
            values.userName = '';
            values.password = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              onUpdate()
              onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="First Name"
                  name="firstName"
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Last Name"
                  name="lastName"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Email"
                  name="email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Phone Number"
                  name="phoneNumber"
                  {...getFieldProps('phoneNumber')}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Username"
                  name="userName"
                  {...getFieldProps('userName')}
                  error={Boolean(touched.userName && errors.userName)}
                  helperText={touched.userName && errors.userName}
                />



                <TextField
                  fullWidth
                  autoComplete="off"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  name="password"
                  onCut={handleChange}
                  onCopy={handleChange}
                  onPaste={handleChange}
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="filled-status-native-simple">Status</InputLabel>
                <Select
                  native
                  label="Status"
                  inputProps={{
                    name: 'isActive',
                    id: 'filled-isActive-native-simple'
                  }}
                  {...getFieldProps('isActive')}
                  error={Boolean(touched.isActive && errors.isActive)}
                  helperText={touched.isActive && errors.isActive}
                >
                  <option aria-label="None" value="" />
                  <option value="true">ACTIVE</option>
                  <option value="false">INACTIVE</option>
                </Select>
              </FormControl> */}



                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CreateUserForm;