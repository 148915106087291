import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Box, Stack, AppBar, Toolbar, IconButton } from '@material-ui/core';
// components
//

import './footer.css';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;



const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));



export default function Footer({ onOpenSidebar }) {
  return (
    <Card className="footer" align="center">
      © 2021 Europ Assistance. All Rights Reserved.
    </Card>
  );
}
