import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import Divider from '@mui/material/Divider';
// ----------------------------------------------------------------------

const UserMoreMenuRole = ({role, onDelete, onEdit, onPermission}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  
  const handleDelete = (id) => {
    setIsOpen(false);
    onDelete(id);      
  };

  const handleEdit = (id) => {    
    setIsOpen(false);
    onEdit(id);
  }

  const handleAssignPermission = (id) => {    
    setIsOpen(false);
    onPermission(id);
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleAssignPermission(role)}>
          <ListItemIcon>
            <Icon icon="mdi:account-check" width="25" className="big-icon" />
          </ListItemIcon>
          <ListItemText primary="Assign Permission" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <Divider />
        
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleEdit(role)}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24}/>
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleDelete(role._id)}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        
        
      </Menu>
    </>
  );
};

export default UserMoreMenuRole;
