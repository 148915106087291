import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from 'crypto-js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import { Alert, Stack, TextField } from '@material-ui/core';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------
const UpdateClanForm = ({ role, onUpdate, onClose }) => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [allClients, setallClients] = useState([]);
  const LoginSchema = Yup.object().shape({
    clientId: Yup.string().required('Client Id is required'),
    name: Yup.string().required('Name is required'),
    count: Yup.string().required('Count is required'),
    type: Yup.string().required('Type is required')
  });

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          setallClients(data.data);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      clientId: role.clientData._id,
      name: role.name,
      count: role.count,
      type: role.type
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = {
        clainsId: role._id,
        clientId: values.clientId,
        name: values.name,
        count: values.count,
        type: values.type,
        createdBy: localStorage.getItem('_id')
      };
      console.log(user, 'user ');
      const encuser = {
        encryptedData: CryptoJS.AES.encrypt(
          JSON.stringify(user),
          '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
        ).toString()
      };
      console.log(encuser);
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/client/updateClains`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              onUpdate();
              onClose();
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Name"
                  name="name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Count"
                  name="count"
                  {...getFieldProps('count')}
                  error={Boolean(touched.count && errors.count)}
                  helperText={touched.count && errors.count}
                />

                

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                  <Select
                    native
                    label="Type"
                    inputProps={{
                      name: 'type',
                      id: 'filled-type-native-simple'
                    }}
                    {...getFieldProps('type')}
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                  >
                    <option aria-label="None" value="" />
                    <option value="INTERNAL">INTERNAL</option>
                    <option value="EXTERNAL">EXTERNAL</option>
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Cliend Id</InputLabel>
                  <Select
                    native
                    label="Cliend Id"
                    inputProps={{
                      name: 'clientId',
                      id: 'filled-status-native-simple'
                    }}
                    {...getFieldProps('clientId')}
                    error={Boolean(touched.clientId && errors.clientId)}
                    helperText={touched.clientId && errors.clientId}
                  >
                    <option key="" value="" />
                    {allClients.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.actualName}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UpdateClanForm;
