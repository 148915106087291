import * as React from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { LoadingButton } from '@material-ui/lab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
// ----------------------------------------------------------------------
const AssignPemissionForm = ({role, onUpdate, onClose}) => {

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [allRoles, setallRoles] = useState([]);
  const [assignedPermissions, setassignedPermissions] = useState([]);
  const [dense, setDense] = React.useState(false);

  const LoginSchema = Yup.object().shape({
    role: Yup.string().required('Status is required'),
  });

  useEffect(() => {   
    setassignedPermissions([])
    if(role.attachedPermission) {
      console.log('app are there')
      console.log(role.attachedPermission.length)
      setassignedPermissions(role.attachedPermission)
    }
    fetchRoles()
  }, [])

  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/listAllPermission`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;          
          console.log(data.data, 'roles data')
          setallRoles(data.data);
        }
      });
  }

  const formik = useFormik({
    initialValues: {
      role: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { roleId: role._id, permissionId: values.role };
      console.log(user, 'request body')
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      console.log(user, 'data to send')
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/attachPermissionsToRole`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              onUpdate()
              onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const detachApp = (id) => {
    console.log(id, 'assign app id');
    const user = { roleId: role._id, permissionId: id };
    const encuser = {
      encryptedData: CryptoJS.AES.encrypt(
        JSON.stringify(user),
        '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
      ).toString()
    };
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/detachPermissionsToRole`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {          
          setSuccessMessage(data.msg);          
          setTimeout(() => {
            setSuccessMessage('');
            setassignedPermissions(assignedPermissions.filter((assignedPermission) => assignedPermission._id !== id))
          }, 1000);
        } else {
          setpasswordError(data.msg);
        }
      });
  };

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12} md={12}>
            <List dense={dense}>
              {assignedPermissions.map((item) => (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <Icon
                        icon="ic:baseline-delete"
                        className="big-icon"
                        onClick={() => detachApp(item._id)}
                      />
                    </IconButton>
                  }
                >
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>              
                <Select
                  native
                  label="Role"
                  inputProps={{
                    name: 'role',
                    id: 'filled-status-native-simple'
                  }}
                  {...getFieldProps('role')}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                >
                  <option key="" value="" />
                {
                    allRoles.map((item) => (
                      <option key={item._id} value={item._id}>{item.name}</option>
                    ))
                }                  
                </Select>
                
                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AssignPemissionForm;