import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


// material
import {
  Alert,
  Box,
  Checkbox,
  ListItem,
  ListItemText,
  Stack
} from '@material-ui/core';

import {
    Card,
    Button,
    Dialog,
    DialogContent,
    DialogTitle, 
    IconButton,
    InputAdornment,
    styled,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
  } from '@mui/material';

// import Select from '@material-ui/core/Select';
import { LoadingButton } from '@material-ui/lab';
import Typography from '@mui/material/Typography';
import { element } from 'prop-types';
// ----------------------------------------------------------------------
const CreateApproval = ({  onUpdate, onClose }) => {

  const navigate = useNavigate();

  const [age, setAge] = React.useState('');

  const [allApps, setAllApps] = useState([]);

  useEffect(() => {
    fetchApps();
    getAppPageData();
    // fetchRolesMaster();
    fetchClient();
  }, []);

  
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [projectName, setProjectName] = React.useState([]);
  const [searchProject, setSearchProject] = useState('');
  const [page, setPage] = React.useState(0);
  const [rolesOfClient, setRolesOfClient] = useState([]);
  const [index, setIndex] = useState(0);
  const [appPages, setAppPages] = useState([]);
  const [pages, setPages] = useState([]);
  const [client, setClient] = useState([]);
  const [clientName, setClientName ] = useState([]);
  const [text, setText] = useState("");
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [text4, setText4] = useState("");
  const [text5, setText5] = useState("");
  const [text6, setText6] = useState("");
  const [text7, setText7] = useState("");
  const formik = useFormik({
    initialValues: {
        pageId: '',
        roleId: '',
        clientId:'',
        projectId:'',
      projectName: '',
      pageName: '',
      clientName: '',
      roleName: ''
    },
    
    onSubmit: () => {
        console.log("submit");
      const user = { pageId: values.pageId,roleId: values.roleId,clientId: values.clientId,projectId: values.projectId,
                     projectName: values.projectName, pageName: values.pageName, clientName: values.clientName, roleName: values.roleName
        };
        console.log(user,'aproval data');
        const encuser = {
            encryptedData: CryptoJS.AES.encrypt(
              JSON.stringify(user),
              '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
            ).toString()
          };
          const token = localStorage.getItem('token');
          fetch(`${process.env.REACT_APP_BASEURL}/api/application/createDocumentApprovalMaster`, {
            method: 'POST',
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            body: JSON.stringify(encuser)
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.code === 200) {
                // setAssignedApps([...assignedApps, data])
                // fetchUser()
                console.log(data,'sucessful');
                values.pageId= '';
                values.roleId= '';
                values.clientId='';
                values.projectId='';
                values.projectName= '';
                values.pageName= '';
                values.clientName='';
                values.roleName= '';
                setTimeout(() => {
                    setSuccessMessage('');
                    // navigate('/otp', { replace: true });
                    onUpdate()
                    onClose()
                  }, 1000);
              }
            })
    }
   
  });

  const customStyles = {
    control: base => ({
      ...base,
      height: 35,
      minHeight: 35
    })
  };

  const fetchApps = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          setAllApps(data.data);
          console.log(data.data, 'get all application data');
          setProjectName(data.data);
        }
      });
  };


  const handleChangeClient = (e) => {

    formik.values.clientId = e.value
   

    console.log('selected client id' , e.value);

    const indexClient =  client.findIndex(obj => obj._id === e.value);

    formik.values.clientName = client[indexClient].displayName
    setClientName(client[indexClient].displayName);
    const user = { applicationId: projectName[index]._id , clientId: e.value};
    console.log(user);
    const encuser = {
      "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
    }
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/roleBasedOnAapplication`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;  
          console.log(data.data, 'app roles');
          const newArray = data.data.map((item) => item);
          setRolesOfClient(newArray);
          console.log(rolesOfClient);
          
        }
      });

  };

  const getAppPageData = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/page/getAllPage`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          setAppPages(decryptedData);
          console.log('All Pages', decryptedData);
        }
      })
      .catch((err) => console.log('app page data error', err));
  };


  const fetchClient = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          console.log(data.data, 'client data')       
          setClient(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }
  
  

  const handleChange = (e) => {
    
    formik.values.projectName = e.value
    setSearchProject(e.value);
    console.log(e.value);
    setAge(e.value);
    // const newpages = appPages.filter(
      // (item) => item?.applicationName === e.value
    // )
    // setPages(newpages);
    const indexs =  projectName.findIndex(obj => obj.name.description === e.value);
    formik.values.projectId = projectName[indexs]._id
    setIndex(indexs);
    console.log(projectName[indexs]._id);
    
  };
  
  const handlePage = (e) => {
    console.log(e);
    formik.values.pageId = e._id
    // const indexPage =  pages.findIndex(obj => obj._id === e);
    formik.values.pageName = e.page;
  }

  const handleChangerole = (e) => {
    console.log(e.value);
    formik.values.roleId = e.value
    const indexRole =  rolesOfClient.findIndex(obj => obj._id === e.value);
    formik.values.roleName = rolesOfClient[indexRole].role;

    const details = {
            roleName: rolesOfClient[indexRole].role,
            applicationName: projectName[index].name.description,
            clientName
          }
          console.log(details);
          const token = localStorage.getItem('token');
          const url = decodeURI(
            `${process.env.REACT_APP_BASEURL}/api/application/pagesOfRole?roleName=${rolesOfClient[indexRole].role}&applicationName=${projectName[index].name.description}&clientName=${clientName}`
          );
          fetch(url, {
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.code === 200) {
                console.log('decrypted assined data', data);
                const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));               
                console.log('assined pages', decryptedData);
                const newPages = decryptedData.map((item) => item);
                setPages(newPages);
              }
            })
            .catch((err) => console.log('err', err));

            setText4('User Role');
            setText5('Action Of Role');
            setText6('Range');
            setText7('Sequence');
    
            setText(`${rolesOfClient[indexRole].role}` );
            setText1(`${rolesOfClient[indexRole].actionOfRole}`);
            setText2(`${rolesOfClient[indexRole].rangeFrom} to ${rolesOfClient[indexRole].rangeTo}`);
            setText3(`${rolesOfClient[indexRole].sequence}` );
          }

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                
               <FormControl sx={{height: '10%'}}>
               <InputLabel id="demo-select-small" sx={{textAlign: 'center'}}>Project Name</InputLabel>
               <Select
               labelId="demo-select-small"
               label="Project Name"
               onChange={(e) => handleChange(e.target)} 
               sx={{height: '50px'}}
               styles={customStyles}
               inputProps={{
                name: 'projectName',
                id: 'filled-application-native-simple'
              }}
              error={Boolean(touched.role && errors.role)}
              helperText={touched.role && errors.role}
               >
               <MenuItem value="">Select Project</MenuItem>
                    {projectName.map((item) => (
                      <MenuItem  value={item?.name.description}>
                        {item?.name.description}
                      </MenuItem>
                      ))}
               </Select>
               </FormControl>

               <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="client_name">Client</InputLabel>
              <Select
              labelId="client_name"
              label="Client Name"
              onChange={(e) => handleChangeClient(e.target)}
              sx={{height: '50px'}}
              name='clientName'
              inputProps={{
                name: 'cilentName',
                id: 'filled-application-native-simple'
              }}
              error={Boolean(touched.role && errors.role)}
              helperText={touched.role && errors.role}
              >
              <MenuItem value="">Select Client</MenuItem>
                    {client.map((item) => (
                      <MenuItem key={item?._id} value={item?._id}>
                        {item?.displayName}
                      </MenuItem>
                    ))}              
                </Select>
                </FormControl>
                
               <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label">User Role</InputLabel>
                  <Select
                    labelId='user_role'
                    label="User Role"   
                    onChange={(e) => handleChangerole(e.target)}               
                    sx={{height: '50px'}}
                    name='roleName'
                   
                    inputProps={{
                        name: 'roleName',
                        id: 'filled-application-native-simple'
                      }}
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                 >
            
                  <MenuItem value="" sx={{paddingLeft: '30px'}}>Select Role</MenuItem>
                    {rolesOfClient.map((item) => (
                      <MenuItem key={item?._id} value={item?._id} sx={{paddingLeft: '30px'}}>
                        {item.role}               
                      </MenuItem>
                      
                    ))} 
                     {rolesOfClient.map((item) => (
                    <MenuItem disabled> 
                    <ListItem sx={{color: 'red' ,fontWeight: 'medium'}}>{item.role}</ListItem>
                    <ListItem sx={{color: 'red' ,fontWeight: 'medium'}}>{item.actionOfRole}</ListItem>
                    <ListItem sx={{color: 'red' ,fontWeight: 'medium',paddingLeft: '70px'}}>{item.rangeFrom}  to  {item.rangeTo}</ListItem>
                    </MenuItem> 
                    ))}  
            </Select>                     
               </FormControl>

               <FormControl sx={{ m: 1, minWidth: 120 }}>
               <InputLabel id="page_name">Page Name</InputLabel>
               <Select
                labelId="page_name"
                label="Page Name"
                name='pageName'   
                // onChange={(e) =>handlePage(e.target)}            
                sx={{height: '50px'}}
                inputProps={{
                    name: 'pageName',
                    id: 'filled-application-native-simple'
                  }}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
               >
                <MenuItem value="">Select Pages</MenuItem>
                    {pages[0]?.pageData.map((item) => (
                      <MenuItem key={item?._id} value={item?._id} onClick={() => handlePage(item)}>
                        {item?.page}  
                      </MenuItem>
                    ))}
               </Select>
               </FormControl>
              
               
               <Grid container> 
               <Grid item xs={4} md={3} sx={{textAlign: 'left',color: '',fontSize: '14px'}}>
              <div>{text4}</div>
              </Grid>             
              <Grid item xs={4} md={3} sx={{textAlign: 'left',color: '#144ca0',fontSize: '14px'}}>
              <div>{text}</div>
              </Grid>
              <Grid item xs={4} md={3} sx={{textAlign: 'left',color: '',fontSize: '14px'}}>
              <div>{text6}</div>
              </Grid>
              <Grid item xs={4} md={3} sx={{textAlign: 'left',color: '#144ca0',fontSize: '14px'}}>
              <div>{text2}</div>
              </Grid>  
              
              <Grid item xs={4} md={3} mt={1} sx={{textAlign: 'left',color: '',fontSize: '14px'}}>
              <div>{text5}</div>
              </Grid>
              <Grid item xs={4} md={3} mt={1} sx={{textAlign: 'left',color: '#144ca0',fontSize: '14px'}}>
              <div>{text1}</div>
              </Grid>
              <Grid item xs={4} md={3} mt={1} sx={{textAlign: 'left',color: '',fontSize: '14px'}}>
              <div>{text7}</div>
              </Grid>
              <Grid item xs={4} md={3} mt={1} sx={{textAlign: 'left',color: '#144ca0',fontSize: '14px'}}>
              <div>{text3}</div>
              </Grid>
              </Grid>

             <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CreateApproval;