import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from 'crypto-js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import { Alert, Stack, TextField } from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------
const CreatePermissionForm = ({ onUpdate, onClose }) => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const LoginSchema = Yup.object().shape({
    role: Yup.string().required('Role is required'),
    status: Yup.string().required('Status is required'),
    desc: Yup.string().required('Description is required')
  });

  const formik = useFormik({
    initialValues: {
      role: '',
      status: '',
      desc: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { name: values.role, status: values.status, description: values.desc };
      console.log(user, 'permission details');
      const encuser = {
        encryptedData: CryptoJS.AES.encrypt(
          JSON.stringify(user),
          '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
        ).toString()
      };
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/createPermission`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              onUpdate();
              onClose();
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Permission"
                  name="role"
                  {...getFieldProps('role')}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Description"
                  name="desc"
                  {...getFieldProps('desc')}
                  error={Boolean(touched.desc && errors.desc)}
                  helperText={touched.desc && errors.desc}
                />
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="filled-status-native-simple">Status</InputLabel>
                  <Select
                    native
                    label="Status"
                    inputProps={{
                      name: 'status',
                      id: 'filled-status-native-simple'
                    }}
                    {...getFieldProps('status')}
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                  >
                    <option aria-label="None" value="" />
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </Select>
                </FormControl>

                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CreatePermissionForm;
