import * as React from 'react';
import {
  Modal,
  Button,
  Paper,
  TextField,
  Typography,
  styled,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Alert,
} from '@mui/material';
import * as CryptoJS from 'crypto-js';
import { Form, FormikProvider, useFormik } from 'formik';
import './styles.css';

const MuiDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '15px'
}));

const ButtonAlignment = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '25px'
}));

function AddNewPageModal({ handleClose, open, onUpdate }) {
  const [application, setApplication] = React.useState([]);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    fetchApplication();
  }, []);

  const fetchApplication = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log('App page Application res', data);
          setApplication(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  };

  const formik = useFormik({
    initialValues: {
      page: '',
      applicationId: '',
      applicationName: '',
      description: '',
      status: ''
    },
    onSubmit: () => {
      const token = localStorage.getItem('token');
      const bodyData = {
        page: values?.page,
        description: values?.description,
        applicationId: values?.applicationId._id,
        applicationName: values?.applicationId.name.description,
        status: values?.status
      };

      const encData = {
        encryptedData: CryptoJS.AES.encrypt(
          JSON.stringify(bodyData),
          '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
        ).toString()
      };
      fetch(`${process.env.REACT_APP_BASEURL}/api/page/createPage`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encData)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.page = '';
            values.applicationId = '';
            values.applicationName = '';
            values.description = '';
            values.status = '';
            onUpdate();
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              handleClose();
            }, 1000);
          } else {
            setError(data.msg);
          }
          console.log('successfull', data?.msg);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  });

  const { values, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10%' }}
      >
        <Paper sx={{ margin: '5%' }}>
          <Typography sx={{ padding: '5%' }}>Add New Page</Typography>
          <hr className="divider" />
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <div style={{ padding: '25px' }}>
                <MuiDiv>
                  <Typography sx={{ marginRight: '50px' }}>Application</Typography>
                  <FormControl sx={{ width: '224px' }} size="small">
                    <InputLabel id="demo-select-small">Application</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="Application"
                      {...getFieldProps('applicationId')}
                    >
                      {application.map((item) => (
                        <MenuItem key={item?._id} value={item}>
                          {item?.name?.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MuiDiv>
                {/* <MuiDiv>
                <Typography sx={{ marginRight: '50px' }}>Document</Typography>
                <TextField placeholder="Document" width="25px" size="small" />
              </MuiDiv> */}
                <MuiDiv>
                  <Typography sx={{ marginRight: '50px' }}>Page</Typography>
                  <TextField
                    placeholder="Page"
                    {...getFieldProps('page')}
                    width="25px"
                    size="small"
                  />
                </MuiDiv>
                <MuiDiv>
                  <Typography sx={{ marginRight: '50px' }}>Description</Typography>
                  <TextField
                    placeholder="Description"
                    {...getFieldProps('description')}
                    width="25px"
                    size="small"
                  />
                </MuiDiv>
                <MuiDiv>
                  <Typography select sx={{ marginRight: '50px' }}>
                    Status
                  </Typography>
                  <FormControl sx={{ width: '224px' }} size="small">
                    <InputLabel id="demo-select-small">Status</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="Status"
                      {...getFieldProps('status')}
                    >
                      <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                      <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                    </Select>
                  </FormControl>
                </MuiDiv>
              </div>

              <hr className="divider" />
              <ButtonAlignment>
                <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={handleClose}>
                  Close
                </Button>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </ButtonAlignment>
            </Form>
            <br />
            {error ? <Alert severity="success">{error}</Alert> : ''}
            {successMessage ? <Alert sx={{marginLeft: "5px", marginBottom: "5px"}} severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Modal>
    </>
  );
}

export default AddNewPageModal;
