import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------
const UpdatePOCForm = ({role, onUpdate, onClose}) => {

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [allClients, setallClients] = useState([]);
  const LoginSchema = Yup.object().shape({
    pocName: Yup.string().required('Name is required'),
    pocAlternateName: Yup.string().required('Alternate Name is required'),
    pocEmail: Yup.string().email('Email must be a valid email address').required('Email is required'),
    pocAlternateEmail: Yup.string().email('Alternate must be a valid email address').required('Alternate is required'),
    pocContact: Yup.string()
      .min(10, 'Too Short!')
      .max(10, 'Too Long!')
      .required('Contact Number'),
      pocAlternateContact: Yup.string()
      .min(10, 'Too Short!')
      .max(10, 'Too Long!')
      .required('Contact Number')
  });

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log(data.data, 'roles data');
          setallClients(data.data);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      pocName: role.pocName,
      pocAlternateName: role.pocAlternateName,
      pocEmail: role.pocEmail,
      pocAlternateEmail: role.pocAlternateEmail,
      pocContact: role.pocContact,
      pocAlternateContact: role.pocAlternateContact,
      city: role.city,
      state: role.state,
      country: role.country,
      zip: role.zip,
      clientId: role.clientId,
      updatedBy: localStorage.getItem('_id')
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { 
        pocId: role._id, 
        pocName: values.pocName, 
        pocAlternateName: values.pocAlternateName, 
        pocEmail: values.pocEmail, 
        pocAlternateEmail: values.pocAlternateEmail, 
        pocContact: values.pocContact, 
        pocAlternateContact: values.pocAlternateContact, 
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
        clientId: values.clientId,
        updatedBy: localStorage.getItem('_id') 
      };
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/client/updatePoc`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              onUpdate()
              onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Name"
                  name="pocName"
                  {...getFieldProps('pocName')}
                  error={Boolean(touched.pocName && errors.pocName)}
                  helperText={touched.pocName && errors.pocName}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Alternate Name"
                  name="pocAlternateName"
                  {...getFieldProps('pocAlternateName')}
                  error={Boolean(touched.pocAlternateName && errors.pocAlternateName)}
                  helperText={touched.pocAlternateName && errors.pocAlternateName}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="email"
                  label="Email address"
                  name="pocEmail"
                  {...getFieldProps('pocEmail')}
                  error={Boolean(touched.pocEmail && errors.pocEmail)}
                  helperText={touched.pocEmail && errors.pocEmail}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="email"
                  label="Email address"
                  name="pocAlternateEmail"
                  {...getFieldProps('pocAlternateEmail')}
                  error={Boolean(touched.pocAlternateEmail && errors.pocAlternateEmail)}
                  helperText={touched.pocAlternateEmail && errors.pocAlternateEmail}
                />

              <TextField
                fullWidth
                autoComplete="off"
                type="number"
                label="Contact Number"
                {...getFieldProps('pocContact')}
                error={Boolean(touched.pocContact && errors.pocContact)}
                helperText={touched.pocContact && errors.pocContact}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="number"
                label="Alternate Contact Number"
                {...getFieldProps('pocAlternateContact')}
                error={Boolean(touched.pocAlternateContact && errors.pocAlternateContact)}
                helperText={touched.pocAlternateContact && errors.pocAlternateContact}
              />        

                          <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="City"
                {...getFieldProps('city')}
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="State"
                {...getFieldProps('state')}
                error={Boolean(touched.state && errors.state)}
                helperText={touched.state && errors.state}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="Country"
                {...getFieldProps('country')}
                error={Boolean(touched.country && errors.country)}
                helperText={touched.country && errors.country}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="Zip"
                {...getFieldProps('zip')}
                error={Boolean(touched.zip && errors.zip)}
                helperText={touched.zip && errors.zip}
              />

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Cliend Id</InputLabel>
                  <Select
                    native
                    label="Cliend Id"
                    inputProps={{
                      name: 'clientId',
                      id: 'filled-status-native-simple'
                    }}
                    {...getFieldProps('clientId')}
                    error={Boolean(touched.clientId && errors.clientId)}
                    helperText={touched.clientId && errors.clientId}
                  >
                    <option key="" value="" />
                    {allClients.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.actualName}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UpdatePOCForm;