import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import * as CryptoJS from "crypto-js";
import { Alert, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { setUserSession } from '../../../utils/Auth';

// ----------------------------------------------------------------------

export default function VerifyFormCustomer() {
  const navigate = useNavigate();
  const [passwordError, setpasswordError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);

  const [selectedApp, setSelectedApp] = useState('');
  const [selectedClient, setSelectedClient] = useState('');

  const [invlidOTP, setinvlidOTP] = useState(false);

  let ClientName = '';
  let appName = '';


  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    otp: Yup.string().required()
  });

  useEffect(async () => {
    const authResult = new URLSearchParams(window.location.search);
    appName = authResult.get('Application')
    ClientName = authResult.get('Client')
    console.log(appName, 'Test')
    console.log(ClientName, 'client')
    const application = await getApplication();
    setSelectedApp(application);
    const client = await getClient();
    setSelectedClient(client);

    console.log(application, 'applications')
    console.log(client, 'clients')

  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      otp: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { email: values.email, otp: values.otp };
      const encuser = {
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      console.log(user, 'user data to send');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/consumeRequestEmailOtp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            console.log(data, 'after verification data is required')

            const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            data.data = decryptedData;
            console.log(data.data, 'data ')
            const userIds = data.data.id















            const user = { userId: userIds, applicationId: selectedApp._id, clientId: selectedClient._id, role: "User" };
            console.log(user, 'user data')

            const encuser = {
              encryptedData: CryptoJS.AES.encrypt(
                JSON.stringify(user),
                '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
              ).toString()
            };
            const token = localStorage.getItem('token');
            fetch(`${process.env.REACT_APP_BASEURL}/api/users/addUserApplicationClinetRole`, {
              method: 'POST',
              headers: {
                Authorization: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
              },
              body: JSON.stringify(encuser)
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.code === 200) {
                  setSuccessMessage(data.msg);
                  setTimeout(() => {
                    setSuccessMessage('');
                  }, 1000);
                  console.log(appName, 'why app name will not work')
                  console.log(ClientName, 'why app name will not work')

                  const paramsResult = new URLSearchParams(window.location.search);
                  const paramsApp = paramsResult.get('Application')
                  const paramsClient = paramsResult.get('Client')


                  const commonPortalUser = {
                    "userId": userIds,
                    "appName": paramsApp,
                    "ClientName": paramsClient,
                    "ClientID" : paramsClient
                  }
                  console.log(commonPortalUser, "common portal data")
                  fetch(`https://cpuatapi.europ-assistance.in/api/sso/createUserApplicationClinetRole`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Accept: 'application/json'
                    },
                    body: JSON.stringify(commonPortalUser)
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      console.log(data, 'assigned in common portal also');
                    });
                } else {
                  setpasswordError(data.msg);
                }
              });
































            setSuccessMessage(true);
            setTimeout(() => {
              setSuccessMessage(false);
              navigate('/login', { replace: true });
            }, 3000);
          } else {
            setinvlidOTP(true);
          }
        });
    }
  });



  async function getApplication() {
    console.log(appName, 'app name')
    const pageStatus = fetch(`${process.env.REACT_APP_BASEURL}/api/application/getApplicationDetails?name=${appName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          return data.data[0]
        }
      });
    return pageStatus
  }

  const getClient = async () => {
    console.log(ClientName, 'clientName')
    const Clients = fetch(`${process.env.REACT_APP_BASEURL}/api/client/getClientdetails?name=${ClientName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          return data.data[0]
        }
      });
    return Clients
  }

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="off"
            type="email"
            label="Email address"
            name="email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="off"
            type="text"
            label="OTP"
            name="otp"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Submit
          </LoadingButton>
          {successMessage ? (
            <Alert severity="success">
              Reset Password link was sent to your email seccussfully
            </Alert>
          ) : (
            ''
          )}
          {invlidOTP ? <Alert severity="success">Invalid OTP</Alert> : ''}
        </Stack>
      </Form>

      <div align="center">{passwordError}</div>
    </FormikProvider>
  );
}
