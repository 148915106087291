import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import * as CryptoJS from "crypto-js";
import { Alert, Stack, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { setUserSession } from '../../../utils/Auth';

// ----------------------------------------------------------------------

export default function RegisterAssignForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  
  const [selectedApp, setSelectedApp] = useState('');
  const [selectedClient, setSelectedClient] = useState('');

  let ClientName = '';
  let appName = '';


  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    contactNumber: Yup.string()
      .min(10, 'Too Short!')
      .max(10, 'Too Long!')
      .required('Contact Number'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
  });

  useEffect(async () => {
    const authResult = new URLSearchParams(window.location.search);
    appName = authResult.get('Application')
    ClientName = authResult.get('Client')
    console.log(appName, 'Test')
    console.log(ClientName, 'client')
    const application = await getApplication();
    setSelectedApp(application);
    const client = await getClient();
    setSelectedClient(client);

    console.log(application, 'applications')
    console.log(client, 'clients')

  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit:  () => {
      
      const user = {
        password: values.password,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.contactNumber
      };
      console.log(user, 'values values values ');
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/signUp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, 'data after registration')
          if(data.code === 400) {
            console.log("User Already Registered")
            setAlertMessage(data.msg)
          }

            const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            data.data = decryptedData;
            console.log(data.data, 'data ')
            const userIds = data.data._id


















          const user = { userId: userIds, applicationId: selectedApp._id, clientId: selectedClient._id, role: "User" };
            console.log(user, 'user data')

            const encuser = {
              encryptedData: CryptoJS.AES.encrypt(
                JSON.stringify(user),
                '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
              ).toString()
            };
            const token = localStorage.getItem('token');
            fetch(`${process.env.REACT_APP_BASEURL}/api/users/addUserApplicationClinetRole`, {
              method: 'POST',
              headers: {
                Authorization: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
              },
              body: JSON.stringify(encuser)
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.code === 200) {
                  setSuccessMessage(data.msg);
                  setTimeout(() => {
                    setSuccessMessage('');
                  }, 1000);
                  console.log(appName, 'why app name will not work')
                  console.log(ClientName, 'why app name will not work')

                  const paramsResult = new URLSearchParams(window.location.search);
                  const paramsApp = paramsResult.get('Application')
                  const paramsClient = paramsResult.get('Client')


                  const commonPortalUser = {
                    "userId": userIds,
                    "appName": paramsApp,
                    "ClientName": paramsClient,
                    "ClientID" : paramsClient
                  }
                  console.log(commonPortalUser, "common portal data")
                  fetch(`https://cpuatapi.europ-assistance.in/api/sso/createUserApplicationClinetRole`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Accept: 'application/json'
                    },
                    body: JSON.stringify(commonPortalUser)
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      console.log(data, 'assigned in common portal also');
                    });
                }                
              });













          setSuccessMessage(true);
          setTimeout(() => {
            setSuccessMessage(false);
            navigate('/verifyemail', { replace: true });
          }, 3000);
        })
        .catch(console.log);

      // navigate('/dashboard', { replace: true });
    }
  });

  async function getApplication() {
    console.log(appName, 'app name')
    const pageStatus = fetch(`${process.env.REACT_APP_BASEURL}/api/application/getApplicationDetails?name=${appName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          return data.data[0]
        }
      });
    return pageStatus
  }

  const getClient = async () => {
    console.log(ClientName, 'clientName')
    const Clients = fetch(`${process.env.REACT_APP_BASEURL}/api/client/getClientdetails?name=${ClientName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          return data.data[0]
        }
      });
    return Clients
  }

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleChange = (e) => {
    e.preventDefault();
  };

  // const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="off"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="off"
            type="number"
            label="Contact Number"
            {...getFieldProps('contactNumber')}
            error={Boolean(touched.contactNumber && errors.contactNumber)}
            helperText={touched.contactNumber && errors.contactNumber}
          />

          <TextField
            fullWidth
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Register
          </LoadingButton>
          {successMessage ? <Alert severity="success">Registered Successfully</Alert> : ''}
          {alertMessage ? <Alert severity="success">{alertMessage}</Alert> : ''}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
