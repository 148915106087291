import * as React from 'react';
import {
  Button,
  TextField,
  Typography,
  styled,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from '@mui/material';
import * as CryptoJS from 'crypto-js';
import { Form, FormikProvider, useFormik } from 'formik';
import './styles.css';

const MuiDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '22px'
}));

function UpdateAppPage({ pageData, handleClose, onUpdate }) {
  const [application, setApplication] = React.useState([]);

  React.useEffect(() => {
    fetchApplication();
  }, []);

  const fetchApplication = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log('App page Application res', data);
          setApplication(data.data);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      pageId: pageData?._id,
      page: pageData?.page,
      applicationName: pageData?.applicationName,
      status: pageData?.status
    },
    onSubmit: () => {
      const token = localStorage.getItem('token');

      const bodyData = {
        pageId: pageData._id,
        page: values?.page,
        applicationName: values?.applicationId?.name?.description,
        status: values?.status
      };

      const encData = {
        encryptedData: CryptoJS.AES.encrypt(
          JSON.stringify(bodyData),
          '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
        ).toString()
      };
      fetch(`${process.env.REACT_APP_BASEURL}/api/page/updatePage`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encData)
      })
        .then((res) => res.json())
        .then((data) => {
          handleClose();
          onUpdate();
          console.log('successfull', data?.msg);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  });

  const { values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form style={{ margin: '15px' }} autoComplete="off" noValidate onSubmit={handleSubmit}>
        <MuiDiv>
          <Typography sx={{ marginRight: '50px' }}>Application</Typography>
          <FormControl sx={{ width: '224px' }} size="small">
            <InputLabel id="demo-select-small">Application</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Application"
              {...getFieldProps('applicationId')}
            >
              {application.map((item) => (
                <MenuItem key={item?._id} value={item}>
                  {item?.name?.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MuiDiv>

        <MuiDiv>
          <Typography sx={{ marginRight: '50px' }}>Page</Typography>
          <TextField placeholder="Page" {...getFieldProps('page')} width="25px" size="small" />
        </MuiDiv>
        <MuiDiv>
          <Typography select sx={{ marginRight: '50px' }}>
            Status
          </Typography>
          <FormControl sx={{ width: '224px' }} size="small">
            <InputLabel id="demo-select-small">Status</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Status"
              {...getFieldProps('status')}
            >
              <MenuItem value="ACTIVE">ACTIVE</MenuItem>
              <MenuItem value="INACTIVE">INACTIVE</MenuItem>
            </Select>
          </FormControl>
        </MuiDiv>
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ margin: '15px 0px' }}
        >
          Save
        </Button>
      </Form>
    </FormikProvider>
  );
}

export default UpdateAppPage;
