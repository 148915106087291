import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import * as CryptoJS from "crypto-js";
import { Alert, Stack, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';


// ----------------------------------------------------------------------

export default function ChangeForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConNewPassword, setShowConNewPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const LoginSchema = Yup.object().shape({
    currentpassword: Yup.string().required('Current Password is required'),
    newpassword: Yup.string()
    .required('New Password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
    confirmpassword: Yup.string()
      .required('Confirm New Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
      .oneOf([Yup.ref('newpassword'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      currentpassword: '',
      newpassword: '',
      confirmpassword: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { oldPassword: values.currentpassword, newPassword: values.newpassword };
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/changePassword`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'data')
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          setTimeout(() => {
            setSuccessMessage('');
            navigate('/login', { replace: true });
          }, 3000);

          // if (data.data) {
          //   setUserSession(
          //     data.data.userData.email,
          //     data.data.userData.firstName,
          //     data.data.userData.lastName,
          //     data.data.userData.phoneNumber,
          //     data.data.AutherisationToken
          //   );
          //   navigate('/dashboard', { replace: true });
          // } else {
          //   setpasswordError(data.msg);
          // }
        } else {
          setSuccessMessage(data.msg);
        }
      });

        // .then((res) => res.json())
        // .then((data) => {
        //   setSuccessMessage(data.msg);
        //   setTimeout(() => {
        //     setSuccessMessage(data.msg);
        //     navigate('/login', { replace: true });
        //   }, 3000);
        // })
        // .catch(console.log);
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleShowConNewPassword = () => {
    setShowConNewPassword((show) => !show);
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            label="Current Password"
            name="currentpassword"
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            {...getFieldProps('currentpassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.currentpassword && errors.currentpassword)}
            helperText={touched.currentpassword && errors.currentpassword}
          />

          <TextField
            fullWidth
            autoComplete="off"
            type={showNewPassword ? 'text' : 'password'}
            label="New Password"
            name="newpassword"
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            {...getFieldProps('newpassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowNewPassword} edge="end">
                    <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.newpassword && errors.newpassword)}
            helperText={touched.newpassword && errors.newpassword}
          />

          <TextField
            fullWidth
            autoComplete="off"
            type={showConNewPassword ? 'text' : 'password'}
            label="Confirm New Password"
            name="confirmpassword"
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            {...getFieldProps('confirmpassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowConNewPassword} edge="end">
                    <Icon icon={showConNewPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmpassword && errors.confirmpassword)}
            helperText={touched.confirmpassword && errors.confirmpassword}
          />
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Change Password
          </LoadingButton>
          {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
