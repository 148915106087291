import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from 'crypto-js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import { Alert, Stack, TextField, Checkbox } from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { LoadingButton } from '@material-ui/lab';

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

import Autocomplete from '@mui/material/Autocomplete';


// import DeleteIcon from '@mui/icons-material/Delete';
// ----------------------------------------------------------------------
const AssignAppForm = ({ role, onUpdate, onClose }) => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [allRoles, setallRoles] = useState([]);

  const [allAppRoles, setallAppRoles] = useState([]);
  const [allClients, setallClients] = useState([]);

  const [assignedApps, setAssignedApps] = useState([]);
  const [dense, setDense] = React.useState(false);

  const [selectedApp, setSelectedApp] = useState('');
  const [selectedAppId, setSelectedAppId] = useState('');
  const [selectedClient, setSelectedClient] = useState('');

  const LoginSchema = Yup.object().shape({
    role: Yup.string().required('Status is required')
  });

  useEffect(() => {
    // if (role.attechedApplications) {
    //   setAssignedApps(role.attechedApplications)
    // }
    // fetchUser();
    fetchRoles();
    // fetchAppRoles();
    fetchClients();
    fetchAssignedApps();
  }, []);

  const fetchAssignedApps = async () => {
    console.log('came to retrieve apps')
    const token = localStorage.getItem('token');
    const userid = role._id;
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/assignedApplicationWithClient?userId=${userid}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;          
          console.log(data.data, 'user wise apps')
          setAssignedApps(data.data)
        }
      });
  }

  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          setallRoles(data.data);
        }
      });
  };

  const fetchUser = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/listAllActiveUser?userId=${role._id}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log(data.data[0].applicationData, 'old assigned apps')
          // setAssignedApps(data.data[0].applicationData);
        }
      });
  };

  const fetchAppRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getApplicationRoleMaste`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;  
          setallAppRoles(data.data);
        }
      });
  }

  const fetchClients = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          setallClients(data.data);
        }
      });
  }

  const formik = useFormik({
    initialValues: {
      role: '',
      approle: '',
      client: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      // const user = { userId: role._id, applicationId: values.role };
      const user = { userId: role._id, applicationId: values.role, clientId: values.client, role: values.approle};
      console.log(user, 'user data')

      const encuser = {
        encryptedData: CryptoJS.AES.encrypt(
          JSON.stringify(user),
          '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
        ).toString()
      };
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/addUserApplicationClinetRole`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            // setAssignedApps([...assignedApps, data])
            // fetchUser()
            fetchAssignedApps();
            values.role = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
            }, 1000);
            const commonPortalUser = {
              "userId": role.cPortalId,
              "appName": selectedApp,
              "ClientName": selectedClient,
              "ClientID" : selectedClient
            }
            console.log(commonPortalUser, 'common portal data')
            fetch(`https://cpuatapi.europ-assistance.in/api/sso/createUserApplicationClinetRole`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
              },
              body: JSON.stringify(commonPortalUser)
            })
              .then((res) => res.json())
              .then((data) => {
                console.log(data, 'assigned in common portal also');
              });


          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const detachApp = (id) => {
    const user = { userId: role._id, applicationId: id };
    const encuser = {
      encryptedData: CryptoJS.AES.encrypt(
        JSON.stringify(user),
        '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
      ).toString()
    };
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/detachApplicationsFromUser`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          role.roleAttached = null;
          setAssignedApps(assignedApps.filter((assignedApp) => assignedApp._id !== id))
          setSuccessMessage(data.msg);
          fetchUser()
          setTimeout(() => {
            setSuccessMessage('');
          }, 1000);
        } else {
          setpasswordError(data.msg);
        }
      });
  };

  const detachAppNew = (id) => {
    const user = { 
      userId : role._id,
      applicationId : id.applicationData._id,
      clientId : id.clientdata._id
     };
     console.log(user, 'user Data')
    const encuser = {
      encryptedData: CryptoJS.AES.encrypt(
        JSON.stringify(user),
        '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
      ).toString()
    };
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/detachClient`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          role.roleAttached = null;
          // setAssignedApps(assignedApps.filter((assignedApp) => assignedApp._id !== id))
          setSuccessMessage(data.msg);
          // fetchUser()
          fetchAssignedApps();
          setTimeout(() => {
            setSuccessMessage('');
          }, 1000);
        } else {
          setpasswordError(data.msg);
        }
      });
  };

  const handleChange = (e) => {
    setallAppRoles([]);
    console.log(e, 'app roles fetch')
    formik.values.role = allRoles[e.target.value]._id
    setSelectedApp(allRoles[e.target.value].name.description)    
    const token = localStorage.getItem('token');
    const user = { applicationId: allRoles[e.target.value]._id };
    setSelectedAppId(allRoles[e.target.value]._id)
    // console.log(user, 'users')
    // const encuser = {
    //   "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
    // }
    // fetch(`${process.env.REACT_APP_BASEURL}/api/users/roleBasedOnAapplication`, {
    //   method: 'POST',
    //   headers: {
    //     Authorization: token,
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json'
    //   },
    //   body: JSON.stringify(encuser)
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (data.code === 200) {
    //       const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
    //       const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //       data.data = decryptedData;  
    //       console.log(data.data, 'app roles')
    //       setallAppRoles(data.data);
    //     }
    //   });

  }

  const getRolesByAppClient = (client) => {    
    setallAppRoles([])
    const token = localStorage.getItem('token');
    const user = { applicationId: selectedAppId, clientId: client };
    console.log(user, 'user user ')
    const encuser = {
      "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
    }
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/roleBasedOnAapplication`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;  
          console.log(data.data, 'app roles')
          setallAppRoles(data.data);
        }
      });

  }

  const handleChangeClient = (e) => {
    console.log(allAppRoles, 'allAppRoles')
    formik.values.client = allClients[e.target.value]._id
    console.log(allClients[e.target.value]._id, 'client id')
    console.log(allClients[e.target.value].displayName, 'client ndame')
    setSelectedClient(allClients[e.target.value].displayName)
    getRolesByAppClient(allClients[e.target.value]._id)
  }

  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper
  }));



  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12} md={12}>
            <List dense={dense}>
              {assignedApps.map((item) => (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <Icon
                        icon="ic:baseline-delete"
                        className="big-icon"
                        onClick={() => detachAppNew(item)}
                      />
                    </IconButton>
                  }
                >
                  <Grid container spacing={3}>
                    <Grid item xs={4}>{item.applicationData.name.description}</Grid>
                    <Grid item xs={4}>{item.clientdata.displayName}</Grid>
                    <Grid item xs={4}>{item.role}</Grid>
                  </Grid>
                  
                  
                  
                {/* <ListItemText primary={item.applicationData.name.description} />
                <ListItemText primary={item.clientdata.displayName} />
                <ListItemText primary={item.role} /> */}
                </ListItem>
              ))}
            </List>
          </Grid>

          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <br />
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Application</InputLabel>
                  <Select
                    native
                    label="Application"
                    onChange={handleChange}
                    inputProps={{
                      name: 'role',
                      id: 'filled-status-native-simple'
                    }}
                    
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && errors.role}
                  >
                    <option key="" value="" />
                    {allRoles.map((option, index) => (
                      <option key={option._id} value={index}>
                        {option.name.description}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Client</InputLabel>
                  <Select
                    native
                    label="Client"
                    onChange={handleChangeClient}
                    inputProps={{
                      name: 'client',
                      id: 'filled-status-native-simple'
                    }}                    
                    error={Boolean(touched.client && errors.client)}
                    helperText={touched.client && errors.client}
                  >
                    <option key="" value="" />
                    {allClients.map((option, index) => (
                      <option key={option._id} value={index}>
                        {option.displayName}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
                  <Select
                    native
                    label="Role"
                    inputProps={{
                      name: 'approle',
                      id: 'filled-status-native-simple'
                    }}
                    {...getFieldProps('approle')}
                    error={Boolean(touched.approle && errors.approle)}
                    helperText={touched.approle && errors.approle}
                  >
                    <option key="" value="" />
                    {allAppRoles.map((item) => (
                      <option key={item.role} value={item.role}>
                        {item.role}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                {/* <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={allRoles}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name.description}
                  name='assignApps'
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name.description}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Checkboxes" placeholder="Favorites" />
                  )}
                /> */}

                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
};


export default AssignAppForm;
