import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import {
  Alert,
  
  Stack,
  TextField,
} from '@material-ui/core';
import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableCell,
  tableCellClasses,
  TableRow 
} from '@mui/material';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------
const UpdateSmsForm = ({role, onUpdate, onClose}) => {

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [allRoles, setallRoles] = useState([]);
  const [client, setClient] = useState([]);
   

  const LoginSchema = Yup.object().shape({
    providerName: Yup.string().required('Provider Name is required'),
    url: Yup.string().required('Url is required'),
    application: Yup.string().required('Application is required'),
    clientName: Yup.string().required('Client Name is required'),
    token: Yup.string().required('Token is required'),
    defaultNo: Yup.string().required('Default No. is required'),
    gatewayId: Yup.string().required('Gateway Id is required'),
    nameOfTheGateway: Yup.string().required('Name Of Gateway is required'),
    apiCodeValue: Yup.string().required('Api Code Value is required'),
    userNameValue: Yup.string().required('User Name Value is required'),
    passwordValue: Yup.string().required('Password Value is required'),
    senderIdValue: Yup.string().required('Sender Id Value is required'),
    countryCodeAttribute: Yup.string().required('Country Code Attribute is required'),
    apiCodeAttribute: Yup.string().required('Api Code Attribute is required'),
    messageAttribute: Yup.string().required('Message Attribute is required'),
    userNameAttribute: Yup.string().required('User Name Attribute is required'),
    passwordAttribute: Yup.string().required('Password Attribute is required'),
    senderIdAttribute: Yup.string().required('Sender Id Attribute is required'),
    mobileNoAttribute: Yup.string().required('Mobile No Attribute is required'),
    countryCodeValue: Yup.string().required('Country Code Value is required'),
    status: Yup.string().required('Status is required'),

  });

  
  useEffect(() => {  
    fetchRoles();
    fetchClient();
  }, []);



  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log(data.data);
          setallRoles(data.data);
        }
      });
  };


  const fetchClient = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          console.log(data.data, 'client data')       
          setClient(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }




  const formik = useFormik({
    initialValues: {
      providerName: role.providerName,
      application: role.application,
      gatewayId: role.gatewayId,
      clientName: role.Client,
      messageAttribute: role.messageAttribute,
      userNameValue: role.userNameValue,
      passwordValue: role.passwordValue,
      senderIdValue: role.senderIdValue,
      countryCodeValue: role.countryCodeValue,
      apiCodeAttribute: role.apiCodeAttribute,
      url: role.url,
      token: role.token,
      status: role.status,
      nameOfTheGateway: role.nameOfTheGateway,
      userNameAttribute: role.userNameAttribute,
      passwordAttribute: role.passwordAttribute,
      senderIdAttribute: role.senderIdAttribute,
      countryCodeAttribute: role.countryCodeAttribute,
      mobileNoAttribute: role.mobileNoAttribute,
      apiCodeValue: role.apiCodeAttribute,
      defaultNo: role.defaultNo,
      entityId: role.entityId
    },
   // validationSchema: LoginSchema,
    onSubmit: () => {

      const user = { 
        providerName: values.providerName,
        application: values.application,
        gatewayId: values.gatewayId,
        messageAttribute: values.messageAttribute,
        clientName: values.clientName,
        userNameValue: values.userNameValue,
        passwordValue: values.passwordValue,
        senderIdValue: values.senderIdValue,
        countryCodeValue: values.countryCodeValue,
        apiCodeAttribute: values.apiCodeAttribute,
        url: values.url,
        token: values.token,
        status: values.status,
        nameOfTheGateway: values.nameOfTheGateway,
        userNameAttribute: values.userNameAttribute,
        passwordAttribute: values.passwordAttribute,
        senderIdAttribute: values.senderIdAttribute,
        countryCodeAttribute: values.countryCodeAttribute,
        mobileNoAttribute: values.mobileNoAttribute,
        apiCodeValue: values.apiCodeValue,
        defaultNo: values.defaultNo,
        notificationId: role._id,
        entityId: role.entityId
      
      }


        console.log(user, 'user ')
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/notificationConfiguration/update`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              onUpdate()
              onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
              
             
                
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> 
                  <Grid item xs={2} sm={2} md={4}>
                    <TextField  
                      fullWidth        
                      size="small"
                      autoComplete="off"
                      type="text"
                      label="Provider Name"
                      sx={{ marginBottom: '5px', marginTop: '25px' }}
                      name="providerName"
                      {...getFieldProps('providerName')}
                      error={Boolean(touched.providerName && errors.providerName)}
                      helperText={touched.providerName && errors.providerName}
                    />
                  </Grid>
                 
                 
                    <Grid item xs={2} sm={2} md={4}>
                      <FormControl fullWidth sx={{ minWidth: 120 ,marginBottom: '5px', marginTop: '25px'}} size="small">
                    <InputLabel id="demo-simple-select-helper-label">Application</InputLabel>
                      <Select
                        fullWidth
                      
                        label="Application"
                        sx={{ borderRadius: '10px'}}
                        inputProps={{
                          name: 'application',
                          id: 'filled-status-native-simple'
                        }}
                        {...getFieldProps('application')}
                        error={Boolean(touched.application && errors.application)}
                        helperText={touched.application && errors.application}
                      >
                        <MenuItem key="" value="" />
                         <MenuItem  value="All" >All</MenuItem>
                        {allRoles.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.name.description}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={2} md={4}>
                      <FormControl fullWidth sx={{ minWidth: 120 ,marginBottom: '5px', marginTop: '25px'}} size="small">
                  <InputLabel id="demo-simple-select-helper-label">Client</InputLabel>
                  <Select
                    label="Client"
                    fullWidth
                    inputProps={{
                      name: 'clientName',
                      id: 'filled-application-native-simple'
                    }}
                    sx={{ borderRadius: '10px'}}
                    {...getFieldProps('clientName')}
                    error={Boolean(touched.clientName && errors.applicationclientName)}
                    helperText={touched.clientName && errors.clientName}
                  >
                    <MenuItem key="" value="" />
                    <MenuItem  value="All" >All</MenuItem>
                    {client.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Grid>
                   
              </Grid>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>       
                      <Grid item xs={2} sm={2} md={8}>
                <  TextField
                fullWidth
                   size='small'
                  autoComplete="off"
                  type="text"
                  label="Token"
                  name="token"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  {...getFieldProps('token')}
                  error={Boolean(touched.token && errors.token)}
                  helperText={touched.token && errors.token}
                />
                      </Grid>  
                      <Grid item xs={2} sm={2} md={4}>
                    <TextField
                    fullWidth 
                      size="small"
                      autoComplete="off"
                      type="text"
                      label="Url"
                      sx={{ marginBottom: '5px', marginTop: '25px' }}
                      name="url"
                      {...getFieldProps('url')}
                      error={Boolean(touched.url && errors.url)}
                      helperText={touched.url && errors.url}
                    />
                    </Grid>

                    
              </Grid>
              
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                 
             
              <Grid item xs={2} sm={2} md={4}>
                <TextField 
                fullWidth         
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Gateway Id"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="gatewayId"
                  {...getFieldProps('gatewayId')}
                  error={Boolean(touched.gatewayId && errors.gatewayId)}
                  helperText={touched.gatewayId && errors.gatewayId}
                />
              </Grid>

              <Grid item xs={2} sm={2} md={4}>
                <TextField 
                fullWidth         
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Name Of Gateway"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="nameOfTheGateway"
                  {...getFieldProps('nameOfTheGateway')}
                  error={Boolean(touched.nameOfTheGateway && errors.nameOfTheGateway)}
                  helperText={touched.nameOfTheGateway && errors.nameOfTheGateway}
                />
              </Grid>
               
              <Grid item xs={2} sm={2} md={4}>
                <TextField 
                fullWidth         
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="API Code Value"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="apiCodeValue"
                  {...getFieldProps('apiCodeValue')}
                  error={Boolean(touched.apiCodeValue && errors.apiCodeValue)}
                  helperText={touched.apiCodeValue && errors.apiCodeValue}
                />
              </Grid>  
            
                </Grid>

              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> 
                                    
              <Grid item xs={2} sm={2} md={4}>
                <TextField
                fullWidth          
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="User Name Value"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="userNameValue"
                  {...getFieldProps('userNameValue')}
                  error={Boolean(touched.userNameValue && errors.userNameValue)}
                  helperText={touched.userNameValue && errors.userNameValue}
                />
              </Grid>

             
              <Grid item xs={2} sm={2} md={4}>
                <TextField 
                fullWidth         
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Password Value"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="passwordValue"
                  {...getFieldProps('passwordValue')}
                  error={Boolean(touched.passwordValue && errors.passwordValue)}
                  helperText={touched.passwordValue && errors.passwordValue}
                />
              </Grid>
              
              <Grid item xs={2} sm={2} md={4}>
                <TextField  
                fullWidth        
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Sender Id  Value"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="senderIdValue"
                  {...getFieldProps('senderIdValue')}
                  error={Boolean(touched.senderIdValue && errors.senderIdValue)}
                  helperText={touched.senderIdValue && errors.senderIdValue}
                />
              </Grid>

                </Grid>

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> 
               
              <Grid item xs={2} sm={2} md={4}>
                <TextField   
                fullWidth       
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Country Code Attribute"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="countryCodeAttribute"
                  {...getFieldProps('countryCodeAttribute')}
                  error={Boolean(touched.countryCodeAttribute && errors.countryCodeAttribute)}
                  helperText={touched.countryCodeAttribute && errors.countryCodeAttribute}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={4}>
                <TextField   
                fullWidth       
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="API Code Attribute"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="apiCodeAttribute"
                  {...getFieldProps('apiCodeAttribute')}
                  error={Boolean(touched.apiCodeAttribute && errors.apiCodeAttribute)}
                  helperText={touched.apiCodeAttribute && errors.apiCodeAttribute}
                />
              </Grid>

              <Grid item xs={2} sm={2} md={4}>
                <TextField  
                fullWidth        
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Message Attribute"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="messageAttribute"
                  {...getFieldProps('messageAttribute')}
                  error={Boolean(touched.messageAttribute && errors.messageAttribute)}
                  helperText={touched.messageAttribute && errors.messageAttribute}
                />
              </Grid>

               </Grid>
             

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={2} md={4}>
                <TextField   
                fullWidth       
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="User Name Attribute"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="userNameAttribute"
                  {...getFieldProps('userNameAttribute')}
                  error={Boolean(touched.userNameAttribute && errors.userNameAttribute)}
                  helperText={touched.userNameAttribute && errors.userNameAttribute}
                />
              </Grid>
              
              <Grid item xs={2} sm={2} md={4}>
                <TextField
                fullWidth          
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Password Attribute"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="passwordAttribute"
                  {...getFieldProps('passwordAttribute')}
                  error={Boolean(touched.passwordAttribute && errors.passwordAttribute)}
                  helperText={touched.passwordAttribute && errors.passwordAttribute}
                />
              </Grid>

              <Grid item xs={2} sm={2} md={4}>
                <TextField   
                fullWidth       
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Sender Id Attribute"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="senderIdAttribute"
                  {...getFieldProps('senderIdAttribute')}
                  error={Boolean(touched.senderIdAttribute && errors.senderIdAttribute)}
                  helperText={touched.senderIdAttribute && errors.senderIdAttribute}
                />
              </Grid>  
              
              </Grid>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              
              <Grid item xs={2} sm={2} md={4}>
                <TextField   
                fullWidth       
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Mobile No Attribute"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="mobileNoAttribute"
                  {...getFieldProps('mobileNoAttribute')}
                  error={Boolean(touched.mobileNoAttribute && errors.mobileNoAttribute)}
                  helperText={touched.mobileNoAttribute && errors.mobileNoAttribute}
                />
              </Grid>  

              <Grid item xs={2} sm={2} md={4}>
                <TextField 
                fullWidth         
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Country Code Value"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="countryCodeValue"
                  {...getFieldProps('countryCodeValue')}
                  error={Boolean(touched.countryCodeValue && errors.countryCodeValue)}
                  helperText={touched.countryCodeValue && errors.countryCodeValue}
                />
              </Grid>  
              <Grid item xs={2} sm={2} md={4}>

              <FormControl fullWidth sx={{ minWidth: 120,marginBottom: '5px', marginTop: '25px'}} size="small">
              <InputLabel id="demo-simple-select-helper-label" >Status</InputLabel>
              <Select
                fullWidth
                native
                label="Status"
                sx={{ borderRadius: '10px'}}
                inputProps={{
                  name: 'status',
                  id: 'filled-status-native-simple'
                }}
                {...getFieldProps('status')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
              >
                <option aria-label="None" value="" />
                <option value="ACTIVE">ACTIVE</option>
                <option value="INACTIVE">INACTIVE</option>
              </Select>
              </FormControl> 
                </Grid>
             
              </Grid>

              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={2} md={4}>
                <TextField   
                fullWidth       
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Default No."
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="defaultNo"
                  {...getFieldProps('defaultNo')}
                  error={Boolean(touched.defaultNo && errors.defaultNo)}
                  helperText={touched.defaultNo && errors.defaultNo}
                />
              </Grid> 
              <Grid item xs={2} sm={2} md={4}>
                <TextField   
                fullWidth       
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Entity Id"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="entityId"
                  {...getFieldProps('entityId')}
                  error={Boolean(touched.defaultNo && errors.defaultNo)}
                  helperText={touched.defaultNo && errors.defaultNo}
                />
              </Grid>  


              </Grid>
              
              <Button variant="contained" size="large" sx={{ marginTop: '35px' }} fullWidth  type='submit'>
            Submit
          </Button>
            
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UpdateSmsForm;