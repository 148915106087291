import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect ,useMemo} from 'react';
import * as CryptoJS from "crypto-js";
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Paper,
  FormControl,
  InputAdornment,
  TableHead,
  Button,
  Checkbox,
  InputLabel,
  TableRow,
  TableBody,
  Container,
  Select,
  MenuItem,
  TextField,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  styled,
  DialogActions,
  DialogContent,
  DeleteIcon
} from '@material-ui/core';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

// import CloseIcon from '@material-ui/icons/Close';
// import CloseIcon from '@mui/icons-material/Close';
// import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Form, FormikProvider, useFormik } from 'formik';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenuRole, UserMoreMenu } from '../components/_dashboard/user';
import { UpdateClientRoleForm } from '../components/clientroles/updateclientrole'
import { CreateClientRoleForm } from '../components/clientroles/createclientrole'
import { AssignPemissionForm } from '../components/users/assignpemission'
import UserMoreMenuAssignPages from '../components/_dashboard/user/UserMoreMenuAssignPages'
import { AssignPagesToRoleForm } from '../components/clientroles/AssignPagesToRole';
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'role', label: 'Name', alignRight: false },
  { id: 'description', label: 'Application', alignRight: false },
  { id: 'client', label: 'Client', alignRight: false},
];

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.role.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const OBJECT1 = {
  page: 'Search Name...',
  applicationName: 'Search Application Name...',
  status: 'Search Client...'
};

export default function User() {
  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('');
  const [client, setClient] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [successMessage, setSuccessMessage] = useState('');
  const [delErr, setdelErr] = useState('');
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openAssignPage, setOpenAssignPage] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [appPages, setAppPages] = useState([]);
  const [searchField, setSearchField] = React.useState();
  const [searchObj, setSearchObj] = React.useState({ type: '', search: '' });
  const [openPermissions, setopenPermissions] = useState(false);

  const filteredData = useMemo(() => {
    if (!searchObj?.search?.length || !searchObj.type) {
      return roles;
    }
  
    const { type, search } = searchObj;
  
    const filteredObj = roles.filter(item => {
      const fields = type.split('.');
      const fieldValue = fields.reduce((value, field) => {
        if (Object.prototype.hasOwnProperty.call(value, field)) {
          return value[field];
        }
        return '';
      }, item);
  
      if (typeof fieldValue === 'string' && fieldValue.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      return false;
    });
  
    return filteredObj;
  }, [roles, searchObj]);
  
  
  

  useEffect(() => {   
    fetchRoles();
    fetchClient();
    getAppPageData();
  }, [])

  // Fetch Tasks
  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getApplicationRoleMaste`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;  
          console.log(data.data, 'role master data')
          setRoles(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }

  const fetchClient = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          console.log(data.data, 'client data')       
          setClient(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }

  const getAppPageData = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/page/getAllPage`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          setAppPages(decryptedData);
          console.log('All Pages', decryptedData);
        }
      })
      .catch((err) => console.log('app page data error', err));
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roles.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (e) => {
    setSearchField(e.value);
  };

  const pageChangeHandler = (search, type) => {
    setSearchObj({ search, type });
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const deleteRole = async (id) => {
    console.log(id, 'delete id')

      const user = { applicationRoleMasteId: id  };
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      console.log(user, 'user')
      fetch(`${process.env.REACT_APP_BASEURL}/api/application/deleteApplicationRoleMaste`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            setSuccessMessage(data.msg);
            fetchRoles();
            notify()
            setTimeout(() => {
              setSuccessMessage('');
            }, 3000);
          } else {
            setdelErr(data.msg);
          }
        });

  }
  
  const editRole = async (id) => {
    console.log(id, 'edit id')
    setOpen(true)
    setRole(id)
    console.log(role, 'role')
  }

  const useStyles = makeStyles({
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
  });

  const assignPermission = async (id) => {
    console.log('Assign Permission')
    setopenPermissions(true)
    setRole(id)
  }

  const closeAssignPermission = async (id) => {
    fetchRoles()
    setopenPermissions(false)
  }

  const assignPages = async (id) => {
    setOpenAssignPage(true);
    setRole(id);
  }


  const closeAssignPages = (id) => {
    fetchRoles();
    setOpenAssignPage(false)
  }

  const notify = () => toast.success("Role Deleted");
  
  function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Update Role</DialogTitle>
        <UpdateClientRoleForm />
      </Dialog>
    );
  }
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon icon="simple-line-icons:close" className="big-icon" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };


  const openNewRole = () => {
    setOpenNew(true);
  };

  const closeNewRole = (value) => {
    setOpenNew(false);
    setSelectedValue(value);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const filteredUsers = applySortFilter(roles, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

 // const { values, handleSubmit } = formik;

  return (
    <Page title="Application Roles | EAI">
      <Container>
        <ToastContainer />
        <Card className="mainTitles">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} className="mainbuttons">
            <Typography variant="h4" gutterBottom>
              Application Roles
            </Typography>
            <Button
              variant="contained"
              onClick={openNewRole}
              startIcon={<Icon icon={plusFill} />}
            >
              New Application Role
            </Button>          
          </Stack>
        </Card>
        <Card>
        {/*  <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />  */}
          
          <div style={{ display: 'flex', justifyContent: 'row', margin: '20px 15px' }}>
          <FormControl sx={{ width: '200px', marginRight: '20px' }}>
          <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              label="select"
              onChange={(e) => handleChange(e.target)}
            >
              <MenuItem value="role">Name</MenuItem>
              <MenuItem value="applicationData.name.description">Application</MenuItem>
              <MenuItem value="clientData.displayName">CLient</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="search"
            placeholder={OBJECT1[searchField] ? OBJECT1[searchField] : 'Search'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="ic:round-search" width={22} />
                </InputAdornment>
              )
            }}
            onChange={(e) => pageChangeHandler(e.target.value, searchField)}
          />
        </div>
         
        <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Name</StyledTableCell>
                    <StyledTableCell align="left">Application</StyledTableCell>
                    <StyledTableCell align="left">Client</StyledTableCell>
                    <StyledTableCell align="left">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row?._id}>
                        <StyledTableCell align="left">{row?.role}</StyledTableCell>
                        <StyledTableCell align="left">{row?.applicationData.name.description}</StyledTableCell>
                        <StyledTableCell align="left">{row?.clientData.displayName}</StyledTableCell>
                        <StyledTableCell align="left">
                          <UserMoreMenuAssignPages role={row} onDelete={deleteRole} onEdit={editRole} assignPages={assignPages}/>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

       
            <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Card>
        {/* <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} /> */}
        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Update Role
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <UpdateClientRoleForm role={role} onUpdate={fetchRoles} onClose={handleClose}/>
        </DialogContent>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={closeNewRole}
        aria-labelledby="customized-dialog-title"
        open={openNew} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={closeNewRole}>
          New Application Role
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <CreateClientRoleForm client={client} onUpdate={fetchRoles} onClose={closeNewRole}/>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={closeAssignPermission}
        aria-labelledby="customized-dialog-title"
        open={openPermissions} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={closeAssignPermission}>
          Assign Permission - {role.name}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <AssignPemissionForm role={role} onUpdate={fetchRoles} onClose={closeAssignPermission}/>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={closeAssignPages}
        aria-labelledby="customized-dialog-title"
        open={openAssignPage} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={closeAssignPages}>
          Assign Pages to Role
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <AssignPagesToRoleForm role={role} onUpdate={fetchRoles} onClose={closeAssignPages} appPages={appPages} fetchRoles={fetchRoles}/>
        </DialogContent>
      </BootstrapDialog>

      </Container>
    </Page>
  );
}
