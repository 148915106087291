import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------
const UpdateClientForm = ({ role, onUpdate, onClose }) => {
  console.log(role,'in update form');
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [imageUrl, setImageUrl] = useState(`${role.clientLogo}`);
  const [showImage, setShowImage] = useState(true); 
  const [selectedFIle, setSelectedFIle] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (role.clientLogo) {
    const regex = /\/uploads\/(.*)/;
    const match = role.clientLogo.match(regex);
    
    const extractedTextLogo = match ? match[1] : null;
    

        console.log(`/uploads/${extractedTextLogo}`);
        
        const fullImageUrl = new URL(`/uploads/${extractedTextLogo}`, process.env.REACT_APP_BASEURL).toString();
        
        console.log(fullImageUrl);
        
        setImageUrl(fullImageUrl);
        
        console.log(imageUrl, 'client image url');
    } else {
        setImageUrl('');
        console.log(imageUrl, 'client image url');
    }
    
     
    console.log(imageUrl,'client image url');
  }, []);
  const LoginSchema = Yup.object().shape({
    actualName: Yup.string().required('Actual Name is required'),
    displayName: Yup.string().required('Display Name is required'),
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    // address: Yup.string().required('Address Name is required'),
    // officeContact: Yup.string().required('Office Contact Name is required'),
    status: Yup.string().required('Status is required'),
  });

  const onFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    // Check if a file is selected
    if (file) {
      // Check the file size (in bytes)
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 405 * 124; 
      const minSizeInBytes = 350 * 110;

      if (minSizeInBytes <= fileSizeInBytes <= maxSizeInBytes) {   
          // Reset error message if size is within the limit
          setErrorMessage('');
          setShowImage(true);
  
          // Your existing code to set imageUrl
          setSelectedFIle(event.target.files);
      } else {
        setErrorMessage('File size exceeds limit (405 * 124). Choose a similar-sized file within (405 * 124) and  (350 * 110).');
        setShowImage(false);
      }
    }
  };

  const handleCloseImage = () => {
    setShowImage(false);
    setImageUrl('');
   }
   const formdata = new FormData();
  formdata.append("logo",selectedFIle[0]);

  const handleUploadFile = () => {
     console.log(selectedFIle[0]);
    console.log(formdata,'form data');
   fetch(`${process.env.REACT_APP_BASEURL}/api/client/uploadLogoImage`, {
      method: 'POST', 
      body: formdata
    })
   .then((res) => res.json())
    .then((data)=> {
      if(data.code === 200){
        setImageUrl(data?.msg);
        setShowImage(true);
      }
    })
    .catch((err) => {
      alert('please select file');
    })

   
  }

  const formik = useFormik({
    initialValues: {
      actualName: role.actualName,
      displayName: role.displayName,
      email: role.email,
      address: role.address,
      officeContact: role.officeContact,
      uuid: role.uuid,
      status: role.status,
      clientLogo: role.clientLogo,
      schemaNames: role.schemaNames
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { 
        clientId: role._id, 
        actualName: values.actualName, 
        displayName: values.displayName, 
        email: values.email, 
        address: values.address, 
        officeContact: values.officeContact, 
        uuid: values.uuid, 
        status: values.status ,
        clientLogo: imageUrl,
        schemaNames: values.schemaNames,
        isDeleted: false
      };
      console.log(user, 'user')
      const encuser = {
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/client/updateClient`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              onUpdate()
              onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Actual Name"
                  name="actualName"
                  {...getFieldProps('actualName')}
                  error={Boolean(touched.actualName && errors.actualName)}
                  helperText={touched.actualName && errors.actualName}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Display Name"
                  name="displayName"
                  {...getFieldProps('displayName')}
                  error={Boolean(touched.displayName && errors.displayName)}
                  helperText={touched.displayName && errors.displayName}
                />
                
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{display: 'flex', alignItems: 'center'}}>
                <input type="file" onChange={onFileChange} /> 
                <Button variant='contained' sx={{width: '120px'}} onClick={handleUploadFile}>Upload Logo</Button>
                </Stack>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                {
                  imageUrl && showImage ? 
                  <div style={{display: "flex", marginTop: '-5px'}}>
                  <img src={imageUrl} alt="uploadImage" width={85} height={70}/>
                  <Icon icon="akar-icons:cross" onClick={handleCloseImage}/>
                  </div>
                  : ''
                }


                <TextField
                  fullWidth
                  autoComplete="off"
                  type="email"
                  label="Email address"
                  name="email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Address"
                  name="address"
                  {...getFieldProps('address')}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="number"
                  label="Contact Number"
                  name="officeContact"
                  {...getFieldProps('officeContact')}
                  error={Boolean(touched.officeContact && errors.officeContact)}
                  helperText={touched.officeContact && errors.officeContact}
                />
                 <TextField
                type="text"
                multiline
                fullWidth
                autoComplete="off"
                rows={2}
                maxRows={4}
                label="Schema"
                name="schemaNames"
                {...getFieldProps('schemaNames')}
              />
              <p style={{ fontSize: 'smaller', marginTop: '5px' }}> * Please enter schema names, separated by commas.</p>
              
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="UUID"
                  name="uuid"
                  {...getFieldProps('uuid')}
                  error={Boolean(touched.uuid && errors.uuid)}
                  helperText={touched.uuid && errors.uuid}
                />
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                  <Select
                    native
                    label="Status"
                    inputProps={{
                      name: 'status',
                      id: 'filled-status-native-simple'
                    }}
                    {...getFieldProps('status')}
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                  >
                    <option aria-label="None" value="" />
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </Select>
                </FormControl>

                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UpdateClientForm;