import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Alert,
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { setUserSession } from '../../../utils/Auth';

// ----------------------------------------------------------------------

export default function CreateForm() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [verifyOTP, setverifyOTP] = useState(true);
  const [verifysentOTP, setverifysentOTP] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setpasswordError] = useState('');
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = { email: values.email, password: values.password };
      console.log(user, 'user data to send');
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(user)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              setverifysentOTP(true);
              setverifyOTP(false);
              // navigate('/otp', { replace: true });
            }, 3000);

            // if (data.data) {
            //   setUserSession(
            //     data.data.userData.email,
            //     data.data.userData.firstName,
            //     data.data.userData.lastName,
            //     data.data.userData.phoneNumber,
            //     data.data.AutherisationToken
            //   );
            //   navigate('/dashboard', { replace: true });
            // } else {
            //   setpasswordError(data.msg);
            // }
          } else {
            setpasswordError(data.msg);
          }
        });

      // let result = fetch('https://localhost:3000/auth/users/login_test', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Accept: 'application/json'
      //   },
      //   body: JSON.stringify(user)
      // });
      // result = result.json();
      // console.log(result, 'result');
      // localStorage.setItem('user-data', JSON.stringify(result));
      // console.log(values, 'values');
    }
  });

  const handleSendOTP = () => {
    const otp = { email: values.email, otp: values.otp };
    console.log(otp, 'otp value');
    fetch('http://13.127.236.87:3003/api/users/consumeLogInOtpEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(otp)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'after login data');
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          if (data.data) {
            setUserSession(
              data.data.userData.email,
              data.data.userData.firstName,
              data.data.userData.lastName,
              data.data.userData.phoneNumber,
              data.data.AutherisationToken
            );
            navigate('/dashboard', { replace: true });
          } else {
            setpasswordError(data.msg);
          }
        } else {
          setpasswordError(data.msg);
        }
      });
  };

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      {verifysentOTP ? (
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="off"
            type="otp"
            label="OTP"
            name="otp"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSendOTP}
          >
            Submit OTP
          </LoadingButton>
        </Stack>
      ) : (
        ''
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {verifyOTP ? (
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="off"
              type="email"
              label="Email address"
              name="email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="off"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              name="password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>
        ) : (
          ''
        )}
        {verifyOTP ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
            />

            <Link component={RouterLink} variant="subtitle2" to="/forgetpassword">
              Forgot password?
            </Link>
          </Stack>
        ) : (
          ''
        )}
        {verifyOTP ? (
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Login
          </LoadingButton>
        ) : (
          ''
        )}
      </Form>
      <br />
      {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
      {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
    </FormikProvider>
  );
}
