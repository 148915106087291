import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from 'crypto-js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
import { Avatar } from '@mui/material';
// utils

// ----------------------------------------------------------------------
const NewRoleForm = ({ role, desc, logo }) => {
  const navigate = useNavigate();

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const LoginSchema = Yup.object().shape({
    role: Yup.string().required('Role is required'),
    status: Yup.string().required('Status is required'),
    desc: Yup.string().required('Description is required')
  });

  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    color: theme.palette.primary.darker,
    backgroundColor: theme.palette.primary.lighter
  }));

  const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
      theme.palette.primary.dark,
      0.24
    )} 100%)`
  }));

  function truncates(str) {
    console.log(str);
    return str.length > 25 ? str.substring(0, 25) : str;
    // return `${useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString}\u2026`;
  }

  return (
    <RootStyle>
      <IconWrapperStyle>
        {/* <Icon icon={logo} width={24} height={24} /> */}
        <Avatar alt="Application Logo" src={logo} sx={{ width: 63, height: 63 }}/>
      </IconWrapperStyle>
      <Typography variant="h4" sx={{ opacity: 0.72 }}>
        {role}
      </Typography>
      <br />      
      <Tooltip title={desc} placement="bottom">
      <Typography sx={{ opacity: 0.72 }}>
        {truncates(desc)}
        ...
      </Typography>
      </Tooltip>
    </RootStyle>
  );
};

export default NewRoleForm;
