import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import React , { useState, useEffect, useMemo } from 'react';
import * as CryptoJS from "crypto-js";
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  Paper,
  FormControl,
  InputAdornment,
  TableHead,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  TableBody,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  styled,
  DialogActions,
  DialogContent,
  DeleteIcon
} from '@material-ui/core';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import CloseIcon from '@material-ui/icons/Close';
// import CloseIcon from '@mui/icons-material/Close';
// import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { UpdateClientForm } from '../components/clients/updateclient'
import { CreateClientForm } from '../components/clients/createclient'
import { AssignPemissionForm } from '../components/users/assignpemission'
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'actualName', label: 'Actual Name', alignRight: false },
  { id: 'displayName', label: 'Display Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'officeContact', label: 'Office Contact', alignRight: false },  
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.actualName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const OBJECT1 = {
  actualName: 'Search Actual Name..',
  displayName: 'Search Display Name..',
  email: 'Search Email..',
  officeContact: 'Search Office Contact..',
  status: 'Search Status..'
};

export default function User() {
  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [successMessage, setSuccessMessage] = useState('');
  const [delErr, setdelErr] = useState('');
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const [openPermissions, setopenPermissions] = useState(false);

  
  const [searchField, setSearchField] = React.useState();
  const [searchObj, setSearchObj] = React.useState({ type: '', search: '' });

  const filteredData = useMemo(() => {
    if (!searchObj?.search?.length || !searchObj.type) {
      return roles;
    }
  
    const { type, search } = searchObj;
  
    const filteredObj = roles.filter(item => {
      const fields = type.split('.');
      const fieldValue = fields.reduce((value, field) => {
        if (Object.prototype.hasOwnProperty.call(value, field)) {
          return value[field];
        }
        return '';
      }, item);
  
      if (typeof fieldValue === 'string' && fieldValue.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      return false;
    });
  
    return filteredObj;
  }, [roles, searchObj]);

  

  useEffect(() => {   
    fetchRoles()
  }, [])

  // Fetch Tasks
  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          console.log(data.data, 'client data')       
          setRoles(data.data);
        }
      });
  }

  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roles.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChange = (e) => {
    setSearchField(e.value);
  };

  const pageChangeHandler = (search, type) => {
    setSearchObj({ search, type });
  };

  const deleteRole = async (id) => {
    console.log(id, 'delete id')

      const user = { clientId: id  };
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      console.log(user, 'user')
      fetch(`${process.env.REACT_APP_BASEURL}/api/client/deleteClient`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            setSuccessMessage(data.msg);
            fetchRoles();
            notify();
            setTimeout(() => {
              setSuccessMessage('');
            }, 3000);
          } else {
            setdelErr(data.msg);
          }
        });

  }
  
  const editRole = async (id) => {
    console.log(id, 'edit id')
    setOpen(true)
    setRole(id)
    console.log(role, 'role')
  }

  const useStyles = makeStyles({
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
  });

  const assignPermission = async (id) => {
    console.log('Assign Permission')
    setopenPermissions(true)
    setRole(id)
  }

  const closeAssignPermission = async (id) => {
    setopenPermissions(false)
  }
  
  function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Update Role</DialogTitle>
        <UpdateClientForm />
      </Dialog>
    );
  }
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
          <Icon icon="simple-line-icons:close" className="big-icon" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };


  const openNewRole = () => {
    setOpenNew(true);
  };

  const closeNewRole = (value) => {
    setOpenNew(false);
    setSelectedValue(value);
  };

  const notify = () => toast.success("Client Deleted");

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const filteredUsers = applySortFilter(roles, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Clients| EAI">
      <Container>
      <ToastContainer />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Clients
          </Typography>
          <Button
            variant="contained"
            onClick={openNewRole}
            startIcon={<Icon icon={plusFill} />}
          >
            New Client
          </Button>
        </Stack>

        <Card>
        <div style={{ display: 'flex', justifyContent: 'row', margin: '20px 15px' }}>
          <FormControl sx={{ width: '200px', marginRight: '20px' }}>
          <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              label="select"
              onChange={(e) => handleChange(e.target)}
            >
              <MenuItem value="actualName">Actual Name</MenuItem>
              <MenuItem value="displayName">Display Name</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="officeContact">Office Contact</MenuItem>
              <MenuItem value="status">Status</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="search"
            placeholder={OBJECT1[searchField] ? OBJECT1[searchField] : 'Search'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="ic:round-search" width={22} />
                </InputAdornment>
              )
            }}
            onChange={(e) => pageChangeHandler(e.target.value, searchField)}
          />
        </div>
        
        <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Actual Name</StyledTableCell>
                    <StyledTableCell align="left">Display Name</StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">Office Contact</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row?._id}>
                        <StyledTableCell align="left">{row?.actualName}</StyledTableCell>
                        <StyledTableCell align="left">{row?.displayName}</StyledTableCell>
                        <StyledTableCell align="left">{row?.email}</StyledTableCell>
                        <StyledTableCell align="left">{row?.officeContact}</StyledTableCell>
                        <StyledTableCell align="left">{row?.status}</StyledTableCell>
                        <StyledTableCell align="left">
                          <UserMoreMenu role={row} onDelete={deleteRole} onEdit={editRole} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
        
            <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Card>
        {/* <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} /> */}
        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Update Client
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <UpdateClientForm role={role} onUpdate={fetchRoles} onClose={handleClose}/>
        </DialogContent>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={closeNewRole}
        aria-labelledby="customized-dialog-title"
        open={openNew} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={closeNewRole}>
          New Client
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <CreateClientForm onUpdate={fetchRoles} onClose={closeNewRole}/>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={closeAssignPermission}
        aria-labelledby="customized-dialog-title"
        open={openPermissions} fullWidth maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={closeAssignPermission}>
          Assign Permission - {role.name}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <AssignPemissionForm role={role} onUpdate={fetchRoles} onClose={closeAssignPermission}/>
        </DialogContent>
      </BootstrapDialog>

      </Container>
    </Page>
  );
}
