import React, { useEffect, useMemo } from 'react';
import { Icon } from '@iconify/react';
import * as CryptoJS from 'crypto-js';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ToastContainer, toast } from 'react-toastify';
import { Form, FormikProvider, useFormik } from 'formik';
import searchFill from '@iconify/icons-eva/search-fill';
import { UserMoreMenu } from '../components/_dashboard/user';
import AddNewPageModal from './AddNewPageModal';
import './styles.css';
import UpdateAppPage from './UpdateAppPage';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const HeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '15px'
}));

const AppMainPage = styled('div')(({ theme }) => ({
  padding: '10px'
}));

const OBJECT1 = {
  page: 'Search Page Name...',
  applicationName: 'Search Application Name...',
  status: 'Search Status...'
};

export default function AppPage() {
  const [open, setOpen] = React.useState(false);
  const [isUpdatePageOpen, setIsUpdatePageOpen] = React.useState(false);
  const [pageData, setPageData] = React.useState([]);
  const [searchObj, setSearchObj] = React.useState({ type: '', search: '' });
  const [successMessage, setSuccessMessage] = React.useState('');
  const [appPages, setAppPages] = React.useState([]);
  const [searchField, setSearchField] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const filteredData = useMemo(() => {
    if (!searchObj?.search?.length || !searchObj.type) {
      return appPages;
    }
    const filteredObj = appPages.filter((item) => {
      const filteredItems = item?.[searchObj.type]
        ?.toLowerCase()
        .includes(searchObj.search.toLowerCase());
      return filteredItems;
    });
    return filteredObj;
  }, [appPages, searchObj]);

  useEffect(() => {
    getAppPageData();
  }, []);

  const getAppPageData = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/page/getAllPage`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          setAppPages(decryptedData);
          console.log('All Pages', decryptedData);
        }
      })
      .catch((err) => console.log('app page data error', err));
  };

  const formik = useFormik({
    initialValues: {
      pageId: '',
      page: '',
      status: ''
    },
    enableReinitialize: true,
    onSubmit: () => {
      const token = localStorage.getItem('token');
      console.log('App page values', values);
      const bodyData = {
        pageId: '123',
        page: values?.page,
        status: values?.status
      };
      const encData = {
        encryptedData: CryptoJS.AES.encrypt(
          JSON.stringify(bodyData),
          '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
        ).toString()
      };
      fetch(`${process.env.REACT_APP_BASEURL}/api/page/updatePage`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encData)
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('App page data', data);
        })
        .catch((err) => console.log('err', err));
    }
  });

  const notify = () => toast.success('App Page Deleted');

  const deleteAppPage = async (id) => {
    const pageId = { pageId: id };
    console.log(pageId);
    const encuser = {
      encryptedData: CryptoJS.AES.encrypt(
        JSON.stringify(pageId),
        '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
      ).toString()
    };
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/page/deletePage`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          notify();
          getAppPageData();
          setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
        }
      })
      .catch((err) => console.log('err', err));
  };

  const editAppPage = async (data) => {
    setIsUpdatePageOpen(true);
    setPageData(data);
  };

  const handleCloseUpdateAppPage = () => {
    setIsUpdatePageOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const pageChangeHandler = (search, type) => {
    setSearchObj({ search, type });
  };

  const applicationChangeHandler = (search) => {
    setSearchObj({ search, type: 'applicationName' });
  };

  const statusChangeHandler = (search) => {
    setSearchObj({ search, type: 'status' });
  };

  const handleChange = (e) => {
    setSearchField(e.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const { values, handleSubmit } = formik;

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
      padding: theme.spacing(2)
    },
    '& .MuDialogActions-root': {
      padding: theme.spacing(1)
    }
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Icon icon={searchFill} className="big-icon" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <>
      <AppMainPage>
        <ToastContainer />
        <HeadWrapper>
          <h2 style={{ marginLeft: '15px' }}>Pages List</h2>
          <Button sx={{ marginRight: '15px' }} variant="contained" onClick={handleOpen}>
            NEW PAGE
          </Button>
        </HeadWrapper>
        <hr className="divider" />
        <div style={{ display: 'flex', justifyContent: 'row', margin: '20px 15px' }}>
          <FormControl sx={{ width: '200px', marginRight: '20px' }}>
          <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              label="select"
              onChange={(e) => handleChange(e.target)}
            >
              <MenuItem value="page">Page Name</MenuItem>
              <MenuItem value="applicationName">Application</MenuItem>
              <MenuItem value="status">Status</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="search"
            placeholder={OBJECT1[searchField] ? OBJECT1[searchField] : 'Search'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="ic:round-search" width={22} />
                </InputAdornment>
              )
            }}
            onChange={(e) => pageChangeHandler(e.target.value, searchField)}
          />
        </div>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Page Name</StyledTableCell>
                    <StyledTableCell align="left">Application</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row?._id}>
                        <StyledTableCell align="left">{row?.page}</StyledTableCell>
                        <StyledTableCell align="left">{row?.applicationName}</StyledTableCell>
                        <StyledTableCell align="left">{row?.status}</StyledTableCell>
                        <StyledTableCell align="left">
                          <UserMoreMenu role={row} onDelete={deleteAppPage} onEdit={editAppPage} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Form>
        </FormikProvider>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </AppMainPage>
      <BootstrapDialog
        onClose={handleCloseUpdateAppPage}
        aria-labelledby="customized-dialog-title"
        open={isUpdatePageOpen}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseUpdateAppPage}>
          Update App Page
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <UpdateAppPage
            pageData={pageData}
            handleClose={handleCloseUpdateAppPage}
            onUpdate={getAppPageData}
          />
        </DialogContent>
      </BootstrapDialog>
      <AddNewPageModal
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        onUpdate={getAppPageData}
      />
    </>
  );
}
