import * as Yup from 'yup';
import React,{ useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';
import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableCell,
  tableCellClasses,
  TableRow 
} from '@mui/material';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------
const UpdateSmsForm = ({role, onUpdate, onClose}) => {
  
  console.log(role);
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [allRoles, setallRoles] = useState([]);
  const [client, setClient] = useState([]);
   const [smsTemplatePages, setSmsTemplatePages] = useState([]);
   const editorRef = useRef(null);
   const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    console.log(editorState);
  };

  const LoginSchema = Yup.object().shape({
    application: Yup.string().required('Application is required'),
    clientName: Yup.string().required('Client Name is required'),
    name: Yup.string().required('Name is required'),
    templateId: Yup.string().required('Template Id is required'),
    template: Yup.string().required('Template is required'),
    status: Yup.string().required('Status is required'),
  });

  
  useEffect(() => {  
    fetchRoles();
    fetchClient();
    getAppPageData();

    const contentBlock = htmlToDraft(role.Template);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const defaultEditorState = EditorState.createWithContent(contentState);
      setEditorState(defaultEditorState);
    }
  }, []);

  
  const getAppPageData = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/smsMaster/allList`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          // setAppPages(decryptedData);
          setSmsTemplatePages(decryptedData);
          console.log('All SMS Data', decryptedData);
        }
      })
      .catch((err) => console.log('app page data error', err));
  };

  const fetchRoles = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/getAllApplication`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          console.log(data.data);
          setallRoles(data.data);
        }
      });
  };


  const fetchClient = async () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/client/getAllClient`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;   
          console.log(data.data, 'client data')       
          setClient(data.data);
        }
      })
      .catch((err) => console.log('err', err));
  }


  const createMarkup = (content) => ({ __html: content });

  const formik = useFormik({
    initialValues: {     
      Application: role.Application,
      Client: role.Client,
      TemplateName: role.TemplateName,
      TemplateId: role.TemplateId,
      Template: "",
      Status: role.Status,
    },
   //  validationSchema: LoginSchema,
    onSubmit: () => {

      const user = { 
         _id: role._id, 
        Application: values.Application,
        TemplateId: values.TemplateId,
        TemplateName: values.TemplateName,
        Client: values.Client,
        Template: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        Status: values.Status,
      
      }


        console.log(user, 'upadate sms template ')
      const encuser ={
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/smsMaster/update`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              onUpdate()
              onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
              
             
                
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>                 
                 
                    <Grid item xs={2} sm={2} md={4}>
                      <FormControl fullWidth sx={{ minWidth: 120 ,marginBottom: '5px', marginTop: '25px'}} size="small">
                    <InputLabel id="demo-simple-select-helper-label">Application</InputLabel>
                      <Select
                        fullWidth
                      
                        label="Application"
                        sx={{ borderRadius: '10px'}}
                        inputProps={{
                          name: 'Application',
                          id: 'filled-status-native-simple'
                        }}
                        {...getFieldProps('Application')}
                        error={Boolean(touched.application && errors.application)}
                        helperText={touched.application && errors.application}
                      >
                        <MenuItem key="" value="" />
                        {allRoles.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.name.description}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={2} md={4}>
                      <FormControl fullWidth sx={{ minWidth: 120 ,marginBottom: '5px', marginTop: '25px'}} size="small">
                  <InputLabel id="demo-simple-select-helper-label">Client</InputLabel>
                  <Select
                    label="Client"
                    fullWidth
                    inputProps={{
                      name: 'Client',
                      id: 'filled-application-native-simple'
                    }}
                    sx={{ borderRadius: '10px'}}
                    {...getFieldProps('Client')}
                    error={Boolean(touched.clientName && errors.applicationclientName)}
                    helperText={touched.clientName && errors.clientName}
                  >
                    <MenuItem key="" value="" />
                    <MenuItem  value="All" >All</MenuItem>
                    {client.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Grid>
                <Grid item xs={2} sm={2} md={4}>
                <TextField   
                fullWidth       
                  size="small"
                  autoComplete="off"
                  type="text"
                  label="Template Id"
                  sx={{ marginBottom: '5px', marginTop: '25px' }}
                  name="TemplateId"
                  {...getFieldProps('TemplateId')}
                  error={Boolean(touched.templateId && errors.templateId)}
                  helperText={touched.templateId && errors.templateId}
                />
              </Grid>  
              
                   
              </Grid>
           
          
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={2} md={4}>
                    <TextField
                    fullWidth 
                      size="small"
                      autoComplete="off"
                      type="text"
                      label="Template Name"
                      sx={{ marginBottom: '5px', marginTop: '25px' }}
                      name="TemplateName"
                      {...getFieldProps('TemplateName')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    </Grid>
            

              <Grid item xs={2} sm={2} md={4}>

              <FormControl fullWidth sx={{ minWidth: 120,marginBottom: '5px', marginTop: '25px',}} size="small">
              <InputLabel id="demo-simple-select-helper-label" >Status</InputLabel>
              <Select
                fullWidth
                native
                label="Status"
                sx={{ borderRadius: '10px'}}
                inputProps={{
                  name: 'Status',
                  id: 'filled-status-native-simple'
                }}
                {...getFieldProps('Status')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
              >
                <option aria-label="None" value="" />
                <option value="ACTIVE">ACTIVE</option>
                <option value="INACTIVE">INACTIVE</option>
              </Select>
              </FormControl> 
                </Grid>
             
              </Grid>
              
          
              <div >
              <span style={{ display: 'block', marginTop: '15px' ,marginBottom: 'none',color: '#71797E',marginLeft: '11px'}}>Template</span>
                <div  style={{ marginRight: '20px', border: '1.5px', borderStyle: 'solid', borderColor: '#D3D3D3', marginBottom: '5px',borderRadius: '8px', width: '1150px', height: '200px' ,padding: '10px' ,overflow: 'auto' }}>
                  <Editor
                  fullWidth
                  resizable
                  ref={editorRef}
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    toolbarHidden 
                    editorStyle={{height: '200px' }}
                    onClick={() => editorRef.current.focus()}
                  />
                </div>
             {/*  <div>
                  <textarea
                    disabled
                    fullWidth
                    hidden
                    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                    name="Template"
                    {...getFieldProps('Template')}
                    style={{ width: '400px', minHeight: '300px', border: '1px solid #ccc', padding: '10px' }}
                  />
                </div> */}
              </div>              
             
              <Button variant="contained" size="large" sx={{ marginTop: '35px' }} fullWidth  type='submit'>
            Submit
          </Button>
            
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UpdateSmsForm;