import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill),
    role: ['Admin', 'User']
  },
  {
    title: 'application',
    path: '/dashboard/eaiapplication',
    icon: getIcon(fileTextFill),
    role: ['Admin']
  },
  {
    title: 'Client Management',
    icon: getIcon(alertTriangleFill),
    role: ['Admin'],
    children:[
      {
        title:'Clients',
        path:'/dashboard/client'
      },
      {
        title:'Approval Process',
        path:'/dashboard/approvalProcess'
      },
      {
        title:'App Roles',
        path:'/dashboard/Eroleclient'
      },
      {
        title: 'App Page',
        path: '/dashboard/appPage',
      },
     // {
     //   title: 'Assign pages to Role',
    //    path: '/dashboard/assignPageToRole',
    //  }

      // {
      //   title:'Point of Contact',
      //   path:'/dashboard/poc'
      // },
      // {
      //   title:'Clans',
      //   path:'/dashboard/clan'
      // }
    ]
  },
  {
    title: 'Access Management',
    icon: getIcon(lockFill),
    role: ['Admin'],
    children:[
      {
        title:'roles',
        path:'/dashboard/role'
      },
      {
       title:'Permissions',
       path:'/dashboard/permission'
      }
    ]
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon(peopleFill),
    role: ['Admin']
  },
  {
    title: 'Communication',
    icon: getIcon(lockFill),
    role: ['Admin'],
    children:[
      {
        title:'Email Configuration',
        path:'/dashboard/configurationEmail'
      },
      {
        title:'Email Template',
        path:'/dashboard/emailTemplate'
      },
      {
       title:'SMS Configuration',
       path:'/dashboard/configurationSms'
      },
      {
        title:'SMS Template',
        path:'/dashboard/smsTemplate'
       },
    ]
  },
 
  
  
  // {
  //   title: 'clients',
  //   path: '/dashboard/client',
  //   icon: getIcon(alertTriangleFill)
  // },
  // {
  //   title: 'user',
  //   icon: getIcon(peopleFill),
  //   children:[
  //     {
  //       title:'list users',
  //       path:'/dashboard/user'
  //     },
  //     {
  //      title:'create user',
  //      path:'/dashboard/create'
  //     },
  //     {
  //       title:'update user',
  //       path:''
  //     },
  //     {
  //       title:'delete user',
  //      path:''
  //     }
  //   ]
  // },
  // {
  //   title: 'roles',
  //   path: '/dashboard/role',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'permissions',
  //   path: '/dashboard/permission',
  //   icon: getIcon(lockFill)
  // },
];

export default sidebarConfig;
