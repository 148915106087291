import * as React from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from 'crypto-js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// material
import { Alert, Stack, TextField } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { LoadingButton } from '@material-ui/lab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
// ----------------------------------------------------------------------
const AssignPagesToRoleForm = ({ role, onUpdate, onClose, appPages, fetchRoles }) => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [allRoles, setallRoles] = useState([]);
  const [assignedPages, setAssignedPages] = useState([]);
  const [dense, setDense] = React.useState(false);

  const LoginSchema = Yup.object().shape({
    page: Yup.string().required('Status is required')
  });

  useEffect(() => {
    getAssignedPages();
  }, []);

  const pages = appPages.filter(
    (item) => item?.applicationName === role?.applicationData?.name?.description
  );

  const getAssignedPages = () => {
    const token = localStorage.getItem('token');
    const url = decodeURI(
      `${process.env.REACT_APP_BASEURL}/api/application/pagesOfRole?roleName=${role?.role}&applicationName=${role?.applicationData?.name?.description}&clientName=${role?.clientData?.displayName}`
    );
    fetch(url, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          console.log('decrypted assined data', data);
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          setAssignedPages(decryptedData);
          console.log('assined pages', decryptedData);
        }
      })
      .catch((err) => console.log('err', err));
  };

  const formik = useFormik({
    initialValues: {
      page: []
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const token = localStorage.getItem('token');
      const user = { id: role._id, pageId: values?.page };
      console.log(user, 'payload while attaching the page')
      const encuser = {
        encryptedData: CryptoJS.AES.encrypt(
          JSON.stringify(user),
          '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
        ).toString()
      };
      fetch(`${process.env.REACT_APP_BASEURL}/api/application/attachPagesToRole`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('attachPages to role', data);
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          console.log(decryptedData, 'decryptedData resposense after attach page')
          if (data.code === 200) {
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              fetchRoles();
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        })
        .catch((err) => console.log('err', err));
    }
  });

  const detachAssignedPage = (id) => {
    const user = { id: role._id, pageId: id };
    const encuser = {
      encryptedData: CryptoJS.AES.encrypt(
        JSON.stringify(user),
        '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
      ).toString()
    };
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_BASEURL}/api/application/detachPagesFromRole`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code === 200) {
          setSuccessMessage(data?.msg);
          setTimeout(() => {
            setSuccessMessage('');
            fetchRoles();
          }, 1000);
        } else {
          setpasswordError(data?.msg);
        }
      })
      .catch((err) => console.log('err', err));
  };

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12} md={12}>
            <List dense={dense}>
              {assignedPages[0]?.pageData.map((item) => (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <Icon
                        icon="ic:baseline-delete"
                        className="big-icon"
                        onClick={() => detachAssignedPage(item._id)}
                      />
                    </IconButton>
                  }
                  key={item?._id}
                >
                  <ListItemText primary={item?.page} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <FormControl>
                  {/* <InputLabel id="demo-select-small">Pages</InputLabel> */}
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    {...getFieldProps('page')}
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && errors.role}
                  >
                    <MenuItem value="">Select Pages</MenuItem>
                    {pages.map((item) => (
                      <MenuItem key={item?._id} value={item?._id}>
                        {item?.page}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AssignPagesToRoleForm;
