import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as CryptoJS from "crypto-js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Icon } from '@iconify/react';
// material
import {
  Alert,
  Stack,
  TextField,
} from '@material-ui/core';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { LoadingButton } from '@material-ui/lab';
import { Button, Input } from '@mui/material';

// ----------------------------------------------------------------------
const UpdateApplicationForm = ({role, onUpdate, onClose}) => {

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [selectedFIle, setSelectedFIle] = useState([]);
  const [showImage, setShowImage] = useState(true); 

  const LoginSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    applicationType: Yup.string().required('Application Type is required'),  
  });

  const formik = useFormik({
    initialValues: {
      description: role.name.description,
      applicationLogo: role.name.applicationLogo,
      applicationType: role.name.applicationType,
      applicationLogin: role.applicationSecret.applicationLogin,
      applicationLogoutUri: role.applicationSecret.applicationLogoutUri,      
      GrantTypes: role.applicationSecret.GrantTypes,
      applicationAllowedUrl: role.applicationAllowedUrl,
      applicationRedirectUrl: role.applicationRedirectUrl,
      authenticationType: role.authenticationType,
      status: role.status,
      updatedBy: localStorage.getItem('email')
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const user = {
        name: {       
          description: values.description,
          applicationLogo: imageUrl,
          applicationType: 'NA',
          },
          applicationSecret: {
              applicationLogin: 'NA',
              applicationLogoutUri: values.applicationLogoutUri,
              GrantTypes: 'NA',
          },
          applicationAllowedUrl: values.applicationAllowedUrl,
          applicationRedirectUrl: values.applicationRedirectUrl,
          authenticationType: 'NA',
          status: values.status,
          updatedBy: localStorage.getItem('email')
      }
      const encuser = {
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      const token = localStorage.getItem('token');
      fetch(`${process.env.REACT_APP_BASEURL}/api/application/updateApplication/${role._id}`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            values.role = '';
            values.status = '';
            values.desc = '';
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setSuccessMessage('');
              // navigate('/otp', { replace: true });
              onUpdate()
              onClose()
            }, 1000);
          } else {
            setpasswordError(data.msg);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const [imageUrl, setImageUrl] = useState({...getFieldProps('applicationLogo')}.value ? 
                                  {...getFieldProps('applicationLogo')}.value : '');

  const onFileChange = (e) => {
    setSelectedFIle(e.target.files);
  }

  const formdata = new FormData();
  formdata.append("profile",selectedFIle[0]);

  const handleUploadFile = () => {
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/uploadProfileImage`, {
      method: 'POST', 
      body: formdata
    })
    .then((res) => res.json())
    .then((data)=> {
      if(data.code === 200){
        setImageUrl(data?.msg);
        setShowImage(true);
      }
    })
    .catch((err) => {
      alert('please select file');
    })
  }

  const handleCloseImage = () => {
    setShowImage((show) => !show);
   }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Name"
                  name="description"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Application Discription"
                  name="applicationLogoutUri"
                  {...getFieldProps('applicationLogoutUri')}
                  error={Boolean(touched.applicationLogoutUri && errors.applicationLogoutUri)}
                  helperText={touched.applicationLogoutUri && errors.applicationLogoutUri}
                />
                
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{display: 'flex', alignItems: 'center'}}>
                <input type="file" onChange={onFileChange} /> 
                <Button variant='contained' sx={{width: '20px'}} onClick={handleUploadFile}>Upload</Button>
                </Stack>
                {
                  imageUrl && showImage ? 
                  <div style={{display: "flex", marginTop: '-5px'}}>
                  <img src={imageUrl} alt="uploadImage" width={85} height={70}/>
                  <Icon icon="akar-icons:cross" onClick={handleCloseImage}/>
                  </div>
                  : ''
                }

                {/* <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Application Logo"
                  name="applicationLogo"
                  value={imageUrl}
                  error={Boolean(touched.applicationLogo && errors.applicationLogo)}
                  helperText={touched.applicationLogo && errors.applicationLogo}
                /> */}
              {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-helper-label">Application Type</InputLabel>
                <Select
                  native
                  label="Application Type"
                  inputProps={{
                    name: 'applicationType',
                    id: 'filled-status-native-simple'
                  }}
                  {...getFieldProps('applicationType')}
                  error={Boolean(touched.applicationType && errors.applicationType)}
                  helperText={touched.applicationType && errors.applicationType}
                >
                  <option aria-label="None" value="" />
                  <option value="NATIVE">NATIVE</option>
                  <option value="REGULAR WEB">REGULAR WEB</option>
                  <option value="SPA">SPA</option>
                </Select>
                </FormControl> */}

                {/* <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Application Login"
                  name="applicationLogin"
                  {...getFieldProps('applicationLogin')}
                  error={Boolean(touched.applicationLogin && errors.applicationLogin)}
                  helperText={touched.applicationLogin && errors.applicationLogin}
                /> */}

                
              {/* <FormControl sx={{ m: 1, minWidth: 120 }}>  
                <InputLabel id="demo-simple-select-helper-label">Grant Types</InputLabel>
                <Select
                  native
                  label="Grant Types"
                  inputProps={{
                    name: 'GrantTypes',
                    id: 'filled-status-native-simple'
                  }}
                  {...getFieldProps('GrantTypes')}
                  error={Boolean(touched.GrantTypes && errors.GrantTypes)}
                  helperText={touched.GrantTypes && errors.GrantTypes}
                >
                  <option aria-label="None" value="" />
                  <option value="Implicit">Implicit</option>
                  <option value="Authorization Code">Authorization Code</option>
                  <option value="Refresh Token">Refresh Token</option>
                  <option value="MFA">MFA</option>
                  <option value="Passwordless OTP">Passwordless OTP</option>
                  <option value="Password">Password</option>
                </Select>
                </FormControl> */}

                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Application Allowed Url"
                  name="applicationAllowedUrl"
                  {...getFieldProps('applicationAllowedUrl')}
                  error={Boolean(touched.applicationAllowedUrl && errors.applicationAllowedUrl)}
                  helperText={touched.applicationAllowedUrl && errors.applicationAllowedUrl}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Application Redirect Url"
                  name="applicationRedirectUrl"
                  {...getFieldProps('applicationRedirectUrl')}
                  error={Boolean(touched.applicationRedirectUrl && errors.applicationRedirectUrl)}
                  helperText={touched.applicationRedirectUrl && errors.applicationRedirectUrl}
                />

              {/* <FormControl sx={{ m: 1, minWidth: 120 }}>  
                <InputLabel id="demo-simple-select-helper-label">Authentication Type</InputLabel>
                <Select
                  native
                  label="Authentication Type"
                  inputProps={{
                    name: 'authenticationType',
                    id: 'filled-authenticationType-native-simple'
                  }}
                  {...getFieldProps('authenticationType')}
                  error={Boolean(touched.authenticationType && errors.authenticationType)}
                  helperText={touched.authenticationType && errors.authenticationType}
                >
                  <option aria-label="None" value="" />
                  <option value="JWT">JWT</option>
                  <option value="OAuth2.0">OAuth2.0</option>
                </Select>
                </FormControl> */}
                
              <FormControl sx={{ m: 1, minWidth: 120 }}>  
                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                <Select
                  native
                  label="Status"
                  inputProps={{
                    name: 'status',
                    id: 'filled-status-native-simple'
                  }}
                  {...getFieldProps('status')}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                >
                  <option aria-label="None" value="" />
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                  <option value="Temporary BLOCKED">Temporary BLOCKED</option>
                  <option value="Permanent BLOCKED">Permanent BLOCKED</option>
                </Select>
              </FormControl>

                



                
                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
            <br />
            {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          </FormikProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UpdateApplicationForm;